import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetAtsCompanyAction } from "../../../../../store/ATS/Company/Action";
import { GetAtsConsultancyAction } from "../../../../../store/ATS/Consultancy/Action";
import {
  AddAtsUserAction,
  GetAtsUserAction,
  UpdateAtsUserAction,
} from "../../../../../store/ATS/User/Action";
import { getUserData } from "../../../Base/helper/baseFunctions";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import {
  GetAtsUserCountHelper,
  GetEmpUserEmailHelper,
  ValidateAtsUserEmailHelper,
} from "../../helper/AtsUserHelper";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { UpdateStatusHelper } from "../../../Ancillary/StatusUpdate/helper/StatusUpdateHelper";
import { ATS_USERS_PK } from "../../../../Constants/baseConstant";
import { toast } from "react-toastify";
import { GetUserRoleAction } from "../../../../../store/RoleAccess/Role/Action";
import history from "../../../../../history";
import AddEmployeePopup from "../../../MyEmployee/views/AddEmployeePopup";
import Popup from "../../../Base/views/Popup";
import CreatableSearch from "../../../Base/views/CreatableSearch";
import { GetAtsGroupCompanyAction } from "../../../../../store/ATS/GroupCompany/Action";

const AddUserPopup = (props) => {
  const userData = getUserData();
  const isGroupOrgFlowTrue = userData?.grouporg_flow === true;
  const { addMode, editedRecord, setOpenPopup, featuresData, user_type } =
    props;
  const { userRoleData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.RoleReducer
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [type, setType] = useState(false);
  const [message, setMessage] = useState([]);
  const [employeemail, setEmployeeEmail] = useState("");
  const [employee, setEmployee] = useState(false);

  const dispatch = useDispatch();
  const initialFValues = {
    name: "",
    user_type: user_type || "Internal",
    role_id: "",
    first_name: "",
    last_name: "",
    ats_company_id: "",
    ats_grouporg_id: "",
    consultancy_id: "",
    contact_no: "",
    email_id: "",
    access_all_jobs: true,
    access_company_jobs: false,
    access_consultancy_jobs: false,
    access_only_created_jobs: false,
    ats_company_detail_id: [],
    consultancy_detail_id: [],
    ats_grouporg_detail_id: [],
    access_invoice: false,
    access_reports: false,
    job_access: "Access all Jobs",
    enable_onboard_access: false,
  };
  const [step, setStep] = useState(1);
  const [disabled, setDisbaled] = useState(false);
  const [userTypeData, setUserTypeData] = useState(
    "Manage all organization's internal recruiter access"
  );
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [recruiterModalData, setRecruiterModalData] = useState(initialFValues);
  const [atsCompany, setAtsCompany] = useState({ load: true, values: [] });
  const [atsConsultancy, setAtsConsultancy] = useState({
    load: true,
    values: [],
  });
  const [atsgroupUser, setAtsGroupUser] = useState({
    load: true,
    values: [],
  });
  const [userCategory, setUserCategory] = useState({ load: true, values: [] });
  const { atsCompanyData } = useSelector(
    (state) => state.AtsReducer?.AtsCompanyReducer
  );
  const { atsGroupCompanyData } = useSelector(
    (state) => state.AtsReducer?.AtsGroupCompanyReducer
  );
  const { atsConsultancyData } = useSelector(
    (state) => state.AtsReducer?.AtsConsultancyReducer
  );
  const [powerLimit, setPowerLimit] = useState([]);
  const [empUsersEmail, setEmpUsersEmail] = useState({
    load: true,
    values: [],
  });
  useEffect(() => {
    if (editedRecord != null) {
      setRecruiterModalData({
        ...editedRecord,
        email_id:
          editedRecord?.user_type === "Internal" ||
          editedRecord?.user_type === "Admin"
            ? { email_id: editedRecord?.email_id }
            : editedRecord?.email_id,
        ats_company_id:
          editedRecord?.user_type === "Company"
            ? {
                ats_company_id: editedRecord?.ats_company_id,
                name: editedRecord?.company,
              }
            : null,
        ats_grouporg_id:
          editedRecord?.user_type === "Group User"
            ? {
                ats_grouporg_id: editedRecord?.ats_grouporg_id,
                name: editedRecord?.ats_grouporg,
              }
            : null,
        consultancy_id:
          editedRecord?.user_type === "Consultancy"
            ? {
                consultancy_id: editedRecord?.consultancy_id,
                name: editedRecord?.consultancy,
              }
            : null,
        ats_company_detail_id:
          editedRecord?.compj?.length > 0 ? editedRecord?.compj : [],
        consultancy_detail_id:
          editedRecord?.consulj?.length > 0 ? editedRecord?.consulj : [],
        ats_grouporg_detail_id:
          editedRecord?.grouporgj?.length > 0 ? editedRecord?.grouporgj : [],
        user:
          editedRecord?.user_type === "Internal"
            ? {
                employee_id: editedRecord?.company_consultancy_id,
                name: editedRecord?.company_consultancy_name,
              }
            : null,
        role_id:
          editedRecord?.role_id !== null
            ? editedRecord?.user_type === "Internal" ||
              editedRecord?.user_type === "Consultancy" ||
              editedRecord?.user_type === "Group User" ||
              editedRecord?.user_type === "Owner"
              ? {
                  role_id: editedRecord?.role_id,
                  role: editedRecord?.role_name,
                }
              : null
            : null,
        job_access: editedRecord?.access_all_jobs
          ? "Access all Jobs"
          : editedRecord?.access_only_created_jobs
          ? "Access only assigned Jobs"
          : editedRecord?.access_company_jobs || editedRecord?.access_consultancy_jobs
          ? "Access limitted Jobs"
          : " ",
        enable_onboard_access: editedRecord?.access_onboard_candidate,
      });
      editedRecord?.access_onboard_candidate && setDisbaled(true);
    }
    dispatch(GetAtsConsultancyAction());
    dispatch(GetAtsCompanyAction());
    dispatch(GetAtsGroupCompanyAction());
    dispatch(GetUserRoleAction());
    GetAtsUserCountHelper().then((res) => {
      if (res?.data?.statuscode === 200) {
        setPowerLimit(res?.data?.data?.[0]);
      }
    });
    // eslint-disable-next-line
  }, []);
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name =
        fieldValues.first_name?.trim() === "" ? "First Name is required" : "";
    }
    if ("user_type" in fieldValues) {
      temp.user_type =
        fieldValues.user_type === "" ? "User Type is required" : "";
      if (fieldValues?.user_type === "Company") {
        temp.consultancy_id = "";
        temp.user = "";
        if ("ats_company_id" in fieldValues) {
          temp.ats_company_id =
            fieldValues?.ats_company_id?.ats_company_id === null ||
            fieldValues?.ats_company_id === null ||
            fieldValues?.ats_company_id?.ats_company_id === "" ||
            fieldValues?.ats_company_id === ""
              ? "Client is required"
              : "";
        }
      }
      if (fieldValues?.user_type === "Group User") {
        temp.consultancy_id = "";
        temp.user = "";

        if ("ats_grouporg_id" in fieldValues) {
          temp.ats_grouporg_id =
            fieldValues.ats_grouporg_id === null ||
            fieldValues.ats_grouporg_id === ""
              ? "Group User is required"
              : "";
        }
      }
      if (fieldValues?.user_type === "Consultancy") {
        temp.ats_company_id = "";
        temp.user = "";
        if ("consultancy_id" in fieldValues) {
          temp.consultancy_id =
            fieldValues.consultancy_id === null ||
            fieldValues.consultancy_id === ""
              ? "Hiring Partner is required"
              : "";
        }
      }
    }
    if ("access_all_jobs" in fieldValues) {
      temp.ats_company_detail_id = [];
      temp.consultancy_detail_id = [];
      temp.ats_grouporg_detail_id = [];
    }
    if ("job_access" in fieldValues) {
      if (fieldValues?.job_access === "Access limitted Jobs") {
        if (
          fieldValues?.access_company_jobs ||
          fieldValues?.access_consultancy_jobs
        )
          temp.job_access = "";
        else temp.job_access = "Please choose any one access.";
      } else {
        temp.job_access = "";
      }
    }
    if ("access_company_jobs" in fieldValues) {
      if (fieldValues?.access_company_jobs) {
        if (isGroupOrgFlowTrue) {
          temp.ats_grouporg_detail_id =
            fieldValues?.ats_grouporg_detail_id === ""
              ? "Allowed Group Org is required"
              : fieldValues?.ats_grouporg_detail_id?.length > 0
              ? ""
              : "Allowed Group Org is required";
              temp.ats_company_detail_id = "";
        } else {
          temp.ats_company_detail_id =
            fieldValues?.ats_company_detail_id === ""
              ? "Allowed Companies is required"
              : fieldValues?.ats_company_detail_id?.length > 0
              ? ""
              : "Allowed Companies is required";
              temp.ats_grouporg_detail_id = "";
        }
      } else {
        temp.ats_company_detail_id = "";
        temp.ats_grouporg_detail_id = "";
      }
    }
    if ("access_consultancy_jobs" in fieldValues) {
      if (fieldValues?.access_consultancy_jobs) {
        temp.consultancy_detail_id =
          fieldValues?.consultancy_detail_id === ""
            ? "Allowed Consultancies is required"
            : fieldValues?.consultancy_detail_id?.length > 0
            ? ""
            : "Allowed Companies is required";
      } else {
        temp.consultancy_detail_id = "";
      }
    }
    if ("email_id" in fieldValues) {
      if (fieldValues?.email_id?.email_id) {
        temp.email_id =
          fieldValues.email_id.email_id === ""
            ? "Email is required"
            : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                fieldValues.email_id.email_id
              )
            ? ""
            : "Invalid Email.";
      } else {
        temp.email_id =
          fieldValues.email_id === ""
            ? "Email is required"
            : /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
                fieldValues.email_id
              )
            ? ""
            : "Invalid Email.";
      }
    }
    if (
      "role_id" in fieldValues &&
      ["Internal", "Consultancy", "Group User"].includes(
        recruiterModalData?.user_type
      )
    ) {
      temp.role_id =
        fieldValues.role_id === ""
          ? "User Category is required"
          : fieldValues.role_id === null
          ? "User Category is required"
          : "";
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };
  const handleDisabled = (permission) => {
    const foundPermission = featuresData?.find(
      (item) => item.permission === permission
    );
    return foundPermission ? true : false;
  };
  const handleInputChange = (event, value) => {
    if (["email_id"].includes(event)) {
      if (value) {
        if (value?.id === null) {
          // setFeedackTemplate({ load: false, values: [] })
          setEmployeeEmail(value?.email_id);
          setEmployee(true);
        } else {
          setRecruiterModalData({
            ...recruiterModalData,
            [event]: value,
            first_name: value.first_name,
            last_name: value.last_name,
            contact_no: value.personal_mobile,
          });
        }
      } else {
        setRecruiterModalData({ ...recruiterModalData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          email_id: value.email_id,
          first_name: value.first_name,
          last_name: value.last_name,
          contact_no: value.personal_mobile,
        });
    } else if (
      [
        "ats_company_id",
        "ats_grouporg_id",
        "consultancy_id",
        "ats_company_detail_id",
        "consultancy_detail_id",
        "ats_grouporg_detail_id",
        "role_id",
      ].includes(event)
    ) {
      if (value) {
        setRecruiterModalData({ ...recruiterModalData, [event]: value });
      } else {
        setRecruiterModalData({ ...recruiterModalData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...recruiterModalData, [event]: value ? value : "" });
    } else if (event?.target?.name === "user_type") {
      if (event?.target?.value === "Consultancy") {
        setUserTypeData("Manage your vendor’s recruiter access");
        setRecruiterModalData({
          ...recruiterModalData,
          [event?.target?.name]: event?.target?.value,
          ats_company_id: "",
          access_all_jobs: false,
          access_company_jobs: false,
          access_consultancy_jobs: false,
          access_only_created_jobs: false,
          ats_company_detail_id: [],
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
        });
      } else if (event?.target?.value === "Group User") {
        setUserTypeData("Manage your group user’s recruiter access");
        setRecruiterModalData({
          ...recruiterModalData,
          [event?.target?.name]: event?.target?.value,
          ats_company_id: "",
          access_all_jobs: false,
          access_company_jobs: false,
          access_consultancy_jobs: false,
          access_only_created_jobs: false,
          ats_company_detail_id: [],
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
        });
      } else if (event?.target?.value === "Company") {
        setUserTypeData("Manage your Client's recruiter Access");
        setRecruiterModalData({
          ...recruiterModalData,
          [event?.target?.name]: event?.target?.value,
          consultancy_id: "",
          access_all_jobs: false,
          access_company_jobs: false,
          access_consultancy_jobs: false,
          access_only_created_jobs: false,
          ats_company_detail_id: [],
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
        });
      } else if (event?.target?.value === "Internal") {
        setUserTypeData("Manage all organization's internal recruiter access");
        setRecruiterModalData({
          ...recruiterModalData,
          [event?.target?.name]: event?.target?.value,
          ats_company_id: "",
          consultancy_id: "",
          access_all_jobs: false,
          access_company_jobs: false,
          access_consultancy_jobs: false,
          access_only_created_jobs: true,
          ats_company_detail_id: [],
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
        });
      } else if (event?.target?.value === "Admin") {
        setUserTypeData("Manage all users");
        setRecruiterModalData({
          ...recruiterModalData,
          [event?.target?.name]: event?.target?.value,
          ats_company_id: "",
          consultancy_id: "",
          access_all_jobs: true,
          access_company_jobs: false,
          access_consultancy_jobs: false,
          access_only_created_jobs: false,
          ats_company_detail_id: [],
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
          access_reports: true,
          access_invoice: true,
          enable_onboard_access: true,
        });
      }
      setErrors({});
      setType(true);
    } else if (["job_access"].includes(event?.target?.name)) {
      setRecruiterModalData({
        ...recruiterModalData,
        [event.target.name]: event.target.value,
        access_company_jobs: false,
        access_consultancy_jobs: false,
        ats_company_detail_id: [],
        consultancy_detail_id: [],
        ats_grouporg_detail_id: [],
        access_only_created_jobs: false,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          [event.target.name]: event.target.value,
          access_company_jobs: false,
          access_consultancy_jobs: false,
          ats_company_detail_id: [],
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
          access_only_created_jobs: false,
        });
    } else if (["access_all_jobs"].includes(event?.target?.name)) {
      setRecruiterModalData({
        ...recruiterModalData,
        [event.target.name]: event.target.checked,
        access_company_jobs: false,
        access_consultancy_jobs: false,
        ats_company_detail_id: [],
        consultancy_detail_id: [],
        ats_grouporg_detail_id: [],
        access_only_created_jobs: false,
        job_access: "Access all Jobs",
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          [event.target.name]: event.target.checked,
          access_company_jobs: false,
          access_consultancy_jobs: false,
          ats_company_detail_id: [],
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
          access_only_created_jobs: false,
        });
    } else if (["access_only_created_jobs"].includes(event?.target?.name)) {
      setRecruiterModalData({
        ...recruiterModalData,
        [event.target.name]: event.target.checked,
        access_all_jobs: false,
        access_company_jobs: false,
        ats_company_detail_id: [],
        access_consultancy_jobs: false,
        consultancy_detail_id: [],
        ats_grouporg_detail_id: [],
        job_access: "Access only assigned Jobs",
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          [event.target.name]: event.target.checked,
        });
      if (recruiterModalData?.access_only_created_jobs === true) {
        setRecruiterModalData({
          ...recruiterModalData,
          [event.target.name]: event.target.checked,
          access_all_jobs: false,
          access_company_jobs: false,
          ats_company_detail_id: [],
          access_consultancy_jobs: false,
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
          job_access: "Access all Jobs",
        });
      }
    } else if (["access_company_jobs"].includes(event?.target?.name)) {
      setRecruiterModalData({
        ...recruiterModalData,
        [event.target.name]: event.target.checked,
        ats_company_detail_id: [],
        access_all_jobs: false,
        access_only_created_jobs: false,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          [event.target.name]: event.target.checked,
          ats_company_detail_id: [],
        });
    } else if (["access_consultancy_jobs"].includes(event?.target?.name)) {
      setRecruiterModalData({
        ...recruiterModalData,
        [event.target.name]: event.target.checked,
        consultancy_detail_id: [],
        access_all_jobs: false,
        access_only_created_jobs: false,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          [event.target.name]: event.target.checked,
          consultancy_detail_id: [],
          ats_grouporg_detail_id: [],
        });
    } else if (
      ["access_reports", "access_invoice"].includes(event?.target?.name)
    ) {
      setRecruiterModalData({
        ...recruiterModalData,
        [event.target.name]: event.target.checked,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          [event.target.name]: event.target.checked,
        });
    } else if (["enable_onboard_access"].includes(event?.target?.name)) {
      setDisbaled(event.target.checked);
      setRecruiterModalData({
        ...recruiterModalData,
        [event.target.name]: event.target.checked,
        job_access: event.target.checked
          ? "Access all Jobs"
          : recruiterModalData?.job_access,
        access_company_jobs: event.target.checked
          ? false
          : recruiterModalData?.access_company_jobs,
        ats_company_detail_id: event.target.checked
          ? []
          : recruiterModalData?.ats_company_detail_id,
        access_consultancy_jobs: event.target.checked
          ? false
          : recruiterModalData?.access_consultancy_jobs,
        consultancy_detail_id: event.target.checked
          ? []
          : recruiterModalData?.consultancy_detail_id,
        ats_grouporg_detail_id: event.target.checked
          ? []
          : recruiterModalData?.ats_grouporg_detail_id,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          [event.target.name]: event.target.checked,
        });
    } else {
      setRecruiterModalData({
        ...recruiterModalData,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...recruiterModalData,
          [event.target.name]: event.target.value,
        });
    }
  };
  const HandleSubmit = (e) => {
    if (validate(recruiterModalData)) {
      var company = [];
      var consultancy = [];
      var groupuser = [];
      if (recruiterModalData?.ats_users_id) {
        recruiterModalData?.consultancy_detail_id?.length > 0 &&
          recruiterModalData?.consultancy_detail_id?.forEach((res) =>
            consultancy.push({
              ats_user_access_detail_id: res?.ats_user_access_detail_id || null,
              consultancy_id: res?.consultancy_id,
            })
          );
        recruiterModalData?.ats_company_detail_id?.length > 0 &&
          recruiterModalData?.ats_company_detail_id?.forEach((res) =>
            company.push({
              ats_user_access_detail_id: res?.ats_user_access_detail_id || null,
              ats_company_id: res?.ats_company_id,
            })
          );
        recruiterModalData?.ats_grouporg_detail_id?.length > 0 &&
          recruiterModalData?.ats_grouporg_detail_id?.forEach((res) =>
            groupuser.push({
              ats_user_access_detail_id: res?.ats_user_access_detail_id || null,
              ats_grouporg_id: res?.ats_grouporg_id,
            })
          );

        if (type === true && step === 1) {
          setStep(2);
        } else {
          ValidateAtsUserEmailHelper({
            email_id: recruiterModalData?.email_id?.email_id
              ? recruiterModalData?.email_id?.email_id
              : recruiterModalData?.email_id,
            user_type: recruiterModalData?.user_type,
          }).then((res) => {
            let case_no = res?.data?.data?.[0]?.case_no;
            let user_id = res?.data?.data?.[0]?.user_id;
            let message = res?.data?.data?.[0]?.message;
            if (case_no === "case1") {
              setLoading(false);
              setOpenPopup(false);
              setOpenDialog(false);
              dispatch(
                UpdateAtsUserAction({
                  ...recruiterModalData,
                  consultancy_detail_id: JSON.stringify(consultancy),
                  ats_grouporg_detail_id: JSON.stringify(groupuser),
                  ats_company_detail_id: JSON.stringify(company),
                  role_id:
                    recruiterModalData?.user_type === "Admin"
                      ? userRoleData?.filter(
                          (item) => item?.role === "Power"
                        )?.[0]?.role_id
                      : recruiterModalData?.user_type === "Company"
                      ? userRoleData?.filter(
                          (item) => item?.role === "Regular"
                        )?.[0]?.role_id
                      : recruiterModalData?.role_id?.role_id,
                  access_all_jobs:
                    recruiterModalData?.job_access === "Access all Jobs"
                      ? true
                      : false,
                  access_only_created_jobs:
                    recruiterModalData?.job_access ===
                    "Access only assigned Jobs"
                      ? true
                      : false,
                  select_type: type,
                })
              );
            }
            if (case_no === "case3") {
              setMessage({ id: user_id, message: message });
              setOpenDialog(true);
            }
            if (case_no === "case4") {
              setLoading(false);
              setOpenPopup(false);
              setOpenDialog(false);
              toast.error(message);
            }
            if (case_no === "case5") {
              setLoading(false);
              setOpenPopup(false);
              setOpenDialog(false);
              dispatch(
                UpdateAtsUserAction({
                  ...recruiterModalData,
                  consultancy_detail_id: JSON.stringify(consultancy),
                  ats_grouporg_detail_id: JSON.stringify(groupuser),
                  ats_company_detail_id: JSON.stringify(company),
                  role_id:
                    recruiterModalData?.user_type === "Admin"
                      ? userRoleData?.filter(
                          (item) => item?.role === "Power"
                        )?.[0]?.role_id
                      : recruiterModalData?.user_type === "Company"
                      ? userRoleData?.filter(
                          (item) => item?.role === "Regular"
                        )?.[0]?.role_id
                      : recruiterModalData?.role_id?.role_id,
                  access_all_jobs:
                    recruiterModalData?.job_access === "Access all Jobs"
                      ? true
                      : false,
                  access_only_created_jobs:
                    recruiterModalData?.job_access ===
                    "Access only assigned Jobs"
                      ? true
                      : false,
                  select_type: type,
                })
              );
              addMode(resetForm);
            }
          });
        }
      } else {
        recruiterModalData?.consultancy_detail_id?.length > 0 &&
          recruiterModalData?.consultancy_detail_id?.forEach((res) =>
            consultancy.push({ consultancy_id: res?.consultancy_id })
          );
        recruiterModalData?.ats_company_detail_id?.length > 0 &&
          recruiterModalData?.ats_company_detail_id?.forEach((res) =>
            company.push({ ats_company_id: res?.ats_company_id })
          );
        recruiterModalData?.ats_grouporg_detail_id?.length > 0 &&
          recruiterModalData?.ats_grouporg_detail_id?.forEach((res) =>
            groupuser.push({
              ats_grouporg_id: res?.ats_grouporg_id,
            })
          );
        if (
          step === 1 &&
          (recruiterModalData?.user_type === "Admin" ||
            recruiterModalData?.user_type === "Internal")
        ) {
          setStep(3);
        } else if (step === 1) {
          setStep(2);
        } else if (
          step === 3 &&
          (recruiterModalData?.user_type === "Internal" ||
            recruiterModalData?.user_type === "Admin")
        ) {
          setLoading(false);
          setOpenPopup(false);
          setOpenDialog(false);
          dispatch(
            AddAtsUserAction({
              ...recruiterModalData,
              email_id: recruiterModalData?.email_id?.email_id,
              consultancy_detail_id: JSON.stringify(consultancy),
              ats_grouporg_detail_id: JSON.stringify(groupuser),
              ats_company_detail_id: JSON.stringify(company),
              role_id:
                recruiterModalData?.user_type === "Admin"
                  ? userRoleData?.filter((item) => item?.role === "Power")?.[0]
                      ?.role_id
                  : recruiterModalData?.user_type === "Company"
                  ? userRoleData?.filter(
                      (item) => item?.role === "Regular"
                    )?.[0]?.role_id
                  : recruiterModalData?.role_id?.role_id,
              access_all_jobs:
                recruiterModalData?.job_access === "Access all Jobs"
                  ? true
                  : false,
              access_only_created_jobs:
                recruiterModalData?.job_access === "Access only assigned Jobs"
                  ? true
                  : false,
            })
          );
        } else {
          setLoading(true);
          ValidateAtsUserEmailHelper({
            email_id: recruiterModalData?.email_id?.email_id
              ? recruiterModalData?.email_id?.email_id
              : recruiterModalData?.email_id,
            user_type: recruiterModalData?.user_type,
          }).then((res) => {
            let case_no = res?.data?.data?.[0]?.case_no;
            let user_id = res?.data?.data?.[0]?.user_id;
            let message = res?.data?.data?.[0]?.message;

            if (case_no === "case1") {
              setLoading(false);
              setOpenPopup(false);
              setOpenDialog(false);
              toast.error(message);
            }
            if (case_no === "case2") {
              setLoading(false);
              setOpenPopup(false);
              setOpenDialog(false);
              UpdateStatusHelper({
                update_id: user_id,
                status: "Active",
                column_name: ATS_USERS_PK,
                successMessage: "User Made Active Successfully",
              }).then((res) => {
                dispatch(GetAtsUserAction());
              });
              addMode(resetForm);
            }
            if (case_no === "case3") {
              setMessage({ id: user_id, message: message });
              setOpenDialog(true);
            }
            if (case_no === "case4") {
              setLoading(false);
              setOpenPopup(false);
              setOpenDialog(false);
              toast.error(message);
            }
            if (case_no === "case5") {
              setLoading(false);
              setOpenPopup(false);
              setOpenDialog(false);
              dispatch(
                AddAtsUserAction({
                  ...recruiterModalData,
                  consultancy_detail_id: JSON.stringify(consultancy),
                  ats_grouporg_detail_id: JSON.stringify(groupuser),
                  ats_company_detail_id: JSON.stringify(company),
                  role_id:
                    recruiterModalData?.user_type === "Admin"
                      ? userRoleData?.filter(
                          (item) => item?.role === "Power"
                        )?.[0]?.role_id
                      : recruiterModalData?.user_type === "Company"
                      ? userRoleData?.filter(
                          (item) => item?.role === "Regular"
                        )?.[0]?.role_id
                      : recruiterModalData?.role_id?.role_id,
                  access_all_jobs:
                    recruiterModalData?.job_access === "Access all Jobs"
                      ? true
                      : false,
                  access_only_created_jobs:
                    recruiterModalData?.job_access ===
                    "Access only assigned Jobs"
                      ? true
                      : false,
                })
              );
            }
          });
        }
      }
    }
  };
  const ValidateCreateUser = (company, consultancy, groupuser) => {
    recruiterModalData?.consultancy_detail_id?.length > 0 &&
      recruiterModalData?.consultancy_detail_id?.forEach((res) =>
        consultancy.push({ consultancy_id: res?.consultancy_id })
      );
    recruiterModalData?.ats_company_detail_id?.length > 0 &&
      recruiterModalData?.ats_company_detail_id?.forEach((res) =>
        company.push({ ats_company_id: res?.ats_company_id })
      );
    recruiterModalData?.ats_grouporg_detail_id?.length > 0 &&
      recruiterModalData?.ats_grouporg_detail_id?.forEach((res) =>
        groupuser.push({
          ats_grouporg_id: res?.ats_grouporg_id,
        })
      );
  };
  const handleEmpUsersEmailLoad = () => {
    GetEmpUserEmailHelper().then((res) => {
      if (res?.data?.statuscode === 200) {
        setEmpUsersEmail({ load: false, values: res?.data?.data });
      }
    });
  };
  const handleAddEmployee = () => {
    history.push({
      pathname: "/myemployee",
    });
  };
  const handleClickCreateAtsUser = () => {
    var company = [];
    var consultancy = [];
    var groupuser = [];

    ValidateCreateUser(company, consultancy, groupuser);
    dispatch(
      AddAtsUserAction({
        ...recruiterModalData,
        consultancy_detail_id: JSON.stringify(consultancy),
        ats_grouporg_detail_id: JSON.stringify(groupuser),
        ats_company_detail_id: JSON.stringify(company),
        role_id:
          recruiterModalData?.user_type === "Admin"
            ? userRoleData?.filter((item) => item?.role === "Power")?.[0]
                ?.role_id
            : ["Company"].includes(recruiterModalData?.user_type)
            ? userRoleData?.filter((item) => item?.role === "Regular")?.[0]
                ?.role_id
            : recruiterModalData?.role_id?.role_id,
        access_all_jobs:
          recruiterModalData?.job_access === "Access all Jobs" ? true : false,
        access_only_created_jobs:
          recruiterModalData?.job_access === "Access only assigned Jobs"
            ? true
            : false,
      })
    );
    addMode(resetForm);
    setOpenDialog(false);
    setOpenPopup(false);
  };
  const resetForm = () => {
    setRecruiterModalData(initialFValues);
    setErrors({});
    setOpenPopup(false);
  };
  return (
    <>
      {step === 1 ? (
        <>
          <Grid container>
            {["Admin", "Internal"].includes(recruiterModalData?.user_type) && (
              <>
                <Typography>
                  To add Admin/Internal users to ATS create an employee with
                  "Enable ATS" access
                </Typography>
                <Link
                  className="forget-sec"
                  onClick={handleAddEmployee}
                  variant="body2"
                  sx={{
                    fontFamily: "Nunito Sans",
                    cursor: "pointer",
                    fontSize: "14.2px",
                    color: "black",
                    paddingLeft: "5px",
                    paddingTop: "0px",
                  }}
                >
                  here
                </Link>
              </>
            )}
            <Grid
              key="user_type"
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              {recruiterModalData?.user_type !== "Owner" ||
              recruiterModalData?.user_type === "" ? (
                <FormControl
                  required
                  fullWidth
                  error={errors?.user_type ? true : false}
                  size="small"
                >
                  <InputLabel id="recruiter-type-select-label">
                    User Type
                  </InputLabel>
                  <Select
                    name="user_type"
                    labelId="recruiter-type-select-label"
                    id="recruiter-type-select"
                    value={recruiterModalData?.user_type}
                    label="User Type"
                    onChange={handleInputChange}
                  >
                    {user_type === "Internal" &&
                      editedRecord && [
                        <MenuItem key="admin" value={"Admin"}>
                          Admin
                        </MenuItem>,
                        <MenuItem key="internal" value={"Internal"}>
                          Internal
                        </MenuItem>,
                      ]}

                    {user_type === "Company" && editedRecord && (
                      <MenuItem value={"Company"}>Client</MenuItem>
                    )}
                    {user_type === "Consultancy" && editedRecord && (
                      <MenuItem value={"Consultancy"}>Consultancy</MenuItem>
                    )}
                    {user_type === "Group User" && editedRecord && (
                      <MenuItem value={"Group User"}>Group User</MenuItem>
                    )}

                    {(user_type === "Internal" ||
                      user_type === "Company" ||
                      user_type === "Consultancy" ||
                      user_type === "Group User") &&
                      editedRecord === null && [
                      
                        <MenuItem key="consultancy" value={"Consultancy"}>
                          Hiring Partner
                        </MenuItem>,
                        isGroupOrgFlowTrue && (
                          <MenuItem key="Group User" value={"Group User"}>
                            Group User
                          </MenuItem>
                        ),
                        !isGroupOrgFlowTrue && (
                          <MenuItem key="company" value={"Company"}>
                            Client
                          </MenuItem>
                        ),
                      ]}
                  </Select>
                  <FormHelperText>{userTypeData}</FormHelperText>
                  <FormHelperText>{errors?.user_type}</FormHelperText>
                </FormControl>
              ) : (
                <FormControl
                  required
                  fullWidth
                  error={errors?.user_type ? true : false}
                  size="small"
                >
                  <InputLabel id="recruiter-type-select-label">
                    User Type
                  </InputLabel>
                  <Select
                    name="user_type"
                    labelId="recruiter-type-select-label"
                    id="recruiter-type-select"
                    value={recruiterModalData?.user_type}
                    label="User Type"
                    disabled={true}
                    onChange={handleInputChange}
                  >
                    <MenuItem value={"Owner"}>Owner</MenuItem>
                  </Select>
                  <FormHelperText>{errors?.user_type}</FormHelperText>
                </FormControl>
              )}
            </Grid>
            {["Internal", "Consultancy", "Group User"].includes(
              recruiterModalData?.user_type
            ) && (
              <Grid
                key="user_category"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <SelectableSearch
                    isOptionEqualToValue={(option, value) =>
                      +option?.role_id === +value?.role_id
                    }
                    label="User Category"
                    value={recruiterModalData?.role_id}
                    fieldLabel="role"
                    loading={() =>
                      setUserCategory({ load: false, values: userRoleData })
                    }
                    variant={"contained"}
                    required
                    name="role_id"
                    size="small"
                    data={userCategory}
                    onChangeEvent={handleInputChange}
                    {...(errors.role_id && {
                      error: true,
                      helperText: errors.role_id,
                    })}
                  />
                </FormControl>
                {recruiterModalData?.role_id?.role === "Power" && (
                  <FormHelperText sx={{ ml: 1 }}>
                    {powerLimit?.power_current} of {powerLimit?.power_limit}{" "}
                    Power users available
                  </FormHelperText>
                )}
                {recruiterModalData?.role_id?.role === "Regular" && (
                  <FormHelperText sx={{ ml: 1 }}>
                    {powerLimit?.normal_current} of {powerLimit?.normal_limit}{" "}
                    Regular users available
                  </FormHelperText>
                )}
              </Grid>
            )}
            {recruiterModalData?.user_type === "Company" && (
              <Grid
                key="ats_company_id"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <SelectableSearch
                    isOptionEqualToValue={(option, value) =>
                      +option.ats_company_id === +value.ats_company_id
                    }
                    label="Client"
                    value={recruiterModalData?.ats_company_id}
                    fieldLabel="name"
                    loading={() =>
                      setAtsCompany({
                        load: false,
                        values: atsCompanyData?.filter(
                          (item) => item.status === "Active"
                        ),
                      })
                    }
                    variant={"contained"}
                    required
                    name="ats_company_id"
                    size="small"
                    data={atsCompany}
                    onChangeEvent={handleInputChange}
                    {...(errors.ats_company_id && {
                      error: true,
                      helperText: errors.ats_company_id,
                    })}
                  />
                </FormControl>
              </Grid>
            )}
            {recruiterModalData?.user_type === "Consultancy" && (
              <Grid
                key="consultancy_id"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <SelectableSearch
                    isOptionEqualToValue={(option, value) =>
                      +option.consultancy_id === +value.consultancy_id
                    }
                    label="Hiring Partner"
                    value={recruiterModalData?.consultancy_id}
                    fieldLabel="name"
                    loading={() =>
                      setAtsConsultancy({
                        load: false,
                        values: atsConsultancyData?.filter(
                          (item) => item.status === "Active"
                        ),
                      })
                    }
                    variant={"contained"}
                    name="consultancy_id"
                    required
                    size="small"
                    data={atsConsultancy}
                    onChangeEvent={handleInputChange}
                    {...(errors.consultancy_id && {
                      error: true,
                      helperText: errors.consultancy_id,
                    })}
                  />
                </FormControl>
              </Grid>
            )}
            {recruiterModalData?.user_type === "Group User" && (
              <Grid
                key="ats_grouporg_id"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <SelectableSearch
                    isOptionEqualToValue={(option, value) =>
                      +option.ats_grouporg_id === +value.ats_grouporg_id
                    }
                    label="Group Org"
                    value={recruiterModalData?.ats_grouporg_id}
                    fieldLabel="name"
                    loading={() =>
                      setAtsGroupUser({
                        load: false,
                        values: atsGroupCompanyData?.filter(
                          (item) => item.status === "Active"
                        ),
                      })
                    }
                    variant={"contained"}
                    name="ats_grouporg_id"
                    required
                    size="small"
                    data={atsgroupUser}
                    onChangeEvent={handleInputChange}
                    {...(errors.ats_grouporg_id && {
                      error: true,
                      helperText: errors.ats_grouporg_id,
                    })}
                  />
                </FormControl>
              </Grid>
            )}
            {["Internal", "Admin"].includes(recruiterModalData?.user_type) && (
              <Grid
                key="email_id"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <CreatableSearch
                  isOptionEqualToValue={(option, value) =>
                    +option.email_id === +value.email_id
                  }
                  label="Select Employee Email"
                  value={recruiterModalData?.email_id}
                  fieldLabel="email_id"
                  loading={() => handleEmpUsersEmailLoad()}
                  disabled={recruiterModalData?.ats_users_id}
                  variant={"contained"}
                  name="email_id"
                  required
                  size="small"
                  data={empUsersEmail}
                  onChangeEvent={handleInputChange}
                  {...(errors.email_id && {
                    error: true,
                    helperText: errors.email_id,
                  })}
                />
              </Grid>
            )}
            {["Company", "Consultancy", "Group User"].includes(
              recruiterModalData?.user_type
            ) && (
              <Grid
                key="email_id"
                item
                xs={12}
                md={6}
                sx={{
                  marginTop: "1rem",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    required
                    value={
                      recruiterModalData.email_id.email_id
                        ? recruiterModalData.email_id.email_id
                        : recruiterModalData.email_id
                    }
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="email_id"
                    label="Email"
                    name="email_id"
                    disabled={recruiterModalData?.ats_users_id}
                    onKeyPress={(e) =>
                      e.target.value?.length >= 254 && e.preventDefault()
                    }
                    autoComplete="email_id"
                    {...(errors.email_id && {
                      error: true,
                      helperText: errors.email_id,
                    })}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid
              key="first_name"
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  required
                  size="small"
                  value={recruiterModalData.first_name}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  id="first_name"
                  label="First Name"
                  name="first_name"
                  onKeyPress={(e) =>
                    e.target.value?.length >= 35 && e.preventDefault()
                  }
                  onPaste={(e) =>
                    e.target.value?.length >= 35 && e.preventDefault()
                  }
                  autoComplete="first_name"
                  {...(errors.first_name && {
                    error: true,
                    helperText: errors.first_name,
                  })}
                />
              </FormControl>
            </Grid>
            <Grid
              key="last_name"
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  size="small"
                  value={recruiterModalData.last_name}
                  onChange={handleInputChange}
                  variant="outlined"
                  fullWidth
                  id="last_name"
                  onKeyPress={(e) =>
                    e.target.value?.length >= 35 && e.preventDefault()
                  }
                  label="Last Name"
                  name="last_name"
                  autoComplete="last_name"
                />
              </FormControl>
            </Grid>

            {["Internal"].includes(recruiterModalData?.user_type) && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  sx={{
                    marginTop: "1rem",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Job Access
                    </FormLabel>
                    <RadioGroup
                      disabled={disabled}
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="job_access"
                      onChange={handleInputChange}
                      value={recruiterModalData?.job_access}
                    >
                      <FormControlLabel
                        disabled={disabled}
                        value="Access all Jobs"
                        control={<Radio />}
                        label="Access all Jobs"
                      />
                      <FormControlLabel
                        disabled={disabled}
                        value="Access only assigned Jobs"
                        control={<Radio />}
                        label="Access only assigned Jobs"
                      />
                      <FormControlLabel
                        disabled={disabled}
                        value="Access limitted Jobs"
                        control={<Radio />}
                        label="Access limited Jobs"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                {recruiterModalData?.role_id?.role === "Power" && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                      paddingRight: "15px",
                      paddingLeft: "15px",
                    }}
                  >
                    <FormControl>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Onboard Access
                      </FormLabel>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="enable_onboard_access"
                            checked={
                              recruiterModalData?.enable_onboard_access || false
                            }
                            onChange={handleInputChange}
                          />
                        }
                        label="Provide Onboarding Access?"
                      />
                    </FormControl>
                  </Grid>
                )}
                {recruiterModalData?.job_access === "Access limitted Jobs" && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Divider variant="fullWidth" orientation="horizontal" />
                    </Grid>
                    {!isGroupOrgFlowTrue && (
                      <>
                        <Grid
                          key="access_company_jobs"
                          item
                          xs={12}
                          md={6}
                          sx={{
                            marginTop: "1rem",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="access_company_jobs"
                                checked={
                                  recruiterModalData?.access_company_jobs ||
                                  false
                                }
                                onChange={handleInputChange}
                              />
                            }
                            label="Access Client Jobs?"
                          />
                        </Grid>
                        <Grid
                          key="ats_company_detail_id"
                          item
                          xs={12}
                          md={6}
                          sx={{
                            marginTop: "1rem",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          <SelectableSearch
                            isOptionEqualToValue={(option, value) =>
                              +option.ats_company_id === +value.ats_company_id
                            }
                            disabled={!recruiterModalData?.access_company_jobs}
                            label="Allowed Clients"
                            value={recruiterModalData?.ats_company_detail_id}
                            fieldLabel="name"
                            loading={() =>
                              setAtsCompany({
                                load: false,
                                values: atsCompanyData,
                              })
                            }
                            variant={"contained"}
                            name="ats_company_detail_id"
                            size="small"
                            data={atsCompany}
                            onChangeEvent={handleInputChange}
                            multiple
                            {...(errors.ats_company_detail_id && {
                              error: true,
                              helperText: errors.ats_company_detail_id,
                            })}
                          />
                        </Grid>
                      </>
                    )}

                    {isGroupOrgFlowTrue && (
                      <>
                        <Grid
                          key="access_company_jobs"
                          item
                          xs={12}
                          md={6}
                          sx={{
                            marginTop: "1rem",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                name="access_company_jobs"
                                checked={
                                  recruiterModalData?.access_company_jobs ||
                                  false
                                }
                                onChange={handleInputChange}
                              />
                            }
                            label="Access Group Organisation Jobs?"
                          />
                        </Grid>
                        <Grid
                          key="ats_grouporg_detail_id"
                          item
                          xs={12}
                          md={6}
                          sx={{
                            marginTop: "1rem",
                            paddingRight: "15px",
                            paddingLeft: "15px",
                          }}
                        >
                          <SelectableSearch
                            isOptionEqualToValue={(option, value) =>
                              +option.ats_grouporg_id === +value.ats_grouporg_id
                            }
                            disabled={!recruiterModalData?.access_company_jobs}
                            label="Allowed Group Organisation"
                            value={recruiterModalData?.ats_grouporg_detail_id}
                            fieldLabel="name"
                            loading={() =>
                              setAtsGroupUser({
                                load: false,
                                values: atsGroupCompanyData,
                              })
                            }
                            variant={"contained"}
                            name="ats_grouporg_detail_id"
                            size="small"
                            data={atsgroupUser}
                            onChangeEvent={handleInputChange}
                            multiple
                            {...(errors.ats_grouporg_detail_id && {
                              error: true,
                              helperText: errors.ats_grouporg_detail_id,
                            })}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid
                      key="access_consultancy_jobs"
                      item
                      xs={12}
                      md={6}
                      sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="access_consultancy_jobs"
                            checked={
                              recruiterModalData?.access_consultancy_jobs ||
                              false
                            }
                            onChange={handleInputChange}
                          />
                        }
                        label="Access Hiring Partner Jobs?"
                      />
                    </Grid>
                    <Grid
                      key="consultancy_detail_id"
                      item
                      xs={12}
                      md={6}
                      sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                      }}
                    >
                      <SelectableSearch
                        isOptionEqualToValue={(option, value) =>
                          +option.consultancy_id === +value.consultancy_id
                        }
                        disabled={!recruiterModalData?.access_consultancy_jobs}
                        label="Allowed Hiring Partners"
                        value={recruiterModalData?.consultancy_detail_id}
                        fieldLabel="name"
                        loading={() =>
                          setAtsConsultancy({
                            load: false,
                            values: atsConsultancyData,
                          })
                        }
                        variant={"contained"}
                        name="consultancy_detail_id"
                        size="small"
                        data={atsConsultancy}
                        onChangeEvent={handleInputChange}
                        multiple
                        {...(errors.consultancy_detail_id && {
                          error: true,
                          helperText: errors.consultancy_detail_id,
                        })}
                      />
                    </Grid>
                    <Typography sx={{ color: "red", ml: 2 }}>
                      {errors?.job_access}
                    </Typography>
                  </>
                )}

                {recruiterModalData?.role_id?.role === "Power" && (
                  <>
                    <Grid item xs={12} md={12}>
                      <Divider variant="fullWidth" orientation="horizontal" />
                    </Grid>
                    <Grid
                      key="access_reports"
                      item
                      xs={12}
                      md={6}
                      sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="access_reports"
                            checked={
                              recruiterModalData?.access_reports || false
                            }
                            onChange={handleInputChange}
                          />
                        }
                        label="Access to Reports?"
                      />
                    </Grid>
                    <Grid
                      key="access_invoice"
                      item
                      xs={12}
                      md={6}
                      sx={{
                        marginTop: "1rem",
                        paddingRight: "15px",
                        paddingLeft: "15px",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="access_invoice"
                            checked={
                              recruiterModalData?.access_invoice || false
                            }
                            onChange={handleInputChange}
                          />
                        }
                        label="Access to Invoice?"
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
            {["Consultancy", "Group User"].includes(
              recruiterModalData?.user_type
            ) && (
              <>
                <Grid
                  key="access_all_jobs"
                  item
                  xs={12}
                  md={6}
                  sx={{
                    marginTop: "1rem",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="access_all_jobs"
                        checked={recruiterModalData?.access_all_jobs || false}
                        onChange={handleInputChange}
                      />
                    }
                    label="Access all Jobs?"
                  />
                </Grid>
                <Grid
                  key="access_only_created_jobs"
                  item
                  xs={12}
                  md={6}
                  sx={{
                    marginTop: "1rem",
                    paddingRight: "15px",
                    paddingLeft: "15px",
                  }}
                >
                  <FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="access_only_created_jobs"
                          checked={
                            recruiterModalData?.access_only_created_jobs ||
                            false
                          }
                          onChange={handleInputChange}
                        />
                      }
                      label="Access only assigned Jobs?"
                    />
                    <FormHelperText sx={{ color: "red" }}>
                      {errors?.access_only_created_jobs}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </>
            )}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: -2,
              }}
            >
              <Button
                sx={{
                  ":hover": { color: grey[50] },
                  marginTop: "1.75rem",
                  marginRight: "1rem",
                }}
                variant="outlined"
                className="mpl-secondary-btn"
                onClick={() => setOpenPopup(false)}
              >
                cancel
              </Button>
              <Button
                onClick={HandleSubmit}
                variant="contained"
                color="primary"
                type="submit"
                className="mpl-primary-btn"
                sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
                disabled={
                  ["Internal", "Consultancy"].includes(
                    recruiterModalData?.user_type
                  ) &&
                  (powerLimit?.normal_current === powerLimit?.normal_limit ||
                    powerLimit?.power_current === powerLimit?.power_limit) &&
                  handleDisabled("ats_users_update_user")
                }
              >
                {editedRecord?.ats_users_id ? "Update" : "Next"}
              </Button>
            </Grid>
          </Grid>
        </>
      ) : step === 2 ? (
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              md={7}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <Typography>
                Email ID :{" "}
                {recruiterModalData.email_id.email_id ||
                  recruiterModalData.email_id}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <Typography>Workspace : {userData?.domain}</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <Typography variant="subtitle2">
                {editedRecord?.ats_users_id
                  ? ""
                  : "A link will be sent to this email. There you can set your password and that link is valid for 24 hrs"}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              sx={{
                ":hover": { color: grey[50] },
                marginTop: "1.75rem",
                marginRight: "1rem",
              }}
              variant="outlined"
              className="mpl-secondary-btn"
              onClick={() => setStep(1)}
            >
              Back
            </Button>
            <Box>
              <Button
                sx={{
                  ":hover": { color: grey[50] },
                  marginTop: "1.75rem",
                  marginRight: "1rem",
                }}
                variant="outlined"
                className="mpl-secondary-btn"
                onClick={() => setOpenPopup(false)}
              >
                cancel
              </Button>
              <LoadingButton
                loading={loading}
                onClick={HandleSubmit}
                variant="contained"
                color="primary"
                type="submit"
                className="mpl-primary-btn"
                sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
              >
                {editedRecord?.ats_users_id
                  ? "Update User"
                  : "Add and Send Password"}
              </LoadingButton>
            </Box>
          </Box>
        </>
      ) : (
        <>
          <Grid container>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <Typography>
                Email ID : {recruiterModalData.email_id.email_id}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              sx={{
                marginTop: "1rem",
                paddingRight: "15px",
                paddingLeft: "15px",
              }}
            >
              <Typography>Workspace : {userData?.domain}</Typography>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              sx={{
                ":hover": { color: grey[50] },
                marginTop: "1.75rem",
                marginRight: "1rem",
              }}
              variant="outlined"
              className="mpl-secondary-btn"
              onClick={() => setStep(1)}
            >
              Back
            </Button>
            <Box>
              <Button
                sx={{
                  ":hover": { color: grey[50] },
                  marginTop: "1.75rem",
                  marginRight: "1rem",
                }}
                variant="outlined"
                className="mpl-secondary-btn"
                onClick={() => setOpenPopup(false)}
              >
                cancel
              </Button>
              <LoadingButton
                loading={loading}
                onClick={HandleSubmit}
                variant="contained"
                color="primary"
                type="submit"
                className="mpl-primary-btn"
                sx={{ marginTop: "1.75rem", marginRight: "1rem" }}
              >
                Add
              </LoadingButton>
            </Box>
          </Box>
        </>
      )}
      <ConfirmationDialog
        title="Active User Found"
        content={message?.message || message}
        openDialog={openDialog}
        closeDialog={setOpenDialog}
        popupTitle="Users"
        CancelButtonText=""
        OkButtonText="Yes"
        onCancel={() => {
          setOpenDialog(false);
          setOpenPopup(false);
        }}
        onSubmit={handleClickCreateAtsUser}
      />
      <Popup
        title={"Add Employee"}
        openPopup={employee}
        setOpenPopup={setEmployee}
        maxWidth="md"
        fullWidth={true}
      >
        <AddEmployeePopup
          openPopup={employee}
          setOpenPopup={setEmployee}
          email={employeemail}
        />
      </Popup>
    </>
  );
};

export default AddUserPopup;
