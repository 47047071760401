import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommonDatePicker from "../../Base/views/CommonDatePicker";
import { AddEmailTokenHelper } from "../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { getUserData } from "../../Base/helper/baseFunctions";
import { v4 as uuidv4 } from "uuid";
import SelectableSearch from "../../Base/views/SelectableSearch";
import { GetDesignationAction } from "../../../../store/Ancillary/Designation/Action";
import { GetWorkLocationAction } from "../../../../store/Ancillary/WorkLocation/Action";
import { GetEmployeesAction } from "../../../../store/Employee/Employee/Action";
import Popup from "../../Base/views/Popup";
import Success from "../Image/check.png";
import { AddEmployeeHelper } from "../../Employee/helper/EmployeeHelper";
import { validateEmailHelper } from "../../SignIn/helper/SigninHelper";
import CreatableSearch from "../../Base/views/CreatableSearch";
import { AddDesignationHelper } from "../../Ancillary/Designation/helper/DesignationHelper";
import { AddWorkLocationHelper } from "../../Ancillary/WorkLocation/helper/WorkLocation";

const AddEmployeePopup = ({ setOpenPopup, email }) => {
  const dispatch = useDispatch();
  let userData = getUserData();
  const date = new Date();
  const default_date = `${
    date?.getFullYear() +
    "-" +
    ("0" + (date?.getMonth() + 1)).slice(-2) +
    "-" +
    ("0" + date?.getDate()).slice(-2)
  }`;
  const initialFValues = {
    first_name: "",
    last_name: "",
    dob: null,
    isactive: true,
    _designation_id: "",
    office_email: email || "" ,
    ats_work_location_id: "",
    reportingmanager_id: "",
    core_hr_user_type: "Employee",
    enable_corehr: true,
    enable_ats: false,
    joining_date: default_date,
  };
  const [errors, setErrors] = useState({});
  const [employeeModalData, setEmployeeModalData] = useState(initialFValues);
  const [successPopup, setSuccessPopup] = useState(false);
  const [designationData, setDesignationData] = useState({
    load: false,
    values: [],
  });
  const [worklocationData, setWorklocationData] = useState({
    load: false,
    values: [],
  });
  const [step, setStep] = useState(1);
  const { designation_options } = useSelector(
    (state) => state?.AncillaryReducer?.DesignationReducer
  );
  const { workLocation_options } = useSelector(
    (state) => state?.AncillaryReducer?.WorkLocationReducer
  );

  const { employees_options } = useSelector(
    (state) => state.EmployeeReducer.EmployeesReducer
  );
  const [employeesData, setEmployeesData] = useState({
    load: true,
    values: [],
  });
  useEffect(() => {
    dispatch(GetEmployeesAction({ is_active: true }));
    designation_options?.length === 0 && dispatch(GetDesignationAction());
    workLocation_options?.length === 0 && dispatch(GetWorkLocationAction());
  }, []);
  const validate = (fieldValues) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues) {
      temp.first_name =
        fieldValues.first_name?.trim() === "" ? "First Name is required" : "";
    }
    if ("last_name" in fieldValues) {
      temp.last_name =
        fieldValues.last_name?.trim() === "" ? "Last Name is required" : "";
    }
    if ("dob" in fieldValues) {
      temp.dob =
        fieldValues.dob === "" || fieldValues.dob === null
          ? "Date of Birth is required"
          : "";
    }
    if ("phone" in fieldValues) {
      temp.phone =
        fieldValues.phone === "" || fieldValues.phone === null
          ? "Mobile No is required"
          : fieldValues?.phone.toString() !== "" &&
            !fieldValues?.phone
              ?.toString()
              ?.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
          ? "Invalid Mobile Number."
          : "";
    }
    if ("office_email" in fieldValues) {
      temp.office_email =
        fieldValues.office_email === "" || null
          ? "Email is required"
          : /$^|.+@.+..+/.test(fieldValues.office_email)
          ? ""
          : "Invalid Email.";
    }
    if ("_designation_id" in fieldValues) {
      temp._designation_id =
        fieldValues._designation_id === "" || null
          ? "Designation is required"
          : "";
    }
    if ("ats_work_location_id" in fieldValues) {
      temp.ats_work_location_id =
        fieldValues.ats_work_location_id === "" || null
          ? "Work Location is required"
          : "";
    }
    if ("reportingmanager_id" in fieldValues) {
      temp.reportingmanager_id =
        fieldValues.reportingmanager_id === "" || null
          ? "Reporting Manager is required"
          : "";
    }
    if ("joining_date" in fieldValues) {
      temp.joining_date =
        fieldValues.joining_date === "" || fieldValues.joining_date === null
          ? "Date of Joining is required"
          : "";
    }

    setErrors({
      ...temp,
    });

    return Object.values(temp).every((x) => x === "");
  };
  const handleEmployees = () => {
    const filteredData = employees_options.filter((employee) => {
      return (
        (employee.employee_id !== userData?.employee_id &&
          employee.core_hr_user_type !== "Employee") ||
        employee.core_hr_user_type === "Owner"
      );
    });
    employees_options &&
      setEmployeesData({ load: false, values: filteredData });
  };
  const handleInputChange = (event, value) => {
    if (
      ["reportingmanager_id"].includes(
        event
      )
    ) {
      if (value) {
        setEmployeeModalData({ ...employeeModalData, [event]: value });
      } else {
        setEmployeeModalData({ ...employeeModalData, [event]: "" });
      }
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...employeeModalData, [event]: value ? value : "" });
    } else if (
      ["_designation_id"].includes(
        event
      )
    ) {
        if (value?.id === null) {
          AddDesignationHelper({ name: value?.name }).then((res) => {
            setDesignationData({ load: false, values: res?.data?.data });
            setEmployeeModalData({
              ...employeeModalData,
              [event]: res?.data?.data?.filter(
                (resp) => resp?.name === value?.name
              )?.[0],
            });
          });
        } else setEmployeeModalData({ ...employeeModalData, [event]: value });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...employeeModalData, [event]: value ? value : "" });
    }
    else if (
      ["ats_work_location_id"].includes(
        event
      )
    ) {
        if (value?.id === null) {
          AddWorkLocationHelper({ name: value?.work_location_name }).then((res) => {
            setWorklocationData({ load: false, values: res?.data?.data });
            setEmployeeModalData({
              ...employeeModalData,
              [event]: res?.data?.data?.filter(
                (resp) => resp?.work_location_name === value?.work_location_name
              )?.[0],
            });
          });
        } else setEmployeeModalData({ ...employeeModalData, [event]: value });
      
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...employeeModalData, [event]: value ? value : "" });
    }
    else if (event?.target?.name === "core_hr_user_type") {
      setEmployeeModalData({
        ...employeeModalData,
        [event?.target?.name]: event?.target?.value,
      });
    } else if (["enable_ats"].includes(event?.target?.name)) {
      setEmployeeModalData({
        ...employeeModalData,
        [event.target.name]: event.target.checked,
      });
    } else if (event?.target?.value === "Admin") {
      setEmployeeModalData({
        ...employeeModalData,
        [event?.target?.name]: event?.target?.value,
        enable_ats: true,
      });
    } else {
      setEmployeeModalData({
        ...employeeModalData,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...employeeModalData,
          [event.target.name]: event.target.value,
        });
    }
  };

  const handleNext = (e) => {
    if(validate(employeeModalData)){
    if (step === 1) {
      setStep(2);
    }
    }
  };
  const HandleClose = () => {
    setSuccessPopup(false)
    setOpenPopup(false)
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate(employeeModalData)) {
      let token = uuidv4() + `=${userData?.domain}`;

      AddEmployeeHelper(employeeModalData).then((res) => {
        if (res?.data?.statuscode === 200) {
        setSuccessPopup(true)
        AddEmailTokenHelper({
          token: token,
          email_id: employeeModalData?.office_email,
          json_data: JSON.stringify([{ ...employeeModalData }]),
        });
        validateEmailHelper({ domain: userData?.domain, email: employeeModalData?.office_email })
        dispatch(GetEmployeesAction({ is_active: true }));
      }
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };
  return (
    <>
      {step === 1 ? (
        <>
          <Grid container>
            <Grid container>
              <Grid
                key="first_name"
                item
                xs={12}
                md={12}
                sx={{ padding: "0.75rem" }}
              >
                <FormControl fullWidth>
                  <TextField
                    autoFocus={true}
                    required
                    size="small"
                    value={employeeModalData.first_name}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    onKeyPress={(e) =>
                      e.target.value?.length >= 35 && e.preventDefault()
                    }
                    onPaste={(e) =>
                      e.target.value?.length >= 35 && e.preventDefault()
                    }
                    id="first_name"
                    label="First Name"
                    name="first_name"
                    autoComplete="first_name"
                    {...(errors.first_name && {
                      error: true,
                      helperText: errors.first_name,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid
                key="last_name"
                item
                xs={12}
                md={12}
                sx={{ padding: "0.75rem" }}
              >
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    required
                    value={employeeModalData.last_name}
                    onChange={handleInputChange}
                    variant="outlined"
                    fullWidth
                    id="last_name"
                    label="Last Name"
                    onKeyPress={(e) =>
                      e.target.value?.length >= 35 && e.preventDefault()
                    }
                    onPaste={(e) =>
                      e.target.value?.length >= 35 && e.preventDefault()
                    }
                    name="last_name"
                    autoComplete="last_name"
                    {...(errors.last_name && {
                      error: true,
                      helperText: errors.last_name,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <TextField
                  label=" Email"
                  fullWidth
                  name="office_email"
                  required
                  onChange={handleInputChange}
                  value={employeeModalData?.office_email || ""}
                  size="small"
                  {...(errors.office_email && {
                    error: true,
                    helperText: errors.office_email,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <CommonDatePicker
                    required
                    size="small"
                    name="dob"
                    label="Birth Date"
                    pickerType="date"
                    onKeyPress={(e) => e.preventDefault()}
                    onKeyDown={(e) => e.preventDefault()}
                    maxDate={new Date().setFullYear(
                      new Date().getFullYear() - 18
                    )}
                    pickerFormat="dd/MM/yyyy"
                    DefaultDTvalue={employeeModalData?.dob}
                    handleChange={(value) =>
                      handleInputChange({
                        target: {
                          name: "dob",
                          value: value
                            ? `${
                                value?.getFullYear() +
                                "-" +
                                ("0" + (value.getMonth() + 1)).slice(-2) +
                                "-" +
                                ("0" + value.getDate()).slice(-2)
                              }`
                            : "",
                        },
                      })
                    }
                    {...(errors.dob && {
                      error: true,
                      helperText: errors.dob,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <CreatableSearch
                 isOptionEqualToValue={(option, value) =>
                  +option._designation_id === +value._designation_id
                }
                  label="Designation"
                  value={employeeModalData?._designation_id}
                  loading={() =>
                    setDesignationData({
                      load: false,
                      values: designation_options,
                    })
                  }
                  fieldLabel="name"
                  required={true}
                  variant={"contained"}
                  name="_designation_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={designationData}
                  showAddOption={true}
                  OptionName="Type to add new Designation"
                  {...(errors._designation_id && {
                    error: true,
                    helperText: errors._designation_id,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <CreatableSearch
                 isOptionEqualToValue={(option, value) =>
                  +option.ats_work_location_id === +value.ats_work_location_id
                }
                  label="Work Location"
                  value={employeeModalData?.ats_work_location_id}
                  loading={() =>
                    setWorklocationData({
                      load: false,
                      values: workLocation_options,
                    })
                  }
                  fieldLabel="work_location_name"
                  required={true}
                  variant={"contained"}
                  name="ats_work_location_id"
                  size="small"
                  onChangeEvent={handleInputChange}
                  data={worklocationData}
                  showAddOption={true}
                  OptionName="Type to add new Work Location"
                  {...(errors.ats_work_location_id && {
                    error: true,
                    helperText: errors.ats_work_location_id,
                  })}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                  <SelectableSearch
                    label="Reporting Manager"
                    value={employeeModalData?.reportingmanager_id}
                    loading={() => handleEmployees()}
                    fieldLabel="name"
                    required={true}
                    variant={"outlined"}
                    name="reportingmanager_id"
                    size="small"
                    onChangeEvent={handleInputChange}
                    data={employeesData}
                    {...(errors.reportingmanager_id && {
                      error: true,
                      helperText: errors.reportingmanager_id,
                    })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
                <FormControl fullWidth>
                <CommonDatePicker
                            required
                            size="small"
                            name="joining_date"
                            label="Date of Joining"
                            pickerType="date"
                            onKeyPress={(e) => e.preventDefault()}
                            onKeyDown={(e) => e.preventDefault()}
                            pickerFormat="dd/MM/yyyy"
                            DefaultDTvalue={employeeModalData?.joining_date}
                            handleChange={(value) =>
                              handleInputChange({
                                target: {
                                  name: "joining_date",
                                  value: value
                                    ? `${
                                        value?.getFullYear() +
                                        "-" +
                                        ("0" + (value?.getMonth() + 1)).slice(
                                          -2
                                        ) +
                                        "-" +
                                        ("0" + value?.getDate()).slice(-2)
                                      }`
                                    : "",
                                },
                              })
                            }
                            {...(errors.joining_date && {
                              error: true,
                              helperText: errors.joining_date,
                            })}
                          />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mr:1.5 }}>
            <Button
              className="mpl-primary-btn"
              variant="contained"
              size="medium"
              onClick={handleNext}
            >
              Next
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Grid container>
            <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
              <FormControl
                required
                fullWidth
                error={errors?.core_hr_user_type ? true : false}
                size="small"
              >
                <InputLabel id="recruiter-type-select-label">
                  User Type
                </InputLabel>
                <Select
                  name="core_hr_user_type"
                  labelId="recruiter-type-select-label"
                  id="recruiter-type-select"
                  value={employeeModalData?.core_hr_user_type}
                  label="User Type"
                  onChange={handleInputChange}
                >
                  <MenuItem value={"Admin"}>Admin</MenuItem>
                  <MenuItem value={"Employee"}>Employee</MenuItem>
                </Select>
                <FormHelperText>{errors?.core_hr_user_type}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="Name"
                  name="name"
                  onChange={handleInputChange}
                  value={
                    employeeModalData?.first_name +" "+
                      employeeModalData?.last_name || ""
                  }
                  size="small"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={12} sx={{ padding: "0.75rem" }}>
              <FormControl fullWidth>
                <TextField
                  disabled
                  label="Email"
                  name="office_email"
                  onChange={handleInputChange}
                  value={employeeModalData?.office_email || ""}
                  size="small"
                />
              </FormControl>
            </Grid>
            {["Employee"].includes(employeeModalData?.core_hr_user_type) && (
              <>
                <Grid item xs={12} md={7} sx={{ padding: "0.75rem" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled
                        name="enable_corehr"
                        checked={employeeModalData?.enable_corehr || false}
                        onChange={handleInputChange}
                      />
                    }
                    label="Enable Core HR & LMS"
                  />
                </Grid>
                <Grid item xs={12} md={5} sx={{ padding: "0.75rem" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="enable_ats"
                        checked={employeeModalData?.enable_ats || false}
                        onChange={handleInputChange}
                      />
                    }
                    label="Enable ATS"
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "space-between", mr:1.5 }}>
            <Button
              className="mpl-primary-btn"
              variant="contained"
              size="medium"
              onClick={() => setStep(1)}
            >
              Back
            </Button>
            <Button
              className="mpl-primary-btn"
              variant="contained"
              size="medium"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </>
      )}
      <Popup
        openPopup={successPopup}
        setOpenPopup={setSuccessPopup}
        fullWidth={false}
        maxWidth="md"
        close={false}
        hideDividers={true}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
            <img src={Success} alt="Success" width={"15%"} />
          </Box>
          <Typography variant="h6"> Employee Created Successfully. </Typography>
          <Typography variant="h6"> Employee Password Setup Instructions have been sent successfully to <span style={{ color:"#883bd5"}}>{employeeModalData?.office_email}</span></Typography>
          <Typography variant="h6"> For any updates on the onboarded employee, please visit My Employee Section. </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Button
              onClick={HandleClose}
              variant="contained"
              type="submit"
              className="mpl-primary-btn profile-btn"
              size="small"
              sx={{
                margin: "10px",
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Popup>
    </>
  );
};
export default AddEmployeePopup;
