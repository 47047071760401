import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Menu,
  MenuItem,
  Paper,
  Tab,
  Box,
  Typography,
  Button,
  Drawer,
  Tooltip,
  IconButton,
  Divider,
  tooltipClasses,
  Tabs,
} from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import { createStyles, makeStyles, styled } from "@mui/styles";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import FilterListIcon from "@mui/icons-material/FilterList";
import KanbanBoard from "./Board/KanbanBoard";
import TableBoard from "./Table/TableBoard";
import {Search as SearchIcon} from "@mui/icons-material"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { GetATSModuleFeaturesAction } from "../../../../../../store/RoleAccess/ATSModuleFeatures/Action";
import AtsCandidateForm from "../../ATSCandidate/AtsCandidateForm";
import SelectableSearch from "../../../../Base/views/SelectableSearch";
import { GetAtsJobAccessUsersHelper } from "../../../helper/AtsUserHelper";
import { GetProposedByHelper } from "../../../helper/AtsJobKanbanHelper";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#fff",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: "100%",
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const KanbanLanding = ({
  setArchived,
  currentMode,
  currentJobData,
  setCurrentMode,
}) => {
  const [value, setValue] = useState("1");
  const [candidatePreview, setCandidatePreview] = useState(false);
  const [candidateUpdate, setCandidateUpdate] = useState(false);
  const [candidatevalueData, setCandidatevalueData] = useState();
  const [filters, setFilters] = useState({
    assignee_filter: [],
    proposed_filter: [],
  });
  const [anchor, setAnchor] = useState(false);
  const [sortfilter, setSortFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(false);
  const [anchorel, setAnchorel] = useState(false);
  const open = Boolean(anchorel);
  const [currentData, setCurrentData] = useState();
  const [sharedState, setSharedState] = useState();
  useEffect(() => {
    setSharedState(sessionStorage.getItem('tabTypeIndex'));
  }, []);
  useEffect(() => {
    if (sharedState == 2) {
      setValue("2");
    } else {
      setValue("1");
    }
  }, [sharedState]);
  const [assignee, setAssignee] = useState();
  const [proposedby, setProposedby] = useState();
  const [searchVisible, setSearchVisible] = useState(false);
  const [sortValue, setSortValue] = useState({
    value: false,
    name: false,
    label: false,
    "Remaining Positions": false,
    order: false,
  });
  const { featuresData } = useSelector(
    (state) => state?.RoleAndAccessReducer?.ATSModuleFeaturesReducer
  );
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    if (candidatePreview === true) {
      dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Candidate" }));
    }
  }, [candidatePreview === true]);
  useEffect(() => {
    setCurrentMode("edit");
    dispatch(GetATSModuleFeaturesAction({ module_name: "ATS Job Post" }));
    GetAtsJobAccessUsersHelper({
      ats_jobpost_id: currentJobData?.ats_jobpost_id,
      candidate_job_id: currentJobData?.candidate_job_id
    }).then((res) => {
      setAssignee(res?.data?.data);
    });
    GetProposedByHelper({
      ats_jobpost_id: currentJobData?.ats_jobpost_id,
    }).then((res) => {
      setProposedby(res?.data?.data);
    });
    // eslint-disable-next-line
  }, [location]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggleDrawer = (open) => {
    setAnchor(open);
  };
  const toggleDrawers = (open) => {
    setAnchorEl(open);
  };
  const handleFilterClick = (event) => {
    setAnchorel(event.currentTarget);
  };
  const handleFilterClose = (event, name) => {
    setAnchorel(null);
    if (name !== "backdropClick") {
      setSortFilter(name);
      var found = sortByData.find((e) => e.label === name);
      if (found) {
        setSortValue({ name: found?.name, order: found?.value });
      }
    }
  };
  const handleFilter = (event, value) => {
    if (["assignee_filter", "proposed_filter"].includes(event)) {
      setFilters({ ...filters, [event]: value });
    }
  };
  const handleSearchClick = () => {
    setSearchVisible(!searchVisible);
  };
  const handleClearFilter = () => {
    setFilters({ assignee_filter: [], proposed_filter: [] });
    setSortFilter("");
    setSortValue({
      value: false,
      name: false,
      label: false,
      "Remaining Positions": false,
      order: false,
    });
  };
  const isTab1Disabled = sharedState == 2
  const sortByData = [
    { value: "asc", name: "priority", label: "Candidate Score: Low - High" },
    { value: "desc", name: "priority", label: "Candidate Score: High - Low" },
  ];

  return (
    <>
      {candidatePreview ? (
        <AtsCandidateForm
          featuresData={featuresData}
          setCandidatevalueData={setCandidatevalueData}
          setCandidateUpdate={setCandidateUpdate}
          setCandidatePreview={setCandidatePreview}
          candidatePreview ={candidatePreview}
          currentData={currentData}
          toggleDrawer={toggleDrawer}
        />
      ) : (
        <Paper
          className="onboarding-settings admin-setting"
          sx={{
            borderRadius: "10px",
            minHeight: "87vh",
            maxHeight: "87vh",
            overflowY: "auto",
          }}
        >
          <TabContext value={value}>
            <Box>
              <TabList
                sx={{
                  minHeight: "40px",
                  ".MuiTab-root": { minHeight: "20px" },
                  "& .MuiTabs-indicator": {
                    width: "100%",
                    display: "flex",
                    justifyContent: "center !important",
                  },
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Board" value="1" sx={{
                  display : isTab1Disabled ? "none" : "block",
                }}/>
                <Tab label="Table" value="2" />
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexGrow: "1",
                  }}
                >
                  <Typography variant="h6" sx={{ p: 1 }}>
                    {" "}
                    {currentJobData?.title}
                  </Typography>

                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <div style={{ height: "350px", overflowY: "auto" }} className="assigne_section">
                          <Typography
                            sx={{ marginBottom: "10px", textDecoration:"underline"  }}
                            color="inherit"
                            variant="h6"
                          >
                           Job Description
                          </Typography>
                          <Typography
                            sx={{ marginBottom: "10px", fontSize:"14px !important"}}
                          >
                           <div dangerouslySetInnerHTML={{ __html: currentJobData?.description || "No Description" }}></div>
                          </Typography>

                          <Typography
                            sx={{ marginBottom: "10px", textDecoration:"underline"  }}
                            color="inherit"
                            variant="h6"
                          >
                           Roles and Responsibilities
                          </Typography>
                          <Typography
                            sx={{ marginBottom: "10px", }}
                          >
                            <div dangerouslySetInnerHTML={{ __html: currentJobData?.roles_responsibilities || "No Roles and Responsibilities"  }}></div>
                          </Typography>
                          
                        </div>
                      </React.Fragment>
                    }
                  >
                    <Box sx={{ ml: 1 }}>
                      <ErrorOutlineIcon
                        sx={{ cursor: "pointer" }}
                        fontSize="small"
                      />
                    </Box>
                  </HtmlTooltip>
                </Box>
                {value === "1" &&
                <IconButton onClick={handleSearchClick}>
                <SearchIcon />
              </IconButton>}
                <Button
                  sx={{ mr: 2 }}
                  endIcon={<FilterListIcon />}
                  onClick={() => toggleDrawers(true)}
                >
                  Filter
                </Button>
                <Button onClick={handleFilterClick}>
                  Sort By : &nbsp;{" "}
                  <span
                    style={{
                      WebkitTapHighlightColor: "transparent",
                      color: "rgb(99, 115, 129)",
                    }}
                  >
                    {sortfilter}
                  </span>{" "}
                  &nbsp;
                  {open && (
                    <KeyboardArrowUpIcon
                      fontSize="small"
                      sx={{ color: "rgb(99, 115, 129)" }}
                    />
                  )}
                  {!open && (
                    <KeyboardArrowDownIcon
                      fontSize="small"
                      sx={{ color: "rgb(99, 115, 129)" }}
                    />
                  )}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorel}
                  open={open}
                  onClose={handleFilterClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  PaperProps={{}}
                  transformOrigin={{ horizontal: "right", vertical: "top" }}
                  anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                >
                  {sortByData.map((option, index) => (
                    <MenuItem
                      key={option?.label}
                      selected={sortfilter === option?.label}
                      onClick={(event) =>
                        handleFilterClose(event, option?.label)
                      }
                    >
                      {option?.label}
                    </MenuItem>
                  ))}
                </Menu>
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: "0px", minHeight: "45px" }}>
              <KanbanBoard
                setArchived={setArchived}
                currentMode={currentMode}
                setCurrentMode={setCurrentMode}
                currentJobData={currentJobData}
                candidatevalueData={candidatevalueData}
                setCandidateUpdate={setCandidateUpdate}
                candidateUpdate={candidateUpdate}
                setCandidatePreview={setCandidatePreview}
                candidatePreview={candidatePreview}
                toggleDrawer={toggleDrawer}
                setAnchor={setAnchor}
                anchor={anchor}
                setCurrentData={setCurrentData}
                currentData={currentData}
                filters={filters}
                sortValue={sortValue}
                searchVisible={searchVisible}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ padding: "0px", minHeight: "45px" }}>
              <TableBoard
                setArchived={setArchived}
                currentJobData={currentJobData}
                currentMode={currentMode}
                setCurrentMode={setCurrentMode}
                candidatevalueData={candidatevalueData}
                setCandidateUpdate={setCandidateUpdate}
                candidateUpdate={candidateUpdate}
                setCandidatePreview={setCandidatePreview}
                toggleDrawer={toggleDrawer}
                setAnchor={setAnchor}
                anchor={anchor}
                setCurrentData={setCurrentData}
                currentData={currentData}
                filters={filters}
                sortValue={sortValue}
              />
            </TabPanel>
          </TabContext>
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={anchorEl}
            onClose={() => toggleDrawers(false)}
            ModalProps={{ keepMounted: false }}
            PaperProps={{ sx: { width: "25%" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "10px 0px 10px 16px",
              }}
            >
              <Typography variant="subtitle1">Filters</Typography>
              <Box>
                <Tooltip title="Clear">
                  <IconButton
                    color="error"
                    variant="outlined"
                    onClick={handleClearFilter}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Divider sx={{ mb: 1 }} />
            <Box sx={{ margin: "8px" }}>
              <SelectableSearch
                isOptionEqualToValue={(option, value) =>
                  +option.recruiter_id === +value.recruiter_id
                }
                size="small"
                label="Assignee"
                value={filters.assignee_filter}
                loading={() => setAssignee({ load: false, values: assignee })}
                fieldLabel="recruiter"
                required={false}
                multiple={true}
                variant={"outlined"}
                name="assignee_filter"
                onChangeEvent={handleFilter}
                data={assignee}
              />
            </Box>

            <Box sx={{ margin: "8px" }}>
              <SelectableSearch
                isOptionEqualToValue={(option, value) =>
                  option.proposed_by_name === value.proposed_by_name
                }
                size="small"
                label="Proposed By"
                value={filters.proposed_filter}
                loading={() =>
                  setProposedby({ load: false, values: proposedby })
                }
                fieldLabel="proposed_by_name"
                required={false}
                multiple={true}
                variant={"outlined"}
                name="proposed_filter"
                onChangeEvent={handleFilter}
                data={proposedby}
              />
            </Box>
            <Box sx={{ margin: "8px" }}></Box>
            <Divider sx={{ mb: 1 }} />
          </Drawer>
        </Paper>
      )}
    </>
  );
};

export default KanbanLanding;
