import {
  Box,
  Button,
  Skeleton,
  CardHeader,
  CardContent,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
  Divider,
  Pagination
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EmailIcon from "@mui/icons-material/Email";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import SelectableSearch from "../../../Base/views/SelectableSearch";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { useDispatch, useSelector } from "react-redux";
import {
  AddAtsJobPostAction,
  UpdateAtsJobPostAction,
} from "../../../../../store/ATS/JobPost/Action";
import { GetUserCompanyConsultancyHelper } from "../../helper/AtsCompanyHelper";
import { GetAtsAllowedUsersHelper } from "../../helper/AtsUserHelper";
import FormGroup from "@mui/material/FormGroup";
import { getUserData } from "../../../Base/helper/baseFunctions";
import Switch from "@mui/material/Switch";
import Quill from "../../../Base/views/Quill";
import LinearProgress from "@mui/material/LinearProgress";
import ConfirmationDialog from "../../../Base/views/ConfirmationDialog";
import { AddAtsJobKanbanHelper } from "../../helper/AtsJobKanbanHelper";
import AtsCandidateForm from "../ATSCandidate/AtsCandidateForm";
import { GetAtsProfileScoreAction } from "../../../../../store/ATS/ProfileScore/Action";
import CreatableSearch from "../../../Base/views/CreatableSearch";
import {
  AddIndustryTypeHelper,
  GetIndustryTypeHelper,
} from "../../helper/Ancillary/IndustryTypeHelper";
import AtsJobTemplatePreview from "./AtsJobTemplatePreview";
import {
  GetJobTemplateAction,
} from "../../../../../store/ATS/JobTemplate/Action";
import {
  AddSkillsHelper,
  GetSkillsHelper,
} from "../../helper/Ancillary/SkillsHelper";
import ArrowBackButton from "../../../Base/views/ArrowBackButton";
import Base from "../../../Base/views/Base";
import history from "../../../../../history";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { GetJobTemplateHelper } from "../../helper/Ancillary/JobTemplateHelper";
import { ProposeCandidateConfirmationAction } from "../../../../../store/ATS/Candidate/Action";
import Popup from "../../../Base/views/Popup";
import FormBuilder from "../../../ATSSettings/views/FormBuilder/FormBuilder";
import {
  GetFormTemplateHelper,
  GetPreScreenTemplateHelper,
  GetOfferLetterTemplateHelper,
} from "../../../ATSSettings/helper/AtsFormTemplateHelper";
import { AddMenuHeadingAction } from "../../../../../store/Menu/Action";
import PreScreen from "../../../ATSSettings/views/PreScreen/PreScreen";
import { GetAtsJobOfferHelper } from "../../helper/JobPostHelper";
import { GetAtsGroupCompanyAction } from "../../../../../store/ATS/GroupCompany/Action";

const AtsJobPostForm = ({
  editedRecord,
  jobPostFormMode,
  setJobPostFormMode,
  canEdit,
}) => {
  const initialValues = {
    primary_recruiter_id: [],
    title: [],
    ats_company_id: "",
    ats_grouporg_id:"",
    consultancy_id: [],
    job_location: "",
    status: "Active",
    preferred_qualification: "",
    min_exp_required: "",
    max_exp_required: "",
    preferred_gender: "All",
    min_salary: "",
    max_salary: "",
    vaccancies: "",
    job_position: "",
    secondary_recruiter_id: [],
    description: "",
    industry_type: [],
    job_type: "",
    remote_job: "Hybrid",
    priority: "Low",
    primary_skill: [],
    primary_skill_name: "",
    secondary_skill: [],
    secondary_skill_name: "",
    roles_responsibilities: "",
    make_public: true,
    feedback_template_id: "",
    prescreen_template_id: "",
    offer_letter: false,
    offer_letter_template_id: "",
  };
  const dispatch = useDispatch();
  const { getsuggestions, isLoading } = useSelector(
    (state) => state.AtsReducer?.AtsProfileScoreReducer
  );
  const { atsGroupCompanyData } = useSelector(
    (state) => state.AtsReducer?.AtsGroupCompanyReducer
  );
  const [templateData, setTemplateData] = useState({ load: true, values: [] });
  const [prescreentemplateData, setprescreenTemplateData] = useState({
    load: true,
    values: [],
  });
  const [offertemplateData, setOfferTemplateData] = useState({
    load: true,
    values: [],
  });
  const [jobPostData, setPostJobData] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const [primaryRecruiterData, setPrimaryRecruiterData] = useState({
    load: true,
    values: [],
  });
  const [secondaryRecruiterData, setSecondaryRecruiterData] = useState({
    load: true,
    values: [],
  });
  const [atsCompanyData, setAtsCompanyData] = useState([]);
  const [atsConsultancyData, setAtsConsultancyData] = useState([]);
  const [companyData, setCompanyData] = useState({ load: true, values: [] });
  
  const [atsgroupUser, setAtsGroupUser] = useState({
    load: true,
    values: [],
  });
  const [consultancyData, setConsultancyData] = useState({
    load: true,
    values: [],
  });
  const [jobTitle, setJobTitle] = useState({ load: true, values: [] });
  const [industrytype, setIndustrytype] = useState({ load: true, values: [] });
  const [feedbackTemplate, setFeedackTemplate] = useState({
    load: true,
    values: [],
  });
  const [prescreenTemplate, setPrescreenTemplate] = useState({
    load: true,
    values: [],
  });
  const [offerTemplate, setOfferTemplate] = useState({
    load: true,
    values: [],
  });
  const [candidatePreview, setCandidatePreview] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [data, setData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [formBuilder, setFormBuilder] = useState(false);
  const [builderTitle, setBuilderTitle] = useState("");
  const [preformBuilder, setPreFormBuilder] = useState(false);
  const [prebuilderTitle, setPreBuilderTitle] = useState("");
  const [anchorE, setAnchorE] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [offerstatus, setOfferStatus] = useState("");
  const [rolesresponsibilities, setRolesResponsibilities] = useState("");
  const [description, setDescription] = useState("");
  const [anchor, setAnchor] = useState(false);
  const [drawerdata, setDrawerdata] = useState([]);
  const [skills, setSkills] = useState({ load: true, values: [] });
  const [page, setPage] = useState(1);
  const location = useLocation();
  const from = location.state ? location.state.from : null;
  const dashboard = [from];
  const dashBoard = dashboard?.[0] ? true : false;
  const userData = getUserData();
  const isGroupOrgFlowTrue = userData?.grouporg_flow === true;
  const showCandidate = getsuggestions.length === 0;
  useEffect(() => {
    dispatch(GetAtsGroupCompanyAction());
    if (editedRecord) {
      GetAtsJobOfferHelper({
        ats_jobpost_id: editedRecord.ats_jobpost_id,
      }).then((res) => {
       setOfferStatus(res?.data?.data?.[0]?.fn_ats_getjobpost_offer_status) 
      });
    }
    if (userData?.user_type !== "Consultancy") {
      handleToggle({ target: { checked: true } });
    }

    if (
      (jobPostFormMode !== "Add" && jobPostFormMode !== undefined) ||
      !dashBoard
    ) {
      let consultancy = editedRecord?.consultancy
        ? JSON.parse(editedRecord?.consultancy)
        : [];
      setPostJobData({
        ...editedRecord,
        ats_company_id: editedRecord?.ats_company_id
          ? {
              ats_company_id: editedRecord?.ats_company_id,
              company: editedRecord?.company,
            }
          : "",
          ats_grouporg_id: editedRecord?.ats_grouporg_id
          ? {
            ats_grouporg_id: editedRecord?.ats_grouporg_id,
              name: editedRecord?.grouporg,
            }
          : "",
        primary_recruiter_id:
          editedRecord?.precruiter?.length > 0 ? editedRecord?.precruiter : [],
        secondary_recruiter_id:
          editedRecord?.srecruiter?.length > 0 ? editedRecord?.srecruiter : [],
        consultancy_id:
          consultancy?.length > 0
            ? consultancy.filter((row) => row?.consultancy_id !== null)
            : [],
        priority: editedRecord?.priority,
        industry_type: editedRecord?.industry_type
          ? {
              industry_type_id: editedRecord?.industry_code,
              industry_name: editedRecord?.industry_type,
              code: editedRecord?.industry_code,
            }
          : "",
        title: editedRecord?.title
          ? {
              job_title: editedRecord?.title,
              job_template_id: editedRecord?.job_title_id,
            }
          : "",
        primary_skill: editedRecord?.primary_skills_json
          ? JSON.parse(editedRecord?.primary_skills_json)
          : [],
        secondary_skill: editedRecord?.secondary_skills_json
          ? JSON.parse(editedRecord?.secondary_skills_json)
          : [],
        feedback_template_id: editedRecord?.feedback_template_id
          ? {
              template_id: editedRecord?.feedback_template_id,
              template_name: editedRecord?.template_name,
            }
          : "",
        prescreen_template_id: editedRecord?.prescreen_template_id
          ? {
              prescreen_template_id: editedRecord?.prescreen_template_id,
              prescreen_template_name: editedRecord?.prescreen_template_name,
            }
          : "",
        offer_letter_template_id: editedRecord?.offer_letter_template_id
          ? {
              offer_letter_template_id: editedRecord?.offer_letter_template_id,
              offer_letter_template_name:
                editedRecord?.offer_letter_template_name,
            }
          : "",
      });
      setRolesResponsibilities(editedRecord?.roles_responsibilities);
      setDescription(editedRecord?.description);
      setToggle(editedRecord?.is_internal || (isGroupOrgFlowTrue && userData?.user_type === "Group User") ? true : false);
    }
    GetUserCompanyConsultancyHelper().then((res) => {
      setAtsCompanyData(res?.data?.data?.[0]?.company);
      setAtsConsultancyData(res?.data?.data?.[0]?.consultancy);
      if (userData?.user_type === "Consultancy") {
        if (jobPostFormMode === "Add" || dashBoard) {
          setPostJobData((jobPostData) => ({
            ...jobPostData,
            consultancy_id: res?.data?.data?.[0]?.consultancy,
          }));
        }
      }
    });
    if (jobPostFormMode === "Preview") {
      getData();
    }
    GetFormTemplateHelper().then((res) => {
      setTemplateData({ load: false, values: res?.data?.data });
      if (jobPostFormMode === "Add" || dashBoard) {
        const defaultTemplate = res?.data?.data.find(
          (template) => template.is_default === true
        );
        // handleToggle({ 'target': { 'checked': true } })
        setPostJobData((jobPostData) => ({
          ...jobPostData,
          feedback_template_id: defaultTemplate
            ? {
                template_id: defaultTemplate?.template_id,
                template_name: defaultTemplate?.template_name,
              }
            : "",
        }));
      }
    });

    GetPreScreenTemplateHelper().then((res) => {
      setprescreenTemplateData({ load: false, values: res?.data?.data });
      if (jobPostFormMode === "Add" || dashBoard) {
        const defaultTemplate = res?.data?.data.find(
          (template) => template.is_default === true
        );
        // handleToggle({ 'target': { 'checked': true } })
        setPostJobData((jobPostData) => ({
          ...jobPostData,
          prescreen_template_id: defaultTemplate
            ? {
                prescreen_template_id: defaultTemplate?.prescreen_template_id,
                prescreen_template_name:
                  defaultTemplate?.prescreen_template_name,
              }
            : "",
        }));
      }
    });
    //
    GetOfferLetterTemplateHelper().then((res) => {
      setOfferTemplateData({ load: false, values: res?.data?.data });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!editedRecord, !candidatePreview]);

  const getData = (e) => {
    dispatch(
      GetAtsProfileScoreAction({
        domain: userData?.domain,
        ats_jobpost_id: editedRecord.ats_jobpost_id,
        page_number: typeof e === "number" ? e : 1,
        page_size: 12,
      })
    );
  };
  const tabTypeIndex=sessionStorage.getItem("tabTypeIndex");
  let state;
  if (tabTypeIndex === "0") {
    state = "Active";
  } else if (tabTypeIndex === "1") {
    state = "Inactive";
  } else if (tabTypeIndex === "2") {
    state = "Archive";
  }
  const handlePagination = (event, value) => {
    setPage(value);
    getData(value);
  };
  const proposeCandidate = (value) => {
    setData(value);
    setAnchorE(null);
    setOpenDialog(true);
  };
  const handleClickPreview = (value) => {
    setCurrentData(value);
    setCandidatePreview(true);
  };
  const handleConfirmPropose = () => {
    AddAtsJobKanbanHelper({
      kanban_data: JSON.stringify([
        {
          candidate_id: data?.candidate_id,
          ats_jobpost_id: editedRecord?.ats_jobpost_id,
          comment: null,
          ats_job_stages_id: null,
          priority: 5,
          ats_status_id: null,
        },
      ]),
    }).then((res) => {
      if (res?.data?.statuscode === 200) {
        dispatch(
          ProposeCandidateConfirmationAction({
            candidate_data: data,
            job_data: currentData,
            kanban_data: res?.data?.data?.[0],
          })
        );
      }
      getData();
    });
    setOpenDialog(false);
  };
  const validate = (fieldValues, description, rolesresponsibilities) => {
    let temp = { ...errors };

    if ("title" in fieldValues) {
      temp.title =
        fieldValues.title?.length === 0
          ? "Job Title is required"
          : fieldValues?.title === null
          ? "Job Title is required"
          : "";
    }
    if ("industry_type" in fieldValues) {
      temp.industry_type =
        fieldValues.industry_type?.length === 0
          ? "Industry Sector is required"
          : fieldValues?.industry_type === null
          ? "Industry Sector is required"
          : "";
    }
    if(isGroupOrgFlowTrue){
      if ("ats_grouporg_id" in fieldValues) {
        temp.ats_grouporg_id =
        fieldValues.is_internal === true
            ? ""
            : fieldValues.ats_grouporg_id === ""
            ? "Group Org is required"
            : "";
      }
    }
    else{
      if ("ats_company_id" in fieldValues) {
        temp.ats_company_id =
          jobPostData.is_internal === true
            ? ""
            : fieldValues.ats_company_id === ""
            ? "Client is required"
            : "";
      }
    }
    
    if ("job_location" in fieldValues) {
      temp.job_location = ["", null].includes(fieldValues.job_location?.trim())
        ? "Job Location is required"
        : "";
    }
    if ("vaccancies" in fieldValues) {
      temp.vaccancies =
        fieldValues.vaccancies === "" ? "Vacancies is required" : "";
    }
    if ("primary_skill" in fieldValues) {
      temp.primary_skill =
        fieldValues.primary_skill?.length === 0
          ? "Primary Skill is required"
          : fieldValues.primary_skill === null
          ? "Primary Skill is required"
          : "";
    }
    if ("primary_recruiter_id" in fieldValues) {
      temp.primary_recruiter_id =
        fieldValues.primary_recruiter_id?.length === 0
          ? "Primary Recruiter is required"
          : fieldValues.primary_recruiter_id === null
          ? "Primary Recruiter is required"
          : "";
    }
    if ("min_salary" in fieldValues) {
      temp.min_salary =
        fieldValues?.max_salary === ""
          ? ""
          : +fieldValues?.max_salary < +fieldValues?.min_salary
          ? "Minimum salary must be lesser than Maximum salary"
          : "";
    }
    if ("min_exp_required" in fieldValues) {
      temp.min_exp_required =
        fieldValues?.max_exp_required === ""
          ? ""
          : +fieldValues?.max_exp_required < +fieldValues?.min_exp_required
          ? "Minimum experience must be lesser than maximum experience"
          : "";
    }
    if ("description" in fieldValues) {
      temp.description =
        description === ""
          ? "Description is required"
          : description === "<p><br></p>"
          ? "Description is required"
          : "";
    }
    if ("roles_responsibilities" in fieldValues) {
      temp.roles_responsibilities =
        rolesresponsibilities === ""
          ? "Roles and Responsibilities are required"
          : rolesresponsibilities === "<p><br></p>"
          ? "Roles and Responsibilities are required"
          : "";
    }
    if ("offer_letter" in fieldValues) {
      if (fieldValues?.offer_letter) {
        temp.offer_letter_template_id =
          fieldValues?.offer_letter_template_id === ""
            ? "Offer Letter is required"
            : "";
      } else {
        temp.offer_letter_template_id = "";
      }
    }
    setErrors({
      ...temp,
    });
    return Object.values(temp).every((x) => x === "");
  };

  const handleChange = (event, value) => {
    if (["ats_company_id", "consultancy_id","ats_grouporg_id"].includes(event)) {
      if (value)
        setPostJobData({
          ...jobPostData,
          [event]: value,
          primary_recruiter_id: [],
          secondary_recruiter_id: [],
        });
      else
        setPostJobData({
          ...jobPostData,
          [event]: "",
          primary_recruiter_id: [],
          secondary_recruiter_id: [],
        });
      setPrimaryRecruiterData({ load: true, values: [] });
      setSecondaryRecruiterData({ load: true, values: [] });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, [event]: value ? value : "" });
    } else if (["primary_recruiter_id"].includes(event)) {
      if (value)
        setPostJobData({
          ...jobPostData,
          primary_recruiter_id: value,
        });
      else
        setPostJobData({
          ...jobPostData,
          primary_recruiter_id: "",
        });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, primary_recruiter_id: value ? value : [] });
    } else if (["primary_skill", "secondary_skill"].includes(event)) {
      if (value) {
        const inputValues = value?.filter(
          (row) =>
            row.inputValue &&
            row.skill &&
            +value.findIndex(
              (obj) => obj?.inputValue === value.at(-1)?.inputValue
            ) ===
              +value?.length - 1
        );
        const skillNames = value
          ?.map((row) => row.inputValue || row.skill)
          .join(", ");
        if (
          inputValues?.length > 0 &&
          jobPostData?.industry_type?.industry_type_id
        ) {
          const existingSkills = jobPostData[event] || [];
          const newSkills = inputValues?.filter(
            (row) =>
              !existingSkills.some((skill) => skill?.skill === row?.skill)
          );
          if (newSkills.length > 0) {
            const updatedSkills = [
              ...existingSkills,
              ...newSkills.map((newSkill) => ({ skill: newSkill.inputValue })),
            ];
            const newSkillNames = updatedSkills
              .map((skill) => skill.skill)
              .join(", ");
            newSkills.forEach((newSkill) => {
              AddSkillsHelper({
                industry_code: jobPostData?.industry_type?.code,
                skill_name: newSkill.inputValue,
              }).then((res) => {
                if (res?.data?.statuscode === 200 && res?.data?.data.length > 0)
                  setSkills({ load: false, values: res?.data?.data });
              });
            });
            setPostJobData({
              ...jobPostData,
              [event]: updatedSkills,
              [event === "primary_skill"
                ? "primary_skill_name"
                : "secondary_skill_name"]: newSkillNames,
            });
          }
        } else {
          setPostJobData({
            ...jobPostData,
            [event]: value,
            [event === "primary_skill"
              ? "primary_skill_name"
              : "secondary_skill_name"]: skillNames,
          });
        }
      } else setPostJobData({ ...jobPostData, [event]: [] });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, [event]: value ? value : [] });
    } else if (["secondary_recruiter_id"].includes(event)) {
      if (value) {
        setPostJobData({ ...jobPostData, [event]: value });
      } else setPostJobData({ ...jobPostData, [event]: [] });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, [event]: value ? value : [] });
    } else if (["title"].includes(event)) {
      if (value) {
        setPostJobData({ ...jobPostData, [event]: value });
        toggleDrawer(true, value, jobPostData);
      } else setPostJobData({ ...jobPostData, title: [] });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, title: value ? value : [] });
    } else if (["industry_type"].includes(event)) {
      if (value?.id === null) {
        AddIndustryTypeHelper({ industry_name: value?.industry_name }).then(
          (res) => {
            if (res.data?.statuscode === 200) {
              setIndustrytype({ load: false, values: res?.data?.data });
              setPostJobData({
                ...jobPostData,
                [event]: res?.data?.data?.filter(
                  (resp) => resp?.industry_name === value?.industry_name
                )?.[0],
                title: [],
              });
            }
          }
        );
      } else if (value)
        setPostJobData({
          ...jobPostData,
          [event]: value,
          title: [],
          primary_skill: [],
          secondary_skill: [],
        });
      else
        setPostJobData({
          ...jobPostData,
          [event]: [],
          title: [],
          primary_skill: [],
          secondary_skill: [],
        });
      toggleDrawer(false, value);
      setJobTitle({ load: true, values: [] });
      setSkills({ load: true, values: [] });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, industry_type: value ? value : [] });
    } else if (["feedback_template_id"].includes(event)) {
      if (value) {
        if (value?.id === null) {
          setFeedackTemplate({ load: false, values: [] });
          setBuilderTitle(value?.template_name);
          setFormBuilder(true);
        } else setPostJobData({ ...jobPostData, [event]: value });
      } else setPostJobData({ ...jobPostData, [event]: "" });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, feedback_template_id: value ? value : "" });
    } else if (["prescreen_template_id"].includes(event)) {
      if (value) {
        if (value?.id === null) {
          setPrescreenTemplate({ load: false, values: [] });
          setPreBuilderTitle(value?.prescreen_template_name);
          setPreFormBuilder(true);
        } else setPostJobData({ ...jobPostData, [event]: value });
      } else setPostJobData({ ...jobPostData, [event]: "" });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, prescreen_template_id: value ? value : "" });
    } else if (["offer_letter_template_id"].includes(event)) {
      if (value) {
        setPostJobData({ ...jobPostData, [event]: value });
      } else setPostJobData({ ...jobPostData, [event]: "" });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({
          ...jobPostData,
          offer_letter_template_id: value ? value : "",
        });
    }  else if (["make_public"].includes(event.target.name)) {
      setPostJobData({
        ...jobPostData,
        [event.target.name]: event.target.value === "true" ? true : false,
      });
    }  else if (["offer_letter"].includes(event?.target?.name)) {
      setPostJobData({
        ...jobPostData,
        [event.target.name]: event.target.value === "true" ? true : false,
        offer_letter_template_id: "",
      });
    }
    else {
      setPostJobData({
        ...jobPostData,
        [event.target.name]: event.target.value,
      });
      Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
        validate({ ...jobPostData, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = () => {
    if (validate(jobPostData, description, rolesresponsibilities)) {
      let primary_recruiter = [];
      let secondary_recruiter = [];
      let consultancy = [];
      let data = {
        // primary_recruiter_id: jobPostData?.primary_recruiter_id?.recruiter_id || null,
        title: jobPostData?.title?.job_title,
        job_title_id: jobPostData?.title?.job_template_id || null,
        ats_company_id: +jobPostData?.ats_company_id?.ats_company_id || null,
        job_location: jobPostData?.job_location,
        status: jobPostData?.status,
        preferred_qualification: jobPostData?.preferred_qualification,
        min_exp_required: +jobPostData?.min_exp_required || null,
        max_exp_required: +jobPostData?.max_exp_required || null,
        preferred_gender: jobPostData?.preferred_gender,
        min_salary: +jobPostData?.min_salary || null,
        max_salary: +jobPostData?.max_salary || null,
        vaccancies: jobPostData?.vaccancies || null,
        job_position: jobPostData?.job_position,
        secondary_recruiter_id:
          jobPostData?.secondary_recruiter_id?.recruiter_id || null,
        description: description,
        industry_type: jobPostData?.industry_type?.industry_name,
        industry_code: jobPostData?.industry_type?.code || null,
        job_type: jobPostData?.job_type,
        remote_job: jobPostData?.remote_job || null,
        priority: jobPostData?.priority,
        primary_skill: jobPostData?.primary_skill_name,
        secondary_skill: jobPostData?.secondary_skill_name,
        // is_internal: toggle ? true : false,
         is_internal : (toggle && !(isGroupOrgFlowTrue && userData?.user_type === "Group User")) ? true : false,
        roles_responsibilities: rolesresponsibilities,
        primary_skills_json: jobPostData?.primary_skill,
        secondary_skills_json: jobPostData?.secondary_skill,
        make_public: jobPostData?.make_public,
        feedback_template_id: +jobPostData?.feedback_template_id?.template_id,
        prescreen_template_id:
          +jobPostData?.prescreen_template_id?.prescreen_template_id,
        offer_letter: jobPostData?.offer_letter,
        offer_letter_template_id:
          +jobPostData?.offer_letter_template_id?.offer_letter_template_id ||
          null,
          ats_grouporg_id:+jobPostData?.ats_grouporg_id?.ats_grouporg_id || +jobPostData?.ats_grouporg_id || null,
      };
      jobPostData?.primary_recruiter_id?.length > 0 &&
        jobPostData?.primary_recruiter_id?.forEach((res) =>
          primary_recruiter.push({ primary_recruiter_id: +res?.recruiter_id })
        );

      jobPostData?.secondary_recruiter_id?.length > 0 &&
        jobPostData?.secondary_recruiter_id?.forEach((res) =>
          secondary_recruiter.push({
            secondary_recruiter_id: +res?.recruiter_id,
          })
        );

      jobPostData?.consultancy_id?.length > 0 &&
        jobPostData?.consultancy_id?.forEach((res) =>
          consultancy.push({ consultancy_id: +res?.consultancy_id })
        );

      if (editedRecord?.ats_jobpost_id)
        data = { ...data, ats_jobpost_id: editedRecord?.ats_jobpost_id };
      if (jobPostData?.ats_jobpost_id) {
        dispatch(
          UpdateAtsJobPostAction({
            jobpost_data: JSON.stringify([data]),
            consultancy_id:
              consultancy?.length > 0 ? JSON.stringify(consultancy) : null,
            primary_recruiter:
              primary_recruiter?.length > 0
                ? JSON.stringify(primary_recruiter)
                : false,
            secondary_recruiter:
              secondary_recruiter?.length > 0
                ? JSON.stringify(secondary_recruiter)
                : false,
            tab_type:state,
          })
        );
      } else {
        dispatch(
          AddAtsJobPostAction({
            jobpost_data: JSON.stringify([data]),
            consultancy_id:
              consultancy?.length > 0 ? JSON.stringify(consultancy) : null,
            primary_recruiter:
              primary_recruiter?.length > 0
                ? JSON.stringify(primary_recruiter)
                : false,
            secondary_recruiter:
              secondary_recruiter?.length > 0
                ? JSON.stringify(secondary_recruiter)
                : false,
          })
        );
      }
      if (dashBoard) {
        history.push("/ats/manage-job");
        sessionStorage.setItem("selectedMenu", "Job Dashboard");
        dispatch(AddMenuHeadingAction({ heading: "Job Dashboard" }));
      }
      else{
        setJobPostFormMode("Card");
      }
    }
  };
 

  const handleClose = () => {
    if (dashBoard) {
      setPostJobData(initialValues);
      setErrors({});
      history.push("/ats/manage-job");
      sessionStorage.setItem("selectedMenu", "Job Dashboard");
      dispatch(AddMenuHeadingAction({ heading: "Job Dashboard" }));
    } else {
      setJobPostFormMode("Card");
      setPostJobData(initialValues);
      setErrors({});
    }
  };
  const handleEdit = () => {
    setJobPostFormMode("Edit");
    setErrors({});
  };

  const handlePrimaryRecruiterLoading = () => {
    if (
      jobPostData?.ats_company_id !== "" ||
      jobPostData?.ats_grouporg_id !== "" ||
      jobPostData?.is_internal === true
    ) {
      const companyId = jobPostData?.ats_company_id?.ats_company_id 
      || jobPostData?.ats_grouporg_id?.ats_grouporg_id 
      || (isGroupOrgFlowTrue && userData?.user_type === "Group User" ? userData?.ats_grouporg_id : "Internal");
      GetAtsAllowedUsersHelper({
        company_id: companyId,
        consultancy_id: `{${jobPostData?.consultancy_id
          ?.map((row) => row?.consultancy_id)
          .join(",")}}`,
        recruiter_id: jobPostData?.secondary_recruiter_id?.recruiter_id || null,
      }).then((res) => {
        setPrimaryRecruiterData({ load: false, values: res?.data?.data });
      });
    } else setPrimaryRecruiterData({ load: false, values: [] });
  };

  const handleSecondaryRecruiterLoading = () => {
    if (
      jobPostData?.ats_company_id !== "" ||
      jobPostData?.ats_grouporg_id !== "" ||
      jobPostData?.is_internal === true
    ) {
      const companyId = jobPostData?.ats_company_id?.ats_company_id 
      || jobPostData?.ats_grouporg_id?.ats_grouporg_id 
      || (isGroupOrgFlowTrue && userData?.user_type === "Group User" ? userData?.ats_grouporg_id : "Internal");
      GetAtsAllowedUsersHelper({
        company_id:companyId,
        consultancy_id: `{${jobPostData?.consultancy_id
          ?.map((row) => row?.consultancy_id)
          .join(",")}}`,
        recruiter_id: jobPostData?.primary_recruiter_id?.recruiter_id || null,
      }).then((res) => {
        setSecondaryRecruiterData({ load: false, values: res?.data?.data });
      });
    } else setSecondaryRecruiterData({ load: false, values: [] });
  };

  const handleToggle = (e) => {
    if (e?.target?.checked === true) {
      setToggle(true);
      setPostJobData({
        ...jobPostData,
        [isGroupOrgFlowTrue ? 'ats_grouporg_id' : 'ats_company_id']: (isGroupOrgFlowTrue && userData?.user_type ==="Group User")? userData?.ats_grouporg_id: "Internal",
        primary_recruiter_id: [],
        secondary_recruiter_id: [],
      });
    } else {
      setToggle(false);
      setPostJobData({
        ...jobPostData,
        ats_grouporg_id:"",
        ats_company_id: "",
        primary_recruiter_id: [],
        secondary_recruiter_id: [],
      });
    }
    setPrimaryRecruiterData({ load: true, values: [] });
    setSecondaryRecruiterData({ load: true, values: [] });
  };

  const toggleDrawer = (open, data, jobPostData) => {
    setAnchor(open);
    setDrawerdata(data);
    open && dispatch(GetJobTemplateAction({ job_title: data?.job_title, industry_code: data?.industry_code || jobPostData?.industry_type?.code }));
  };

  const handleIndustryTypeLoading = () => {
    GetIndustryTypeHelper().then((res) => {
      if (res?.data?.statuscode === 200)
        setIndustrytype({ load: false, values: res?.data?.data });
    });
  };

  const handleJobTemplateLoading = () => {
    jobPostData?.industry_type?.industry_type_id
      ? GetJobTemplateHelper({
          industry_code: jobPostData?.industry_type?.code,
        }).then((res) => {
          if (res?.data?.statuscode === 200 && res?.data?.data?.length > 0) {
            setJobTitle({ load: false, values: res?.data?.data });
          }
        })
      : setJobTitle({ load: false, values: [] });
  };

  const handleFeedBackTemplateLoading = () => {
    if (templateData?.values?.length > 0) {
      setFeedackTemplate({ load: false, values: templateData?.values });
    } else setFeedackTemplate({ load: false, values: [] });
  };

  const handlepreScreenTemplateLoading = () => {
    if (prescreentemplateData?.values?.length > 0) {
      setPrescreenTemplate({
        load: false,
        values: prescreentemplateData?.values,
      });
    } else setPrescreenTemplate({ load: false, values: [] });
  };
  const handleOfferTemplateLoading = () => {
    if (offertemplateData?.values?.length > 0) {
      setOfferTemplate({ load: false, values: offertemplateData?.values });
    } else setOfferTemplate({ load: false, values: [] });
  };

  const handleApplyTemplate = (data) => {
    setAnchor(false);
    setRolesResponsibilities(data?.roles_responsibilities);
    setDescription(data?.description);
  };
  const handleATSSettings = () => {
    history.push({
      pathname: "/ats/ats-settings/",
      state: { defaultValue: "2" },
    });
  };
  const handleAddHiringPartner = () => {
    history.push({
      pathname: "/ats/manage-consultancy",
      state: { defaultValue: "add" },
    });
  };
  const handleAddClient = () => {
    history.push({
      pathname: "/ats/manage-company",
      state: { defaultValue: "add" },
    });
  };
  const handleAddGrp = () => {
    history.push({
      pathname: "/ats/manage-group-company",
      state: { defaultValue: "add" },
    });
  };
  const handleSkillsLoading = () => {
    jobPostData?.industry_type?.industry_type_id
      ? GetSkillsHelper({
          industry_code: jobPostData?.industry_type?.code,
        }).then((res) => {
          if (res?.data?.statuscode === 200 && res?.data?.data?.length > 0)
            setSkills({ load: false, values: res?.data?.data });
        })
      : setSkills({ load: false, values: [] });
  };

  return (
    <>
      {dashBoard ? (
        <Base>
          <>
            {candidatePreview === true ? (
              <AtsCandidateForm
                setCandidatePreview={setCandidatePreview}
                currentData={currentData}
                isProfile={true}
                editedRecord={editedRecord}
              />
            ) : (
              <>
                <Box sx={{ maxHeight: "90vh", overflowY: "auto", pb: 2 }}>
                  <Box
                    sx={{
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                      backgroundColor: "#ffffff",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      p: 2,
                    }}
                  >
                    <ArrowBackButton onClick={handleClose} />
                    <Typography
                      sx={{
                        fontFamily: "Nunito Sans",
                        fontWeight: 700,
                        color: "#000000",
                        fontSize: "large",
                      }}
                    >
                      {jobPostFormMode === "Preview"
                        ? "Preview Job"
                        : jobPostFormMode === "Edit"
                        ? "Update Job"
                        : "Create New Job"}
                    </Typography>
                    {jobPostFormMode === "Preview" && (
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {canEdit && (
                          <Button
                            variant="contained"
                            className="mpl-primary-btn"
                            onClick={handleEdit}
                          >
                            Edit
                          </Button>
                        )}
                      </Box>
                    )}
                    {jobPostFormMode !== "Preview" && (
                      <Button
                        sx={{ ml: 1 }}
                        variant="contained"
                        className="mpl-primary-btn"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        {jobPostData?.ats_jobpost_id ? "Update" : "Save"}
                      </Button>
                    )}
                  </Box>
                  <Paper
                    sx={{ borderRadius: "10px", p: 1.5, overflowY: "auto" }}
                  >
                    {jobPostFormMode !== "Preview" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {["Admin", "Owner", "Internal"].includes(userData?.user_type) && (
                            <>
                              <div style={{ display: "flex" }}>
                                {userData?.user_type !== "Internal" && (
                                  <>
                                    <div style={{ display: "flex", marginRight: "8px" }}>
                                      <Typography>
                                        *To review your industry sectors/ job titles/ skills{" "}
                                        <span
                                          style={{ color: "#1155cc", cursor: "pointer", textDecoration: "underline" }}
                                          onClick={handleATSSettings}
                                        >
                                          Click Here
                                        </span>
                                      </Typography>
                                    </div>
                                    <div style={{ display: "flex", marginRight: "8px" }}>
                                      <Typography>
                                        *To add Hiring Partners{" "}
                                        <span
                                          style={{ color: "#1155cc", cursor: "pointer", textDecoration: "underline" }}
                                          onClick={handleAddHiringPartner}
                                        >
                                          Click Here
                                        </span>
                                      </Typography>
                                    </div>
                                    {toggle === false && (
                                      <>
                                        {isGroupOrgFlowTrue ? (
                                          <div style={{ display: "flex" }}>
                                            <Typography>
                                              *To add a Group Org{" "}
                                              <span
                                                style={{ color: "#1155cc", cursor: "pointer", textDecoration: "underline" }}
                                                onClick={handleAddGrp}
                                              >
                                                Click Here
                                              </span>
                                            </Typography>
                                          </div>
                                        ) : (
                                          <div style={{ display: "flex" }}>
                                            <Typography>
                                              *To add a Client{" "}
                                              <span
                                                style={{ color: "#1155cc", cursor: "pointer", textDecoration: "underline" }}
                                                onClick={handleAddClient}
                                              >
                                                Click Here
                                              </span>
                                            </Typography>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>

                              <div>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={handleToggle}
                                        checked={toggle}
                                        disabled={["Edit", "Preview"].includes(jobPostFormMode)}
                                      />
                                    }
                                    label="is internal job"
                                  />
                                </FormGroup>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    )}
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <Card>
                            <CardContent>
                              <Grid container sx={{ mt: 1 }}>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  {toggle === false ? (
                                    
                                    <>
                                    {isGroupOrgFlowTrue ? (
                                     
                                  <SelectableSearch
                                      isOptionEqualToValue={(option, value) =>
                                        +option.ats_grouporg_id ===
                                        +value.ats_grouporg_id
                                      }
                                      disabled={jobPostFormMode === "Preview"}
                                      label="Group Org"
                                      value={jobPostData?.ats_grouporg_id}
                                      loading={() =>
                                        setAtsGroupUser({
                                          load: false,
                                          values: atsGroupCompanyData,
                                        })
                                      }
                                      fieldLabel="name"
                                      required
                                      variant={"contained"}
                                      name="ats_grouporg_id"
                                      size="small"
                                      data={atsgroupUser}
                                      onChangeEvent={handleChange}
                                      {...(errors.ats_grouporg_id && {
                                        error: true,
                                        helperText: errors.ats_grouporg_id,
                                      })}
                                    />
                                    ) : (
                                      <SelectableSearch
                                      isOptionEqualToValue={(option, value) =>
                                        +option.ats_company_id ===
                                        +value.ats_company_id
                                      }
                                      disabled={jobPostFormMode === "Preview"}
                                      label="Client"
                                      value={jobPostData?.ats_company_id}
                                      loading={() =>
                                        setCompanyData({
                                          load: false,
                                          values: atsCompanyData,
                                        })
                                      }
                                      fieldLabel="company"
                                      required
                                      variant={"contained"}
                                      name="ats_company_id"
                                      size="small"
                                      data={companyData}
                                      onChangeEvent={handleChange}
                                      {...(errors.ats_company_id && {
                                        error: true,
                                        helperText: errors.ats_company_id,
                                      })}
                                    />
                                    )}
                                    </>
                                  ) : (
                                    <>
  {isGroupOrgFlowTrue ? (
    userData?.user_type === "Group User" ? (
      <TextField
        disabled={true}
        name="ats_grouporg_id"
        label="Group Org"
        size="small"
        fullWidth
        variant="outlined"
        value={userData?.ats_grouporg_name}
      />
    ) : (
      <TextField
        disabled={true}
        name="ats_grouporg_id"
        label="Group Org"
        size="small"
        fullWidth
        variant="outlined"
        value={
          ["", null, false, "Internal"].includes(jobPostData?.ats_grouporg_id)
            ? "Internal"
            : ""
        }
      />
    )
  ) : (
    <TextField
      disabled={true}
      name="ats_company_id"
      label="Client"
      size="small"
      fullWidth
      variant="outlined"
      value={
        ["", null, false, "Internal"].includes(jobPostData?.ats_company_id)
          ? "Internal"
          : ""
      }
    />
  )}
</>
                                 
                                  )}
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  {userData?.user_type === "Consultancy" ? (
                                    <TextField
                                      disabled={true}
                                      name="consultancy_id"
                                      label="Hiring Partner"
                                      size="small"
                                      fullWidth
                                      variant="outlined"
                                      value={
                                        atsConsultancyData?.[0]?.consultancy
                                      }
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                    />
                                  ) : (
                                    <SelectableSearch
                                      isOptionEqualToValue={(option, value) =>
                                        +option.consultancy_id ===
                                        +value.consultancy_id
                                      }
                                      disabled={jobPostFormMode === "Preview"}
                                      label="Hiring Partner"
                                      value={jobPostData?.consultancy_id}
                                      loading={() =>
                                        setConsultancyData({
                                          load: false,
                                          values: atsConsultancyData,
                                        })
                                      }
                                      fieldLabel="consultancy"
                                      required={false}
                                      multiple={true}
                                      variant={"contained"}
                                      name="consultancy_id"
                                      size="small"
                                      data={consultancyData}
                                      onChangeEvent={handleChange}
                                      {...(errors.consultancy_id && {
                                        error: true,
                                        helperText: errors.consultancy_id,
                                      })}
                                    />
                                  )}
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <SelectableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      +option.recruiter_id ===
                                      +value.recruiter_id
                                    }
                                    disabled={jobPostFormMode === "Preview"}
                                    required={true}
                                    multiple={true}
                                    label="Primary Recruiter"
                                    noOptionText="Please select client to choose recruiter"
                                    value={jobPostData?.primary_recruiter_id}
                                    loading={() =>
                                      handlePrimaryRecruiterLoading()
                                    }
                                    fieldLabel="recruiter"
                                    variant={"contained"}
                                    name="primary_recruiter_id"
                                    size="small"
                                    data={primaryRecruiterData}
                                    onChangeEvent={handleChange}
                                    {...(errors.primary_recruiter_id && {
                                      error: true,
                                      helperText: errors.primary_recruiter_id,
                                    })}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option?.recruiter} -{" "}
                                        {option?.comcons_name
                                          ? option?.comcons_name
                                          : option?.user_type}
                                      </Box>
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <CreatableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      +option.industry_name ===
                                      +value.industry_name
                                    }
                                    disabled={jobPostFormMode === "Preview"}
                                    label="Industry Sector"
                                    value={jobPostData?.industry_type}
                                    fieldLabel="industry_name"
                                    loading={() => handleIndustryTypeLoading()}
                                    required={true}
                                    variant={"contained"}
                                    noOptionText="Start typing to add or select industry"
                                    name="industry_type"
                                    size="small"
                                    data={industrytype}
                                    onChangeEvent={handleChange}
                                    {...(errors.industry_type && {
                                      error: true,
                                      helperText: errors.industry_type,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <CreatableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      +option.job_template_id ===
                                      +value.job_template_id
                                    }
                                    disabled={jobPostFormMode === "Preview"}
                                    label="Job Title"
                                    value={jobPostData?.title}
                                    noOptionText="Please select Industry Sector to choose title"
                                    fieldLabel="job_title"
                                    loading={() => handleJobTemplateLoading()}
                                    onBlur={() => setJobTitle({ load: false, values: [] })}
                                    required={true}
                                    variant={"contained"}
                                    name="title"
                                    size="small"
                                    data={jobTitle}
                                    onChangeEvent={handleChange}
                                    {...(errors.title && {
                                      error: true,
                                      helperText: errors.title,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <CreatableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      option.skill === value.skill
                                    }
                                    disabled={jobPostFormMode === "Preview"}
                                    label="Primary Skills Required"
                                    value={jobPostData?.primary_skill}
                                    fieldLabel="skill"
                                    loading={() =>
                                      jobPostData?.industry_type
                                        ?.industry_type_id
                                        ? handleSkillsLoading()
                                        : setSkills({ load: false, values: [] })
                                    }
                                    required={true}
                                    noOptionText="Please select Industry Sector to choose skills"
                                    variant={"contained"}
                                    name="primary_skill"
                                    size="small"
                                    optionLabel="skill"
                                    multiple={true}
                                    data={skills}
                                    onChangeEvent={handleChange}
                                    {...(errors.primary_skill && {
                                      error: true,
                                      helperText: errors.primary_skill,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <TextField
                                    disabled={jobPostFormMode === "Preview"}
                                    name="vaccancies"
                                    label="Vacancies"
                                    size="small"
                                    type="number"
                                    onKeyPress={(e) =>
                                      e.target.value?.length > 4 &&
                                      e.preventDefault()
                                    }
                                    onPaste={(e) =>
                                      e.target.value?.length > 4 &&
                                      e.preventDefault()
                                    }
                                    required={true}
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    value={jobPostData.vaccancies}
                                    {...(errors.vaccancies && {
                                      error: true,
                                      helperText: errors.vaccancies,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <TextField
                                    disabled={jobPostFormMode === "Preview"}
                                    name="job_location"
                                    label="Job Location"
                                    size="small"
                                    required={true}
                                    onChange={handleChange}
                                    onKeyPress={(e) =>
                                      e.target.value?.length > 100 &&
                                      e.preventDefault()
                                    }
                                    onPaste={(e) =>
                                      e.target.value?.length > 100 &&
                                      e.preventDefault()
                                    }
                                    fullWidth
                                    variant="outlined"
                                    value={jobPostData.job_location}
                                    {...(errors?.job_location && {
                                      error: true,
                                      helperText: errors?.job_location,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <CreatableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      +option.template_id === +value.template_id
                                    }
                                    disabled={jobPostFormMode === "Preview"}
                                    label="Feedback Template"
                                    name="feedback_template_id"
                                    value={jobPostData?.feedback_template_id}
                                    loading={() =>
                                      handleFeedBackTemplateLoading()
                                    }
                                    fieldLabel="template_name"
                                    variant={"contained"}
                                    size="small"
                                    data={feedbackTemplate}
                                    onChangeEvent={handleChange}
                                    {...(errors.feedback_template_id && {
                                      error: true,
                                      helperText: errors.feedback_template_id,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <CreatableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      +option.prescreen_template_id ===
                                      +value.prescreen_template_id
                                    }
                                    disabled={jobPostFormMode === "Preview"}
                                    label="Pre Screen Questions Template"
                                    name="prescreen_template_id"
                                    value={jobPostData?.prescreen_template_id}
                                    loading={() =>
                                      handlepreScreenTemplateLoading()
                                    }
                                    fieldLabel="prescreen_template_name"
                                    variant={"contained"}
                                    size="small"
                                    data={prescreenTemplate}
                                    onChangeEvent={handleChange}
                                    {...(errors.prescreen_template_id && {
                                      error: true,
                                      helperText: errors.prescreen_template_id,
                                    })}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  sx={{
                                    p: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <FormControl
                                    disabled={
                                      jobPostFormMode === "Preview" || offerstatus
                                    }
                                  >
                                    <FormLabel
                                      id="demo-row-radio-buttons-group-label"
                                      sx={{ mr: 2 }}
                                    >
                                      Include offer letter work flow?
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="offer_letter"
                                      onChange={handleChange}
                                      value={jobPostData?.offer_letter}
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                {jobPostData?.offer_letter && (
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <CreatableSearch
                                      isOptionEqualToValue={(option, value) =>
                                        +option.offer_letter_template_id ===
                                        +value.offer_letter_template_id
                                      }
                                      disabled={jobPostFormMode === "Preview" || offerstatus}
                                      label="Offer Letter Template"
                                      name="offer_letter_template_id"
                                      value={
                                        jobPostData?.offer_letter_template_id
                                      }
                                      loading={() =>
                                        handleOfferTemplateLoading()
                                      }
                                      fieldLabel="offer_letter_template_name"
                                      variant={"contained"}
                                      size="small"
                                      data={offerTemplate}
                                      onChangeEvent={handleChange}
                                      {...(errors.offer_letter_template_id && {
                                        error: true,
                                        helperText:
                                          errors.offer_letter_template_id,
                                      })}
                                    />
                                  </Grid>
                                )}
                                <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                  <Divider
                                    style={{
                                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                                    }}
                                  />
                                </Grid>
                                <Grid container sx={{ mt: 1 }}>
                                  {/* <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                                            <TextField
                                                                disabled={jobPostFormMode === "Preview"}
                                                                name='job_position'
                                                                label="Job Position"
                                                                size='small'
                                                                onChange={handleChange}
                                                                onKeyPress={(e) => e.target.value?.length > 100 && e.preventDefault()}
                                                                onPaste={(e) => e.target.value?.length > 100 && e.preventDefault()}
                                                                fullWidth
                                                                variant="outlined"
                                                                value={jobPostData.job_position}
                                                                {...(errors.job_position && {
                                                                    error: true,
                                                                    helperText: errors.job_position,
                                                                })}
                                                            />
                                                        </Grid> */}
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <SelectableSearch
                                      isOptionEqualToValue={(option, value) =>
                                        +option.recruiter_id ===
                                        +value.recruiter_id
                                      }
                                      multiple={true}
                                      disabled={jobPostFormMode === "Preview"}
                                      label="Secondary Recruiter"
                                      value={
                                        jobPostData?.secondary_recruiter_id
                                      }
                                      loading={() =>
                                        handleSecondaryRecruiterLoading()
                                      }
                                      fieldLabel="recruiter"
                                      noOptionText="Please select client to choose recruiter"
                                      variant={"contained"}
                                      name="secondary_recruiter_id"
                                      size="small"
                                      data={secondaryRecruiterData}
                                      onChangeEvent={handleChange}
                                      {...(errors.secondary_recruiter_id && {
                                        error: true,
                                        helperText:
                                          errors.secondary_recruiter_id,
                                      })}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": { mr: 2, flexShrink: 0 },
                                          }}
                                          {...props}
                                        >
                                          {option?.recruiter} -{" "}
                                          {option?.comcons_name
                                            ? option?.comcons_name
                                            : option?.user_type}
                                        </Box>
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <CreatableSearch
                                      isOptionEqualToValue={(option, value) =>
                                        option.skill === value.skill
                                      }
                                      disabled={jobPostFormMode === "Preview"}
                                      label="Secondary Skills Required"
                                      value={jobPostData?.secondary_skill}
                                      fieldLabel="skill"
                                      noOptionText="Please select Industry Sector to choose skills"
                                      optionLabel="skill"
                                      multiple={true}
                                      loading={() =>
                                        jobPostData?.industry_type
                                          ?.industry_type_id
                                          ? handleSkillsLoading()
                                          : setSkills({
                                              load: false,
                                              values: [],
                                            })
                                      }
                                      variant={"contained"}
                                      name="secondary_skill"
                                      size="small"
                                      data={skills}
                                      onChangeEvent={handleChange}
                                      {...(errors.secondary_skill && {
                                        error: true,
                                        helperText: errors.secondary_skill,
                                      })}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <TextField
                                      disabled={jobPostFormMode === "Preview"}
                                      name="preferred_qualification"
                                      label="Preferred Qualification"
                                      size="small"
                                      onKeyPress={(e) =>
                                        e.target.value?.length > 254 &&
                                        e.preventDefault()
                                      }
                                      onPaste={(e) =>
                                        e.target.value?.length > 254 &&
                                        e.preventDefault()
                                      }
                                      onChange={handleChange}
                                      fullWidth
                                      variant="outlined"
                                      value={
                                        jobPostData.preferred_qualification
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <TextField
                                      disabled={jobPostFormMode === "Preview"}
                                      name="min_exp_required"
                                      label="Minimum Experience"
                                      size="small"
                                      type="number"
                                      onKeyPress={(e) =>
                                        e.target.value?.length > 2 &&
                                        e.preventDefault()
                                      }
                                      onPaste={(e) =>
                                        e.target.value?.length > 2 &&
                                        e.preventDefault()
                                      }
                                      onChange={handleChange}
                                      fullWidth
                                      variant="outlined"
                                      value={jobPostData.min_exp_required}
                                      {...(errors.min_exp_required && {
                                        error: true,
                                        helperText: errors.min_exp_required,
                                      })}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <TextField
                                      disabled={jobPostFormMode === "Preview"}
                                      name="max_exp_required"
                                      label="Maximum Experience"
                                      size="small"
                                      type="number"
                                      onChange={handleChange}
                                      onKeyPress={(e) =>
                                        e.target.value?.length > 2 &&
                                        e.preventDefault()
                                      }
                                      onPaste={(e) =>
                                        e.target.value?.length > 2 &&
                                        e.preventDefault()
                                      }
                                      fullWidth
                                      variant="outlined"
                                      value={jobPostData.max_exp_required}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <TextField
                                      disabled={jobPostFormMode === "Preview"}
                                      name="min_salary"
                                      label="Minimum Salary"
                                      size="small"
                                      type="number"
                                      onChange={handleChange}
                                      fullWidth
                                      variant="outlined"
                                      onKeyPress={(e) =>
                                        e.target.value?.length > 10 &&
                                        e.preventDefault()
                                      }
                                      onPaste={(e) =>
                                        e.target.value?.length > 10 &&
                                        e.preventDefault()
                                      }
                                      value={jobPostData.min_salary}
                                      {...(errors.min_salary && {
                                        error: true,
                                        helperText: errors.min_salary,
                                      })}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <TextField
                                      disabled={jobPostFormMode === "Preview"}
                                      name="max_salary"
                                      label="Maximum Salary"
                                      size="small"
                                      type="number"
                                      onKeyPress={(e) =>
                                        e.target.value?.length > 10 &&
                                        e.preventDefault()
                                      }
                                      onPaste={(e) =>
                                        e.target.value?.length > 10 &&
                                        e.preventDefault()
                                      }
                                      onChange={handleChange}
                                      fullWidth
                                      variant="outlined"
                                      value={jobPostData.max_salary}
                                    />
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <FormControl
                                      fullWidth
                                      error={errors?.job_type}
                                      size="small"
                                      disabled={jobPostFormMode === "Preview"}
                                    >
                                      <InputLabel id="recruiter-type-select-label">
                                        Job Type
                                      </InputLabel>
                                      <Select
                                        name="job_type"
                                        labelId="recruiter-type-select-label"
                                        id="recruiter-type-select"
                                        value={jobPostData?.job_type}
                                        label="Job Type"
                                        onChange={handleChange}
                                      >
                                        <MenuItem value={"Full Time"}>
                                          Full Time
                                        </MenuItem>
                                        <MenuItem value={"Part Time"}>
                                          Part Time
                                        </MenuItem>
                                      </Select>
                                      <FormHelperText>
                                        {errors?.job_type}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <FormControl
                                      fullWidth
                                      error={errors?.priority ? true : false}
                                      size="small"
                                      disabled={jobPostFormMode === "Preview"}
                                    >
                                      <InputLabel id="recruiter-type-select-label">
                                        Severity
                                      </InputLabel>
                                      <Select
                                        name="priority"
                                        labelId="recruiter-type-select-label"
                                        id="recruiter-type-select"
                                        value={jobPostData?.priority}
                                        label="Severity"
                                        onChange={handleChange}
                                      >
                                        <MenuItem value={"Low"}>Low</MenuItem>
                                        <MenuItem value={"Medium"}>
                                          Medium
                                        </MenuItem>
                                        <MenuItem value={"High"}>High</MenuItem>
                                      </Select>
                                      <FormHelperText>
                                        {errors?.priority}
                                      </FormHelperText>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <FormControl
                                      disabled={jobPostFormMode === "Preview"}
                                    >
                                      <FormLabel id="demo-row-radio-buttons-group-label">
                                        Mode of Working
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="remote_job"
                                        onChange={handleChange}
                                        value={jobPostData?.remote_job}
                                      >
                                        <FormControlLabel
                                          value="On Premise"
                                          control={<Radio />}
                                          label="On Premise"
                                        />
                                        <FormControlLabel
                                          value="Remote"
                                          control={<Radio />}
                                          label="Remote"
                                        />
                                        <FormControlLabel
                                          value="Hybrid"
                                          control={<Radio />}
                                          label="Hybrid"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <FormControl
                                      disabled={jobPostFormMode === "Preview"}
                                    >
                                      <FormLabel id="demo-row-radio-buttons-group-label">
                                        Preferred Gender
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="preferred_gender"
                                        onChange={handleChange}
                                        value={jobPostData?.preferred_gender}
                                      >
                                        <FormControlLabel
                                          value="All"
                                          control={<Radio />}
                                          label="Any"
                                        />
                                        <FormControlLabel
                                          value="Male"
                                          control={<Radio />}
                                          label="Male"
                                        />
                                        <FormControlLabel
                                          value="Female"
                                          control={<Radio />}
                                          label="Female"
                                        />
                                        <FormControlLabel
                                          value="Transgender"
                                          control={<Radio />}
                                          label="Transgender"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                    <FormControl
                                      disabled={jobPostFormMode === "Preview"}
                                    >
                                      <FormLabel id="demo-row-radio-buttons-group-label">
                                        Do you wish to make this job public?
                                      </FormLabel>
                                      <RadioGroup
                                        row
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="make_public"
                                        onChange={handleChange}
                                        value={jobPostData?.make_public}
                                      >
                                        <FormControlLabel
                                          value={true}
                                          control={<Radio />}
                                          label="Yes"
                                        />
                                        <FormControlLabel
                                          value={false}
                                          control={<Radio />}
                                          label="No"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Grid>
                                  {jobPostFormMode !== "Preview" ||
                                  jobPostData.roles_responsibilities ||
                                  jobPostData.description ? (
                                    <Grid
                                      item
                                      xs={12}
                                      md={12}
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "space-between",
                                        p: 2,
                                        minHeight: "300px",
                                      }}
                                    >
                                      {jobPostFormMode !== "Preview" && (
                                        <>
                                          <Grid item xs={6} md={6}>
                                            <Typography>
                                              Job Description
                                            </Typography>
                                            <Quill
                                              name="description"
                                              value={description}
                                              setValue={setDescription}
                                              error={Boolean(
                                                errors.description
                                              )}
                                              style={{ height: "200px" }}
                                            />
                                            {errors?.description && (
                                              <Typography
                                                sx={{
                                                  color: "red",
                                                  paddingLeft: "20px",
                                                  paddingTop: 6,
                                                }}
                                              >
                                                {errors?.description}
                                              </Typography>
                                            )}
                                          </Grid>
                                          <Grid
                                            item
                                            xs={6}
                                            md={6}
                                            marginLeft={2}
                                          >
                                            <Typography>
                                              Roles and Responsibilities
                                            </Typography>
                                            <Quill
                                              name="roles_responsibilities"
                                              value={rolesresponsibilities}
                                              setValue={
                                                setRolesResponsibilities
                                              }
                                              style={{ height: "200px" }}
                                            />
                                            {errors?.roles_responsibilities && (
                                              <Typography
                                                sx={{
                                                  color: "red",
                                                  paddingLeft: "20px",
                                                  paddingTop: 6,
                                                }}
                                              >
                                                {errors?.roles_responsibilities}
                                              </Typography>
                                            )}
                                          </Grid>
                                        </>
                                      )}
                                      {jobPostFormMode === "Preview" && (
                                        <>
                                          {jobPostData.description && (
                                            <Grid
                                              item
                                              xs={6}
                                              md={6}
                                              marginLeft={2}
                                            >
                                              <Typography>
                                                Job Description
                                              </Typography>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    jobPostData.description,
                                                }}
                                              ></div>
                                            </Grid>
                                          )}
                                          {jobPostData.roles_responsibilities && (
                                            <Grid item xs={6} md={6}>
                                              <Typography>
                                                Roles and Responsibilities
                                              </Typography>
                                              <div
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    jobPostData.roles_responsibilities,
                                                }}
                                              ></div>
                                            </Grid>
                                          )}
                                        </>
                                      )}
                                    </Grid>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                  </Paper>
                  {jobPostFormMode === "Preview" && isLoading ? (
                    <>
                      <Grid container direction="row" alignItems="center">
                        {Array(8)
                          .fill()
                          .map((data, index) => (
                            <Grid
                              key={index}
                              item
                              p={1.5}
                              lg={6}
                              md={6}
                              sm={6}
                              xs={12}
                            >
                              <Card>
                                <CardHeader
                                  action={null}
                                  title={
                                    <Skeleton
                                      animation="wave"
                                      height={10}
                                      width="80%"
                                      style={{ marginBottom: 6 }}
                                    />
                                  }
                                  subheader={
                                    <Skeleton
                                      animation="wave"
                                      height={10}
                                      width="40%"
                                      style={{ marginBottom: 6 }}
                                    />
                                  }
                                />
                                <Skeleton
                                  sx={{ height: 130 }}
                                  animation="wave"
                                  variant="rectangular"
                                />
                                <CardContent>
                                  <>
                                    <Skeleton
                                      animation="wave"
                                      height={10}
                                      style={{ marginBottom: 6 }}
                                    />
                                    <Skeleton
                                      animation="wave"
                                      height={10}
                                      width="80%"
                                    />
                                  </>
                                </CardContent>
                              </Card>
                            </Grid>
                          ))}
                      </Grid>
                    </>
                  ) : (
                    <>
                      {jobPostFormMode === "Preview" && (
                        <Box
                          sx={{
                            p: 2,
                            mt: 4,
                            backgroundColor: "white",
                            borderRadius: "10px",
                          }}
                        >
                          <Typography variant="h5" sx={{ ml: 1, pb: 1 }}>
                            Suggested Candidates
                          </Typography>
                          {showCandidate > 0 && (
                            <Typography
                              sx={{ mb: 2 }}
                              display="flex"
                              justifyContent="center"
                            >
                              {" "}
                              Candidates Not Available{" "}
                            </Typography>
                          )}
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            spacing={1}
                          >
                            {getsuggestions?.map((su, index) => (
                              <Grid
                                sx={{ padding: "10px" }}
                                key={index}
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                              >
                                <Card sx={{ padding: "14px" }}>
                                  <Box sx={{ paddingRight: "8px" }}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        WebkitBoxPack: "justify",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                        width: "100%",
                                      }}
                                    >
                                      <Tooltip
                                        placement="bottom-start"
                                        title="Name"
                                      >
                                        <Typography
                                          sx={{ mb: 0 }}
                                          variant="subtitle2"
                                          gutterBottom
                                        >
                                          {su?.first_name} {su?.last_name || ""}
                                        </Typography>
                                      </Tooltip>
                                      <IconButton
                                        onClick={() => proposeCandidate(su)}
                                      >
                                        <Tooltip title="Propose">
                                          <PersonAddAlt1Icon
                                            color="success"
                                            fontSize="small"
                                          />
                                        </Tooltip>
                                      </IconButton>
                                    </Box>
                                    <Divider sx={{ mb: 2 }} variant="middle" />
                                    <Grid
                                      sx={{ cursor: "Pointer" }}
                                      onClick={() => handleClickPreview(su)}
                                      container
                                      direction="row"
                                      justifyContent="flex-start"
                                      alignItems="center"
                                      spacing={1}
                                    >
                                      <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Tooltip
                                          placement="bottom-start"
                                          title="Email"
                                        >
                                          <span style={{ display: "flex" }}>
                                            <EmailIcon fontSize="small" />
                                            <Typography
                                              pl={1}
                                              variant="body2"
                                              gutterBottom
                                            >
                                              {su?.email_id}
                                            </Typography>
                                          </span>
                                        </Tooltip>
                                        <Tooltip
                                          placement="bottom-start"
                                          title="Location"
                                        >
                                          <span style={{ display: "flex" }}>
                                            <LocationOnRoundedIcon fontSize="small" />
                                            <Typography pl={1} variant="body2">
                                              {su?.location || "-"}
                                            </Typography>
                                          </span>
                                        </Tooltip>
                                      </Grid>
                                      <Grid item xs={12} sm={6} md={6} lg={6}>
                                        <Tooltip
                                          placement="bottom-start"
                                          title="Primary Skills"
                                        >
                                          <span style={{ display: "flex" }}>
                                            <PsychologyIcon fontSize="small" />
                                            <Typography
                                              pl={1}
                                              variant="body2"
                                              gutterBottom
                                            >
                                              {su?.primary_skill}
                                            </Typography>
                                          </span>
                                        </Tooltip>
                                        <Tooltip
                                          placement="bottom-start"
                                          title="Secondary Skills"
                                        >
                                          <span style={{ display: "flex" }}>
                                            <PsychologyIcon fontSize="small" />
                                            <Typography pl={1} variant="body2">
                                              {su?.secondary_skill || "-"}
                                            </Typography>
                                          </span>
                                        </Tooltip>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                      >
                                        <LinearProgress
                                          className="progress-value"
                                          variant="determinate"
                                          value={
                                            su?.profile_score
                                              ? su?.profile_score
                                              : 0
                                          }
                                        />
                                        <span
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <Typography pl={1} variant="body2">
                                            {su?.profile_score
                                              ? `${su?.profile_score}%`
                                              : "0%"}
                                          </Typography>
                                        </span>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Card>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      )}
                      {getsuggestions?.[0]?.total_count > 12 && (
                        <Pagination
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            mb: 2,
                          }}
                          showFirstButton
                          showLastButton
                          count={
                            Number.isInteger(
                              getsuggestions?.[0]?.total_count / 12
                            )
                              ? getsuggestions?.[0]?.total_count / 12
                              : Math.round(
                                  getsuggestions?.[0]?.total_count / 12 + 0.5
                                ) || 0
                          }
                          page={page}
                          color="secondary"
                          onChange={(event, value) =>
                            handlePagination(event, value)
                          }
                        />
                      )}
                    </>
                  )}
                </Box>
              </>
            )}
            {
              <AtsJobTemplatePreview
                anchor={anchor}
                toggleDrawer={toggleDrawer}
                drawerdata={drawerdata}
                handleApplyTemplate={handleApplyTemplate}
                jobPostData={jobPostData}
                setPostJobData={setPostJobData}
              />
            }
            
            {jobPostFormMode === "Preview" && (
              <ConfirmationDialog
                title="Propose Candidate"
                content={`Do you wish to Propose ${data?.first_name} to ${editedRecord.title}?`}
                anchorE={anchorE}
                openDialog={openDialog}
                closeDialog={setOpenDialog}
                popupTitle="Job"
                OkButtonText="Yes Propose"
                onSubmit={handleConfirmPropose}
              />
            )}
            <Popup
              title={"Feed Back Template"}
              openPopup={formBuilder}
              setOpenPopup={setFormBuilder}
              maxWidth="md"
              fullWidth={true}
            >
              <FormBuilder
                formBuilder={formBuilder}
                setFormBuilder={setFormBuilder}
                JD={true}
                title={builderTitle}
              />
            </Popup>

            <Popup
              title={"Pre Screen Question Template"}
              openPopup={preformBuilder}
              setOpenPopup={setPreFormBuilder}
              maxWidth="md"
              fullWidth={true}
            >
              <PreScreen
                preformBuilder={preformBuilder}
                setPreFormBuilder={setPreFormBuilder}
                JD={true}
                title={prebuilderTitle}
              />
            </Popup>
          </>{" "}
        </Base>
      ) : (
        <>
          {candidatePreview === true ? (
            <AtsCandidateForm
              setCandidatePreview={setCandidatePreview}
              currentData={currentData}
              isProfile={true}
              editedRecord={editedRecord}
            />
          ) : (
            <>
              <Box sx={{ maxHeight: "90vh", overflowY: "auto", pb: 2 }}>
                <Box
                  sx={{
                    position: "sticky",
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "#ffffff",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    p: 2,
                  }}
                >
                  <ArrowBackButton onClick={handleClose} />
                  <Typography
                    sx={{
                      fontFamily: "Nunito Sans",
                      fontWeight: 700,
                      color: "#000000",
                      fontSize: "large",
                    }}
                  >
                    {jobPostFormMode === "Preview"
                      ? "Preview Job"
                      : jobPostFormMode === "Edit"
                      ? "Update Job"
                      : "Create New Job"}
                  </Typography>
                  {jobPostFormMode === "Preview" && (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      {canEdit && (
                        <Button
                          variant="contained"
                          className="mpl-primary-btn"
                          onClick={handleEdit}
                        >
                          Edit
                        </Button>
                      )}
                    </Box>
                  )}

                  {jobPostFormMode !== "Preview" && (
                    <Button
                      sx={{ ml: 1 }}
                      variant="contained"
                      className="mpl-primary-btn"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      {jobPostData?.ats_jobpost_id ? "Update" : "Save"}
                    </Button>
                  )}
                </Box>
                <Paper sx={{ borderRadius: "10px", p: 1.5, overflowY: "auto" }}>
                  {jobPostFormMode !== "Preview" && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {["Admin", "Owner", "Internal"].includes(userData?.user_type) && (
                            <>
                              <div style={{ display: "flex" }}>
                                {userData?.user_type !== "Internal" && (
                                  <>
                                    <div style={{ display: "flex", marginRight: "8px" }}>
                                      <Typography>
                                        *To review your industry sectors/ job titles/ skills{" "}
                                        <span
                                          style={{ color: "#1155cc", cursor: "pointer", textDecoration: "underline" }}
                                          onClick={handleATSSettings}
                                        >
                                          Click Here
                                        </span>
                                      </Typography>
                                    </div>
                                    <div style={{ display: "flex", marginRight: "8px" }}>
                                      <Typography>
                                        *To add Hiring Partners{" "}
                                        <span
                                          style={{ color: "#1155cc", cursor: "pointer", textDecoration: "underline" }}
                                          onClick={handleAddHiringPartner}
                                        >
                                          Click Here
                                        </span>
                                      </Typography>
                                    </div>
                                    {toggle === false && (
                                      <>
                                        {isGroupOrgFlowTrue ? (
                                          <div style={{ display: "flex" }}>
                                            <Typography>
                                              *To add a Group Org{" "}
                                              <span
                                                style={{ color: "#1155cc", cursor: "pointer", textDecoration: "underline" }}
                                                onClick={handleAddGrp}
                                              >
                                                Click Here
                                              </span>
                                            </Typography>
                                          </div>
                                        ) : (
                                          <div style={{ display: "flex" }}>
                                            <Typography>
                                              *To add a Client{" "}
                                              <span
                                                style={{ color: "#1155cc", cursor: "pointer", textDecoration: "underline" }}
                                                onClick={handleAddClient}
                                              >
                                                Click Here
                                              </span>
                                            </Typography>
                                          </div>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </div>

                              <div>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Switch
                                        onChange={handleToggle}
                                        checked={toggle}
                                        disabled={["Edit", "Preview"].includes(jobPostFormMode)}
                                      />
                                    }
                                    label="is internal job"
                                  />
                                </FormGroup>
                              </div>
                            </>
                          )}
                      </div>
                    </>
                  )}
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <Card>
                          <CardContent>
                            <Grid container sx={{ mt: 1 }}>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                {toggle === false ? (
                                 <>
                                 {isGroupOrgFlowTrue ? (
                                  
                               <SelectableSearch
                                   isOptionEqualToValue={(option, value) =>
                                     +option.ats_grouporg_id ===
                                     +value.ats_grouporg_id
                                   }
                                   disabled={jobPostFormMode === "Preview"}
                                   label="Group Org"
                                   value={jobPostData?.ats_grouporg_id}
                                   loading={() =>
                                     setAtsGroupUser({
                                       load: false,
                                       values: atsGroupCompanyData,
                                     })
                                   }
                                   fieldLabel="name"
                                   required
                                   variant={"contained"}
                                   name="ats_grouporg_id"
                                   size="small"
                                   data={atsgroupUser}
                                   onChangeEvent={handleChange}
                                   {...(errors.ats_grouporg_id && {
                                     error: true,
                                     helperText: errors.ats_grouporg_id,
                                   })}
                                 />
                                 ) : (
                                   <SelectableSearch
                                   isOptionEqualToValue={(option, value) =>
                                     +option.ats_company_id ===
                                     +value.ats_company_id
                                   }
                                   disabled={jobPostFormMode === "Preview"}
                                   label="Client"
                                   value={jobPostData?.ats_company_id}
                                   loading={() =>
                                     setCompanyData({
                                       load: false,
                                       values: atsCompanyData,
                                     })
                                   }
                                   fieldLabel="company"
                                   required
                                   variant={"contained"}
                                   name="ats_company_id"
                                   size="small"
                                   data={companyData}
                                   onChangeEvent={handleChange}
                                   {...(errors.ats_company_id && {
                                     error: true,
                                     helperText: errors.ats_company_id,
                                   })}
                                 />
                                 )}
                                 </>
                                ) : (
                                  <>
                                  {isGroupOrgFlowTrue ? (
                                    userData?.user_type === "Group User" ? (
                                      <TextField
                                        disabled={true}
                                        name="ats_grouporg_id"
                                        label="Group Org"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        value={userData?.ats_grouporg_name}
                                      />
                                    ) : (
                                      <TextField
                                        disabled={true}
                                        name="ats_grouporg_id"
                                        label="Group Org"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        value={
                                          ["", null, false, "Internal"].includes(jobPostData?.ats_grouporg_id)
                                            ? "Internal"
                                            : ""
                                        }
                                      />
                                    )
                                  ) : (
                                    <TextField
                                      disabled={true}
                                      name="ats_company_id"
                                      label="Client"
                                      size="small"
                                      fullWidth
                                      variant="outlined"
                                      value={
                                        ["", null, false, "Internal"].includes(jobPostData?.ats_company_id)
                                          ? "Internal"
                                          : ""
                                      }
                                    />
                                  )}
                                </>

                                )}
                              </Grid>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                {userData?.user_type === "Consultancy" ? (
                                  <TextField
                                    disabled={true}
                                    name="consultancy_id"
                                    label="Hiring Partner"
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={atsConsultancyData?.[0]?.consultancy}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                  />
                                ) : (
                                  <SelectableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      +option.consultancy_id ===
                                      +value.consultancy_id
                                    }
                                    disabled={jobPostFormMode === "Preview"}
                                    label="Hiring Partner"
                                    value={jobPostData?.consultancy_id}
                                    loading={() =>
                                      setConsultancyData({
                                        load: false,
                                        values: atsConsultancyData,
                                      })
                                    }
                                    fieldLabel="consultancy"
                                    required={false}
                                    multiple={true}
                                    variant={"contained"}
                                    name="consultancy_id"
                                    size="small"
                                    data={consultancyData}
                                    onChangeEvent={handleChange}
                                    {...(errors.consultancy_id && {
                                      error: true,
                                      helperText: errors.consultancy_id,
                                    })}
                                  />
                                )}
                              </Grid>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <SelectableSearch
                                  isOptionEqualToValue={(option, value) =>
                                    +option.recruiter_id === +value.recruiter_id
                                  }
                                  disabled={jobPostFormMode === "Preview"}
                                  required={true}
                                  multiple={true}
                                  label="Primary Recruiter"
                                  noOptionText="Please select client to choose recruiter"
                                  value={jobPostData?.primary_recruiter_id}
                                  loading={() =>
                                    handlePrimaryRecruiterLoading()
                                  }
                                  fieldLabel="recruiter"
                                  variant={"contained"}
                                  name="primary_recruiter_id"
                                  size="small"
                                  data={primaryRecruiterData}
                                  onChangeEvent={handleChange}
                                  {...(errors.primary_recruiter_id && {
                                    error: true,
                                    helperText: errors.primary_recruiter_id,
                                  })}
                                  renderOption={(props, option) => (
                                    <Box
                                      component="li"
                                      sx={{
                                        "& > img": { mr: 2, flexShrink: 0 },
                                      }}
                                      {...props}
                                    >
                                      {option?.recruiter} -{" "}
                                      {option?.comcons_name
                                        ? option?.comcons_name
                                        : option?.user_type}
                                    </Box>
                                  )}
                                />
                              </Grid>

                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <CreatableSearch
                                  isOptionEqualToValue={(option, value) =>
                                    +option.industry_name ===
                                    +value.industry_name
                                  }
                                  disabled={jobPostFormMode === "Preview"}
                                  label="Industry Sector"
                                  value={jobPostData?.industry_type}
                                  fieldLabel="industry_name"
                                  loading={() => handleIndustryTypeLoading()}
                                  required={true}
                                  variant={"contained"}
                                  noOptionText="Start typing to add or select industry"
                                  name="industry_type"
                                  size="small"
                                  data={industrytype}
                                  onChangeEvent={handleChange}
                                  {...(errors.industry_type && {
                                    error: true,
                                    helperText: errors.industry_type,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <CreatableSearch
                                  isOptionEqualToValue={(option, value) =>
                                    +option.job_template_id ===
                                    +value.job_template_id
                                  }
                                  disabled={jobPostFormMode === "Preview"}
                                  label="Job Title"
                                  value={jobPostData?.title}
                                  noOptionText="Please select Industry Sector to choose title"
                                  fieldLabel="job_title"
                                  loading={() => handleJobTemplateLoading()}
                                  required={true}
                                  variant={"contained"}
                                  name="title"
                                  size="small"
                                  data={jobTitle}
                                  onChangeEvent={handleChange}
                                  {...(errors.title && {
                                    error: true,
                                    helperText: errors.title,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <CreatableSearch
                                  isOptionEqualToValue={(option, value) =>
                                    option.skill === value.skill
                                  }
                                  disabled={jobPostFormMode === "Preview"}
                                  label="Primary Skills Required"
                                  value={jobPostData?.primary_skill}
                                  fieldLabel="skill"
                                  loading={() =>
                                    jobPostData?.industry_type?.industry_type_id
                                      ? handleSkillsLoading()
                                      : setSkills({ load: false, values: [] })
                                  }
                                  required={true}
                                  noOptionText="Please select Industry Sector to choose skills"
                                  variant={"contained"}
                                  name="primary_skill"
                                  size="small"
                                  optionLabel="skill"
                                  multiple={true}
                                  data={skills}
                                  onChangeEvent={handleChange}
                                  {...(errors.primary_skill && {
                                    error: true,
                                    helperText: errors.primary_skill,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <TextField
                                  disabled={jobPostFormMode === "Preview"}
                                  name="vaccancies"
                                  label="Vacancies"
                                  size="small"
                                  type="number"
                                  onKeyPress={(e) =>
                                    e.target.value?.length > 4 &&
                                    e.preventDefault()
                                  }
                                  onPaste={(e) =>
                                    e.target.value?.length > 4 &&
                                    e.preventDefault()
                                  }
                                  required={true}
                                  onChange={handleChange}
                                  fullWidth
                                  variant="outlined"
                                  value={jobPostData.vaccancies}
                                  {...(errors.vaccancies && {
                                    error: true,
                                    helperText: errors.vaccancies,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <TextField
                                  disabled={jobPostFormMode === "Preview"}
                                  name="job_location"
                                  label="Job Location"
                                  size="small"
                                  required={true}
                                  onChange={handleChange}
                                  onKeyPress={(e) =>
                                    e.target.value?.length > 100 &&
                                    e.preventDefault()
                                  }
                                  onPaste={(e) =>
                                    e.target.value?.length > 100 &&
                                    e.preventDefault()
                                  }
                                  fullWidth
                                  variant="outlined"
                                  value={jobPostData.job_location}
                                  {...(errors?.job_location && {
                                    error: true,
                                    helperText: errors?.job_location,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <CreatableSearch
                                  isOptionEqualToValue={(option, value) =>
                                    +option.template_id === +value.template_id
                                  }
                                  disabled={jobPostFormMode === "Preview"}
                                  label="Feedback Template"
                                  name="feedback_template_id"
                                  value={jobPostData?.feedback_template_id}
                                  loading={handleFeedBackTemplateLoading}
                                  fieldLabel="template_name"
                                  variant={"contained"}
                                  size="small"
                                  data={feedbackTemplate}
                                  onChangeEvent={handleChange}
                                  {...(errors.feedback_template_id && {
                                    error: true,
                                    helperText: errors.feedback_template_id,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                <CreatableSearch
                                  isOptionEqualToValue={(option, value) =>
                                    +option.prescreen_template_id ===
                                    +value.prescreen_template_id
                                  }
                                  disabled={jobPostFormMode === "Preview"}
                                  label="Pre Screen Questions Template"
                                  name="prescreen_template_id"
                                  value={jobPostData?.prescreen_template_id}
                                  loading={() =>
                                    handlepreScreenTemplateLoading()
                                  }
                                  fieldLabel="prescreen_template_name"
                                  variant={"contained"}
                                  size="small"
                                  data={prescreenTemplate}
                                  onChangeEvent={handleChange}
                                  {...(errors.prescreen_template_id && {
                                    error: true,
                                    helperText: errors.prescreen_template_id,
                                  })}
                                />
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                md={4}
                                sx={{
                                  p: 1,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <FormControl
                                  disabled={
                                    jobPostFormMode === "Preview" || offerstatus }
                                >
                                  <FormLabel
                                    id="demo-row-radio-buttons-group-label"
                                    sx={{ mr: 2 }}
                                  >
                                    Include offer letter work flow?
                                  </FormLabel>
                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="offer_letter"
                                    onChange={handleChange}
                                    value={jobPostData?.offer_letter}
                                  >
                                    <FormControlLabel
                                      value={true}
                                      control={<Radio />}
                                      label="Yes"
                                    />
                                    <FormControlLabel
                                      value={false}
                                      control={<Radio />}
                                      label="No"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              {jobPostData?.offer_letter && (
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <CreatableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      +option.offer_letter_template_id ===
                                      +value.offer_letter_template_id
                                    }
                                    disabled={jobPostFormMode === "Preview" || offerstatus}
                                    label="Offer Letter Template"
                                    name="offer_letter_template_id"
                                    value={
                                      jobPostData?.offer_letter_template_id
                                    }
                                    loading={() => handleOfferTemplateLoading()}
                                    fieldLabel="offer_letter_template_name"
                                    variant={"contained"}
                                    size="small"
                                    data={offerTemplate}
                                    onChangeEvent={handleChange}
                                    {...(errors.offer_letter_template_id && {
                                      error: true,
                                      helperText:
                                        errors.offer_letter_template_id,
                                    })}
                                  />
                                </Grid>
                              )}
                              <Grid item xs={12} md={12} sx={{ p: 1 }}>
                                <Divider
                                  style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  }}
                                />
                              </Grid>
                              <Grid container sx={{ mt: 1 }}>
                                {/* <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                                            <TextField
                                                                disabled={jobPostFormMode === "Preview"}
                                                                name='job_position'
                                                                label="Job Position"
                                                                size='small'
                                                                onChange={handleChange}
                                                                onKeyPress={(e) => e.target.value?.length > 100 && e.preventDefault()}
                                                                onPaste={(e) => e.target.value?.length > 100 && e.preventDefault()}
                                                                fullWidth
                                                                variant="outlined"
                                                                value={jobPostData.job_position}
                                                                {...(errors.job_position && {
                                                                    error: true,
                                                                    helperText: errors.job_position,
                                                                })}
                                                            />
                                                        </Grid> */}
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <SelectableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      +option.recruiter_id ===
                                      +value.recruiter_id
                                    }
                                    multiple={true}
                                    disabled={jobPostFormMode === "Preview"}
                                    label="Secondary Recruiter"
                                    value={jobPostData?.secondary_recruiter_id}
                                    loading={() =>
                                      handleSecondaryRecruiterLoading()
                                    }
                                    fieldLabel="recruiter"
                                    noOptionText="Please select client to choose recruiter"
                                    variant={"contained"}
                                    name="secondary_recruiter_id"
                                    size="small"
                                    data={secondaryRecruiterData}
                                    onChangeEvent={handleChange}
                                    {...(errors.secondary_recruiter_id && {
                                      error: true,
                                      helperText: errors.secondary_recruiter_id,
                                    })}
                                    renderOption={(props, option) => (
                                      <Box
                                        component="li"
                                        sx={{
                                          "& > img": { mr: 2, flexShrink: 0 },
                                        }}
                                        {...props}
                                      >
                                        {option?.recruiter} -{" "}
                                        {option?.comcons_name
                                          ? option?.comcons_name
                                          : option?.user_type}
                                      </Box>
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <CreatableSearch
                                    isOptionEqualToValue={(option, value) =>
                                      option.skill === value.skill
                                    }
                                    disabled={jobPostFormMode === "Preview"}
                                    label="Secondary Skills Required"
                                    value={jobPostData?.secondary_skill}
                                    fieldLabel="skill"
                                    noOptionText="Please select Industry Sector to choose skills"
                                    optionLabel="skill"
                                    multiple={true}
                                    loading={() =>
                                      jobPostData?.industry_type
                                        ?.industry_type_id
                                        ? handleSkillsLoading()
                                        : setSkills({ load: false, values: [] })
                                    }
                                    variant={"contained"}
                                    name="secondary_skill"
                                    size="small"
                                    data={skills}
                                    onChangeEvent={handleChange}
                                    {...(errors.secondary_skill && {
                                      error: true,
                                      helperText: errors.secondary_skill,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <TextField
                                    disabled={jobPostFormMode === "Preview"}
                                    name="preferred_qualification"
                                    label="Preferred Qualification"
                                    size="small"
                                    onKeyPress={(e) =>
                                      e.target.value?.length > 254 &&
                                      e.preventDefault()
                                    }
                                    onPaste={(e) =>
                                      e.target.value?.length > 254 &&
                                      e.preventDefault()
                                    }
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    value={jobPostData.preferred_qualification}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <TextField
                                    disabled={jobPostFormMode === "Preview"}
                                    name="min_exp_required"
                                    label="Minimum Experience"
                                    size="small"
                                    type="number"
                                    onKeyPress={(e) =>
                                      e.target.value?.length > 2 &&
                                      e.preventDefault()
                                    }
                                    onPaste={(e) =>
                                      e.target.value?.length > 2 &&
                                      e.preventDefault()
                                    }
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    value={jobPostData.min_exp_required}
                                    {...(errors.min_exp_required && {
                                      error: true,
                                      helperText: errors.min_exp_required,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <TextField
                                    disabled={jobPostFormMode === "Preview"}
                                    name="max_exp_required"
                                    label="Maximum Experience"
                                    size="small"
                                    type="number"
                                    onChange={handleChange}
                                    onKeyPress={(e) =>
                                      e.target.value?.length > 2 &&
                                      e.preventDefault()
                                    }
                                    onPaste={(e) =>
                                      e.target.value?.length > 2 &&
                                      e.preventDefault()
                                    }
                                    fullWidth
                                    variant="outlined"
                                    value={jobPostData.max_exp_required}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <TextField
                                    disabled={jobPostFormMode === "Preview"}
                                    name="min_salary"
                                    label="Minimum Salary"
                                    size="small"
                                    type="number"
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    onKeyPress={(e) =>
                                      e.target.value?.length > 10 &&
                                      e.preventDefault()
                                    }
                                    onPaste={(e) =>
                                      e.target.value?.length > 10 &&
                                      e.preventDefault()
                                    }
                                    value={jobPostData.min_salary}
                                    {...(errors.min_salary && {
                                      error: true,
                                      helperText: errors.min_salary,
                                    })}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <TextField
                                    disabled={jobPostFormMode === "Preview"}
                                    name="max_salary"
                                    label="Maximum Salary"
                                    size="small"
                                    type="number"
                                    onKeyPress={(e) =>
                                      e.target.value?.length > 10 &&
                                      e.preventDefault()
                                    }
                                    onPaste={(e) =>
                                      e.target.value?.length > 10 &&
                                      e.preventDefault()
                                    }
                                    onChange={handleChange}
                                    fullWidth
                                    variant="outlined"
                                    value={jobPostData.max_salary}
                                  />
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <FormControl
                                    fullWidth
                                    error={errors?.job_type}
                                    size="small"
                                    disabled={jobPostFormMode === "Preview"}
                                  >
                                    <InputLabel id="recruiter-type-select-label">
                                      Job Type
                                    </InputLabel>
                                    <Select
                                      name="job_type"
                                      labelId="recruiter-type-select-label"
                                      id="recruiter-type-select"
                                      value={jobPostData?.job_type}
                                      label="Job Type"
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={"Full Time"}>
                                        Full Time
                                      </MenuItem>
                                      <MenuItem value={"Part Time"}>
                                        Part Time
                                      </MenuItem>
                                    </Select>
                                    <FormHelperText>
                                      {errors?.job_type}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <FormControl
                                    fullWidth
                                    error={errors?.priority ? true : false}
                                    size="small"
                                    disabled={jobPostFormMode === "Preview"}
                                  >
                                    <InputLabel id="recruiter-type-select-label">
                                      Severity
                                    </InputLabel>
                                    <Select
                                      name="priority"
                                      labelId="recruiter-type-select-label"
                                      id="recruiter-type-select"
                                      value={jobPostData?.priority}
                                      label="Severity"
                                      onChange={handleChange}
                                    >
                                      <MenuItem value={"Low"}>Low</MenuItem>
                                      <MenuItem value={"Medium"}>
                                        Medium
                                      </MenuItem>
                                      <MenuItem value={"High"}>High</MenuItem>
                                    </Select>
                                    <FormHelperText>
                                      {errors?.priority}
                                    </FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <FormControl
                                    disabled={jobPostFormMode === "Preview"}
                                  >
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                      Mode of Working
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="remote_job"
                                      onChange={handleChange}
                                      value={jobPostData?.remote_job}
                                    >
                                      <FormControlLabel
                                        value="On Premise"
                                        control={<Radio />}
                                        label="On Premise"
                                      />
                                      <FormControlLabel
                                        value="Remote"
                                        control={<Radio />}
                                        label="Remote"
                                      />
                                      <FormControlLabel
                                        value="Hybrid"
                                        control={<Radio />}
                                        label="Hybrid"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <FormControl
                                    disabled={jobPostFormMode === "Preview"}
                                  >
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                      Preferred Gender
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="preferred_gender"
                                      onChange={handleChange}
                                      value={jobPostData?.preferred_gender}
                                    >
                                      <FormControlLabel
                                        value="All"
                                        control={<Radio />}
                                        label="Any"
                                      />
                                      <FormControlLabel
                                        value="Male"
                                        control={<Radio />}
                                        label="Male"
                                      />
                                      <FormControlLabel
                                        value="Female"
                                        control={<Radio />}
                                        label="Female"
                                      />
                                      <FormControlLabel
                                        value="Transgender"
                                        control={<Radio />}
                                        label="Transgender"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12} md={4} sx={{ p: 1 }}>
                                  <FormControl
                                    disabled={jobPostFormMode === "Preview"}
                                  >
                                    <FormLabel id="demo-row-radio-buttons-group-label">
                                      Do you wish to make this job public?
                                    </FormLabel>
                                    <RadioGroup
                                      row
                                      aria-labelledby="demo-row-radio-buttons-group-label"
                                      name="make_public"
                                      onChange={handleChange}
                                      value={jobPostData?.make_public}
                                    >
                                      <FormControlLabel
                                        value={true}
                                        control={<Radio />}
                                        label="Yes"
                                      />
                                      <FormControlLabel
                                        value={false}
                                        control={<Radio />}
                                        label="No"
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>

                                {jobPostFormMode !== "Preview" ||
                                jobPostData.roles_responsibilities ||
                                jobPostData.description ? (
                                  <Grid
                                    item
                                    xs={12}
                                    md={12}
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "space-between",
                                      p: 2,
                                      minHeight: "300px",
                                    }}
                                  >
                                    {jobPostFormMode !== "Preview" && (
                                      <>
                                        <Grid item xs={6} md={6}>
                                          <Typography>
                                            Job Description
                                          </Typography>
                                          <Quill
                                            name="description"
                                            value={description}
                                            setValue={setDescription}
                                            error={Boolean(errors.description)}
                                            style={{ height: "200px" }}
                                          />
                                          {errors?.description && (
                                            <Typography
                                              sx={{
                                                color: "red",
                                                paddingLeft: "20px",
                                                paddingTop: 6,
                                              }}
                                            >
                                              {errors?.description}
                                            </Typography>
                                          )}
                                        </Grid>
                                        <Grid item xs={6} md={6} marginLeft={2}>
                                          <Typography>
                                            Roles and Responsibilities
                                          </Typography>
                                          <Quill
                                            name="roles_responsibilities"
                                            value={rolesresponsibilities}
                                            setValue={setRolesResponsibilities}
                                            style={{ height: "200px" }}
                                          />
                                          {errors?.roles_responsibilities && (
                                            <Typography
                                              sx={{
                                                color: "red",
                                                paddingLeft: "20px",
                                                paddingTop: 6,
                                              }}
                                            >
                                              {errors?.roles_responsibilities}
                                            </Typography>
                                          )}
                                        </Grid>
                                      </>
                                    )}
                                    {jobPostFormMode === "Preview" && (
                                      <>
                                        {jobPostData.description && (
                                          <Grid
                                            item
                                            xs={6}
                                            md={6}
                                            marginLeft={2}
                                          >
                                            <Typography>
                                              Job Description
                                            </Typography>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: jobPostData.description,
                                              }}
                                            ></div>
                                          </Grid>
                                        )}
                                        {jobPostData.roles_responsibilities && (
                                          <Grid item xs={6} md={6}>
                                            <Typography>
                                              Roles and Responsibilities
                                            </Typography>
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  jobPostData.roles_responsibilities,
                                              }}
                                            ></div>
                                          </Grid>
                                        )}
                                      </>
                                    )}
                                  </Grid>
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
                {jobPostFormMode === "Preview" && isLoading ? (
                  <>
                    <Grid container direction="row" alignItems="center">
                      {Array(8)
                        .fill()
                        .map((data, index) => (
                          <Grid
                            key={index}
                            item
                            p={1.5}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={12}
                          >
                            <Card>
                              <CardHeader
                                action={null}
                                title={
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="80%"
                                    style={{ marginBottom: 6 }}
                                  />
                                }
                                subheader={
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="40%"
                                    style={{ marginBottom: 6 }}
                                  />
                                }
                              />
                              <Skeleton
                                sx={{ height: 130 }}
                                animation="wave"
                                variant="rectangular"
                              />
                              <CardContent>
                                <>
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    style={{ marginBottom: 6 }}
                                  />
                                  <Skeleton
                                    animation="wave"
                                    height={10}
                                    width="80%"
                                  />
                                </>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    {jobPostFormMode === "Preview" && (
                      <Box
                        sx={{
                          p: 2,
                          mt: 4,
                          backgroundColor: "white",
                          borderRadius: "10px",
                        }}
                      >
                        <Typography variant="h5" sx={{ ml: 1, pb: 1 }}>
                          Suggested Candidates
                        </Typography>
                        {showCandidate > 0 && (
                          <Typography
                            sx={{ mb: 2 }}
                            display="flex"
                            justifyContent="center"
                          >
                            {" "}
                            Candidates Not Available{" "}
                          </Typography>
                        )}
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={1}
                        >
                          {getsuggestions?.map((su, index) => (
                            <Grid
                              sx={{ padding: "10px" }}
                              key={index}
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              lg={6}
                            >
                              <Card sx={{ padding: "14px" }}>
                                <Box sx={{ paddingRight: "8px" }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      WebkitBoxPack: "justify",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <Tooltip
                                      placement="bottom-start"
                                      title="Name"
                                    >
                                      <Typography
                                        sx={{ mb: 0 }}
                                        variant="subtitle2"
                                        gutterBottom
                                      >
                                        {su?.first_name} {su?.last_name || ""}
                                      </Typography>
                                    </Tooltip>
                                    <IconButton
                                      onClick={() => proposeCandidate(su)}
                                    >
                                      <Tooltip title="Propose">
                                        <PersonAddAlt1Icon
                                          color="success"
                                          fontSize="small"
                                        />
                                      </Tooltip>
                                    </IconButton>
                                  </Box>
                                  <Divider sx={{ mb: 2 }} variant="middle" />
                                  <Grid
                                    sx={{ cursor: "Pointer" }}
                                    onClick={() => handleClickPreview(su)}
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                      <Tooltip
                                        placement="bottom-start"
                                        title="Email"
                                      >
                                        <span style={{ display: "flex" }}>
                                          <EmailIcon fontSize="small" />
                                          <Typography
                                            pl={1}
                                            variant="body2"
                                            gutterBottom
                                          >
                                            {su?.email_id}
                                          </Typography>
                                        </span>
                                      </Tooltip>
                                      <Tooltip
                                        placement="bottom-start"
                                        title="Location"
                                      >
                                        <span style={{ display: "flex" }}>
                                          <LocationOnRoundedIcon fontSize="small" />
                                          <Typography pl={1} variant="body2">
                                            {su?.location || "-"}
                                          </Typography>
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6}>
                                      <Tooltip
                                        placement="bottom-start"
                                        title="Primary Skills"
                                      >
                                        <span style={{ display: "flex" }}>
                                          <PsychologyIcon fontSize="small" />
                                          <Typography
                                            pl={1}
                                            variant="body2"
                                            gutterBottom
                                          >
                                            {su?.primary_skill}
                                          </Typography>
                                        </span>
                                      </Tooltip>
                                      <Tooltip
                                        placement="bottom-start"
                                        title="Secondary Skills"
                                      >
                                        <span style={{ display: "flex" }}>
                                          <PsychologyIcon fontSize="small" />
                                          <Typography pl={1} variant="body2">
                                            {su?.secondary_skill || "-"}
                                          </Typography>
                                        </span>
                                      </Tooltip>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <LinearProgress
                                        className="progress-value"
                                        variant="determinate"
                                        value={
                                          su?.profile_score
                                            ? su?.profile_score
                                            : 0
                                        }
                                      />
                                      <span
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <Typography pl={1} variant="body2">
                                          {su?.profile_score
                                            ? `${su?.profile_score}%`
                                            : "0%"}
                                        </Typography>
                                      </span>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Card>
                            </Grid>
                          ))}
                        </Grid>
                      </Box>
                    )}
                    {getsuggestions?.[0]?.total_count > 12 && (
                      <Pagination
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          mb: 2,
                        }}
                        showFirstButton
                        showLastButton
                        count={
                          Number.isInteger(
                            getsuggestions?.[0]?.total_count / 12
                          )
                            ? getsuggestions?.[0]?.total_count / 12
                            : Math.round(
                                getsuggestions?.[0]?.total_count / 12 + 0.5
                              ) || 0
                        }
                        page={page}
                        color="secondary"
                        onChange={(event, value) =>
                          handlePagination(event, value)
                        }
                      />
                    )}
                  </>
                )}
              </Box>
            </>
          )}
          {
            <AtsJobTemplatePreview
              anchor={anchor}
              toggleDrawer={toggleDrawer}
              drawerdata={drawerdata}
              handleApplyTemplate={handleApplyTemplate}
              jobPostData={jobPostData}
              setPostJobData={setPostJobData}
            />
          }
         
          {jobPostFormMode === "Preview" && (
            <ConfirmationDialog
              title="Propose Candidate"
              content={`Do you wish to Propose ${data?.first_name} to ${editedRecord.title}?`}
              anchorE={anchorE}
              openDialog={openDialog}
              closeDialog={setOpenDialog}
              popupTitle="Job"
              OkButtonText="Yes Propose"
              onSubmit={handleConfirmPropose}
            />
          )}
          <Popup
            title={"Feed Back Template"}
            openPopup={formBuilder}
            setOpenPopup={setFormBuilder}
            maxWidth="md"
            fullWidth={true}
          >
            <FormBuilder
              formBuilder={formBuilder}
              setFormBuilder={setFormBuilder}
              JD={true}
              title={builderTitle}
            />
          </Popup>
          <Popup
            title={"Pre Screen Question Template"}
            openPopup={preformBuilder}
            setOpenPopup={setPreFormBuilder}
            maxWidth="md"
            fullWidth={true}
          >
            <PreScreen
              preformBuilder={preformBuilder}
              setPreFormBuilder={setPreFormBuilder}
              JD={true}
              title={prebuilderTitle}
            />
          </Popup>
        </>
      )}
    </>
  );
};

export default AtsJobPostForm;
