import axiosInstance from "../../../../axiosInstance";
import history from "../../../../history";
import {
  SIGN_IN_SECRET,
  SIGN_IN_INITIAL_VECTOR,
  HRMS_UTILITY_API_URL,
} from "../../../Constants/baseConstant";
import {
  addSessionData,
  getSessionData,
  removeSessionData,
} from "../../Base/helper/baseHelper";
import * as CryptoJS from "crypto-js";
import { toast } from "react-toastify";
import { EmailHelper } from "../../Ancillary/Email/helper/EmailHelper";
import { renderEmail } from "react-html-email";
import BaseEmailTemplate from "../../Base/views/BaseEmailTemplate";
import { PasswordResetContent } from "../views/PasswordResetEmailTemplate";
import { v4 as uuidv4 } from "uuid";
import { getUserData } from "../../Base/helper/baseFunctions";
import { WorkspaceResetContent } from "../views/WorkspaceResetEmailTemplate";
import { GetATSModuleAction } from "../../../../store/RoleAccess/ATSModule/Action";
import { GetRequestAction } from "../../../../store/MeeplAdmin/Workspace/Action";
import { GetUsersHelper } from "../../Ancillary/Users/helper/UsersHelper";
import { AddMenuHeadingAction } from "../../../../store/Menu/Action";
import { GetConfigureStatusHelper, GetEmployeeOnboardStatusHelper } from "../../Ancillary/ConfigureStatus/helper/ConfigureStatusHelper";
import { GetConfigureStatusAction } from "../../../../store/Ancillary/ConfigureStatus/Action";
import { GetHomeAction, GetQuickLinksAction } from "../../../../store/EmployeeDashboard/Action";
import { GetQuickLinkHelper } from "../../Home/helper/EmployeeDashboardHelper";

function EncryptPassword(data) {
  let password = CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(SIGN_IN_SECRET),
    {
      iv: CryptoJS.enc.Utf8.parse(SIGN_IN_INITIAL_VECTOR),
      mode: CryptoJS.mode.CBC,
    }
  );
  return password.toString();
}

export const SignInHelper = async (signinData, dispatch) => {
  return await axiosInstance("/user/signIn", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      userid: signinData?.email,
      password:
        signinData?.step !== 1
          ? EncryptPassword(signinData?.password)
          : "password",
      domain: signinData?.domain,
    },
  })
    .then((res) => {
      let AtsUser = res.data?.data?.is_ats_user;
      let CoreHRUser = res.data?.data?.is_corehr_user;
      let UserId = res.data?.data?.userid;
      sessionStorage.setItem("UserId", UserId);
      dispatch(
        GetQuickLinksAction({
          domain: signinData?.domain?.toLowerCase(),
          emp_id: parseInt(res.data?.data?.userid),
        })
      );
      dispatch(
        GetHomeAction({
          domain: signinData?.domain?.toLowerCase(),
          emp_id: parseInt(res.data?.data?.userid),
        })
      );
    
      if (signinData?.guid !== undefined) {
        EmailRedirectionRouteHelper({
          domain: signinData?.domain,
          guid: signinData?.guid,
        });
      } else if (AtsUser && !CoreHRUser) {
        dispatch(
          GetRequestAction({
            domainname: signinData?.domain?.toLowerCase(),
            userid: res.data?.data?.userid,
          })
        );
        addSessionData({ key: "selectedMenu", value: "Home" });
        sessionStorage.setItem("selectedMenu", "Home");
        dispatch(AddMenuHeadingAction({ heading: "Home" }));
        GetUsersHelper({
          domain: signinData?.domain?.toLowerCase(),
          userid: res.data?.data?.userid,
        })
          .then((res) => {
            dispatch(
              GetATSModuleAction({
                userid: UserId,
                user_type: res.data?.data?.[0]?.core_hr_user_type,
                domain: signinData?.domain?.toLowerCase(),
                enable_ats:res.data?.data?.[0]?.enable_ats,
                enable_lms:res.data?.data?.[0]?.enable_lms,
                enable_corehr:res.data?.data?.[0]?.enable_corehr,
              })
            );
            GetQuickLinkHelper({
              domain: signinData?.domain?.toLowerCase(),
              emp_id: parseInt(res.data?.data?.userid),
            }).then((res) => {
              history.push("/home");
            });
          })
          .catch((err) => {});
      } else {
        dispatch(
          GetRequestAction({
            domainname: signinData?.domain?.toLowerCase(),
            userid: res.data?.data?.userid,
          })
        );
        addSessionData({ key: "selectedMenu", value: "Home" });
        sessionStorage.setItem("selectedMenu", "Home");
        dispatch(AddMenuHeadingAction({ heading: "Home" }));
        GetUsersHelper({
          domain: signinData?.domain?.toLowerCase(),
          userid: res.data?.data?.userid,
        })
          .then((res) => {
            dispatch(
              GetATSModuleAction({
                userid: UserId,
                user_type: res.data?.data?.[0]?.core_hr_user_type,
                domain: signinData?.domain?.toLowerCase(),
                enable_ats:res.data?.data?.[0]?.enable_ats,
                enable_lms:res.data?.data?.[0]?.enable_lms,
                enable_corehr:res.data?.data?.[0]?.enable_corehr,
              })
            );
            if (res.data?.data?.[0]?.core_hr_user_type === "Owner") {
              dispatch(GetConfigureStatusAction({  domainname: signinData?.domain?.toLowerCase(),userid: res.data?.data?.[0]?.emp_users_pk }));
              GetConfigureStatusHelper({
                domainname: signinData?.domain?.toLowerCase(),
                userid: res.data?.data?.[0]?.emp_users_pk,
              }).then((res) => {
                if (res.data?.data?.[0]?.onboarding_completed === false) {
                  history.push("/configure-setting");
                }
                else{
                  history.push("/home");
                }
              });
            } 
            else if(res.data?.data?.[0]?.core_hr_user_type === "Admin" || res.data?.data?.[0]?.core_hr_user_type === "Employee"){
              GetEmployeeOnboardStatusHelper({
                domainname: signinData?.domain?.toLowerCase(),
                employee_id: res.data?.data?.[0]?.employee_id,
              }).then((res) => {
                if (res.data?.data?.[0]?.empform_onboarding_status === false) {
                  history.push("/employee/Onboardemployee/");
                }
                else{
                  history.push("/home");
                }
              });
            }
            else {
              history.push("/home");
            }
          })
          .catch((err) => {});
      }
      if (res?.status === 200 && signinData?.step === 1) {
        signinData.setNextLogin(true);
      }
      addSessionData({ key: "isSignedIn", value: true });
      addSessionData({
        key: "is_ats_user",
        value: res.data?.data?.is_ats_user,
      });
      return {
        message: res,
        payload: {
          userid: res.data?.data?.userid,
          domain: signinData?.domain?.toLowerCase(),
          is_ats_user: res.data?.data?.is_ats_user,
        },
      };
    })
    .catch((err) => {
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
    });
};

export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  const isSignedIn = getSessionData({ key: "isSignedIn" });

  if (isSignedIn) {
    return true;
  } else {
    return false;
  }
};

export const signOut = () => {
  if (typeof window !== "undefined") {
    removeSessionData({ key: "isSignedIn" });
    removeSessionData({ key: "is_ats_user" });
    removeSessionData({ key: "selectedMenu" });
    removeSessionData({ key: "theme" });
    removeSessionData({ key: "userData" });
    removeSessionData({ key: "userManagement" });
    removeSessionData({ key: "AdminEmail" });
    removeSessionData({ key: "userid" });
    localStorage.removeItem("feedbackData", "");
    history.push("/");
    return;
  }
};

export const Refresh = (dispatch) => {
  if (typeof window !== "undefined") {
    var user = getUserData();
    const UserId = sessionStorage.getItem("UserId");
    dispatch(
      GetQuickLinksAction({
        domain: user?.domain,
        emp_id: parseInt(UserId),
      })
    );
    dispatch(
      GetHomeAction({
        domain: user?.domain,
        emp_id: parseInt(UserId),
      })
    );
    dispatch(
      GetATSModuleAction({
        userid: UserId,
        user_type: user?.core_hr_user_type,
        domain: user?.domain,
        enable_ats:user?.enable_ats,
        enable_lms:user?.enable_lms,
        enable_corehr:user?.enable_corehr,
      })
    );
    
    return;
  }
};

export const EmailRedirectionRouteHelper = async (signinData) => {
  return await axiosInstance("/ancillary/getEmailRedirectionDetails", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: signinData?.domain,
      guid: signinData?.guid,
    },
  })
    .then((res) => {
      let path = res.data.data?.[0]?.mail_url;
      history.push(`${path?.split("-")?.[0]}`);
    })
    .catch((err) => {
      return { message: err?.response?.data };
    });
};

export const validateEmailHelper = async (params) => {
  let token = uuidv4() + `=${params?.domain}`;
  return await axiosInstance("/user/validateEmail", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: params?.domain,
      email: params?.email,
      token: token,
    },
  })
    .then((res) => {
      let name = params?.first_name || res?.data?.data?.name;
      // Reset Password Email
      let content = renderEmail(
        <BaseEmailTemplate>
          <PasswordResetContent
            userid={params?.email}
            workspace={params?.domain}
            name={name}
            token={token}
          />
        </BaseEmailTemplate>
      );
      EmailHelper({
        to: params?.email,
        subject: "Password Reset",
        content: content,
      });
      params?.message && toast.success(params?.message);
      return { message: res?.data };
    })
    .catch((err) => {
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: err?.response?.data };
    });
};

export const validateWorkspaceHelper = async (params) => {
  return await axiosInstance("/user/getDomains", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      user_id: params?.email,
      password: EncryptPassword(params?.password),
    },
  })
    .then((res) => {
      let workspace = res?.data?.data?.domainname;

      let content = renderEmail(
        <BaseEmailTemplate>
          <WorkspaceResetContent userid={params?.email} workspace={workspace} />
        </BaseEmailTemplate>
      );
      EmailHelper({
        to: params?.email,
        subject: "Workspace Reset",
        content: content,
      });
      return { message: res?.data };
    })
    .catch((err) => {
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: err?.response?.data };
    });
};

export const GetWorkspaceHelper = async (signinData) => {
  return await axiosInstance("/user/getWorkspaces", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      user_id: signinData?.email,
    },
  })
    .then((res) => {
      if (res?.status === 200 && signinData?.step === 1) {
        signinData.setNextLogin(true);
      }
      return { data: res?.data };
    })
    .catch((err) => {
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: err?.response?.data };
    });
};

export const resetPasswordHelper = async (params) => {
  return await axiosInstance("/user/resetPassword", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: params?.domain,
      token: params?.token,
      email_id: params?.email_id,
      password: params?.password,
    },
  })
    .then((res) => {
      toast.success("Password Changed Successfully");
      return {
        message: "Password Changed Successfully",
        hasError: false,
        data: res?.data,
      };
    })
    .catch((err) => {
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: err?.response?.data };
    });
};

export const changePasswordHelper = async (params) => {
  var user = getUserData();
  return await axiosInstance("/user/changePassword", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: user?.domain ? user?.domain : params?.domain,
      user_id: user?.emp_users_pk ? user?.emp_users_pk : params?.userid,
      email_id: user?.email_id ? user?.email_id : user?.office_email ? user?.office_email : params?.email_id,
      token: null,
      old_password: params?.old_password,
      password: params?.password,
    },
  })
    .then((res) => {
      toast.dismiss();
      toast.success("Password Updated Successfully");
      return {
        message: "Password Updated Successfully",
        hasError: false,
        data: res?.data,
      };
    })
    .catch((err) => {
      toast.dismiss();
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: err?.response?.data };
    });
};

export const GetTourRequest = async () => {
  var user = getUserData();
  return await axiosInstance("/ancillary/getTourStatus", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: user?.domain,
      user_id: user?.employee_id,
    },
  })
    .then((res) => {
      return {
        message: "Tour status Retrived Successfully",
        hasError: false,
        data: res?.data,
      };
    })
    .catch((err) => {
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: err?.response?.data, hasError: true };
    });
};

export const UpdateTourRequest = async () => {
  var user = getUserData();
  return await axiosInstance("/ancillary/updateTourStatus", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      domain_name: user?.domain,
      user_id: user?.employee_id,
      status: true,
    },
  })
    .then((res) => {
      return {
        message: "Tour status Updated Successfully",
        hasError: false,
        data: res?.data,
      };
    })
    .catch((err) => {
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: err?.response?.data, hasError: true };
    });
};


export const GetDomainHelper = async (signinData) => {
  return await axiosInstance("/user/getDomain", {
    method: "POST",
    baseURL: HRMS_UTILITY_API_URL,
    data: {
      user_id: signinData?.email,
    },
  })
    .then((res) => {
      return { message: "Success", hasError: false, data: res?.data }
    })
    .catch((err) => {
      err?.response?.data?.error?.[0]?.message &&
        toast.error(err?.response?.data?.error?.[0]?.message);
      return { message: err?.response?.data };
    });
};