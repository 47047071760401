import {
    Box,
    Button,
    Checkbox,
    Divider,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText
  } from "@mui/material";
  import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
  import { useEffect } from "react";
  import { useState } from "react";
  import { useDispatch, useSelector } from "react-redux";
  import { GetDesignationAction } from "../../../../store/Ancillary/Designation/Action";
  import { GetEmploymentTypeAction } from "../../../../store/Ancillary/EmploymentType/Action";
  // import { GetJobAction } from '../../../../store/Ancillary/Job/Action'
  // import { GetPaygradeAction } from '../../../../store/Ancillary/Paygrade/Action'
  // import { GetSalaryStructureAction } from '../../../../store/Finance/SalaryStructure/Action';
  // import { GetEmployeesAction } from '../../../../store/Employee/Employee/Action'
  // import { GetCostCenterAction } from '../../../../store/Ancillary/CostCenter/Action'
  // import { GetRoleAction } from '../../../../store/RoleAccess/Role/Action'
  import CommonDatePicker from "../../Base/views/CommonDatePicker";
  import SelectableSearch from "../../Base/views/SelectableSearch";
  import "../css/styles.css";
  import { createStyles, makeStyles } from "@mui/styles";
  import { convertBase64, getUserData } from "../../Base/helper/baseFunctions";
  import { useDropzone } from "react-dropzone";
  import { toast } from "react-toastify";
  import { GetCountryHelper } from "../../Ancillary/Country/helper/CountryHelper";
  import { GetStateHelper } from "../../Ancillary/State/helper/StateHelper";
  import { GetCityHelper } from "../../Ancillary/City/helper/CityHelper";
  import { AddEmployeeOnboardingHelper } from "../helper/OnboardingHelper";
  import {
    GetEmployeesAction,
  } from "../../../../store/Employee/Employee/Action";
  import FileUploader from "../../ATS/views/ATSCandidate/FileUploader";
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
  import { GetDocumentationsAction } from "../../../../store/Ancillary/Documentations/Action";
  import AddIcon from "@mui/icons-material/Add";
  import DeleteIcon from "@mui/icons-material/Delete";
  import { v4 as uuidv4 } from "uuid";
  import CreatableSearch from "../../Base/views/CreatableSearch";
  import { AddDesignationHelper } from "../../Ancillary/Designation/helper/DesignationHelper";
  import { AddEmploymentTypeHelper } from "../../Ancillary/EmploymentType/helper/EmploymentTypeHelper";
  import ArrowBackButton from "../../Base/views/ArrowBackButton";
  import { GetWorkLocationAction } from "../../../../store/Ancillary/WorkLocation/Action";
import { AddEmailTokenHelper } from "../../Ancillary/EmailToken/helper/EmailTokenHelper";
import { validateEmailHelper } from "../../SignIn/helper/SigninHelper";
import { AddWorkLocationHelper } from "../../Ancillary/WorkLocation/helper/WorkLocation";
  
  const EmployeeAddForm = ({ setCurrentMode, setInternal }) => {
    const userData = getUserData();
    const useStyles = makeStyles((theme) =>
      createStyles({
        paper: {
          boxShadow:
            theme.palette.mode === "dark"
              ? "rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px"
              : "0px 3px 3px -2px #cbc3c3, 0px 3px 4px 0px rgb(242 242 242), 0px 1px 8px 0px rgb(242 242 242)",
          padding: "20px",
          borderRadius: "10px",
        },
        comingSoon: {
          fontWeight: 600,
          fontSize: "18px",
          marginTop: "30px",
          color: "white",
        },
      })
    );
    const classes = useStyles();
    const dispatch = useDispatch();
    const date = new Date();
    const default_date = `${
      date?.getFullYear() +
      "-" +
      ("0" + (date?.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + date?.getDate()).slice(-2)
    }`;
    const initialValues = {
      // Basic
      first_name: "",
      middle_name: "",
      last_name: "",
      employee_no: "",
      personal_email: "",
      personal_mobile: "",
      _designation_id: "",
      _job_id: "",
      role_id: "",
      // Personal
      birthdate: "",
      gender: "",
      marital_status: "",
      marriage_date: null,
      image: null,
      emg_contact_no: "",
      emg_contact_person: "",
      cur_city: "",
      cur_state: "",
      cur_country: "",
      per_city: "",
      per_state: "",
      per_country: "",
      cur_street1: "",
      cur_street2: "",
      per_street1: "",
      per_street2: "",
      cur_zipcode: "",
      per_zipcode: "",
      is_permanent: false,
      // Work Details
      joining_date: default_date,
      employmenttype_id: "",
      paygrade_id: "",
      salary_structure_id: "",
      ctc: "",
      costcenter_id: "",
      phy_disabled: false,
      phy_disabilities: "",
      international_worker: false,
      contractor: "",
      contract_from: null,
      contract_to: null,
      hrmanager_id: "",
      uan_no: "",
      office_email: "",
      ats_work_location_id: "",
      reportingmanager_id: "",
      core_hr_user_type: "Employee",
      enable_corehr: true,
      enable_ats: false,
    };
  
    const documentInitValue = {
      proof_type: "",
      number: "",
      file: [],
    };
  
    const { designation_options } = useSelector(
      (state) => state?.AncillaryReducer?.DesignationReducer
    );
  
    const { employment_type_options } = useSelector(
      (state) => state.AncillaryReducer.EmploymentTypeReducer
    );
    const { documentations_options } = useSelector(
      (state) => state?.AncillaryReducer?.DocumentationsReducer
    );
    // const { cost_center_options } = useSelector(state => state.AncillaryReducer.CostCenterReducer)
    // const { employees_options } = useSelector(state => state.EmployeeReducer.EmployeesReducer)
    // const { paygrade_options } = useSelector(state => state.AncillaryReducer.PaygradeReducer)
    // const { salaryStructure_data } = useSelector(state => state.FinanceReducer?.SalaryStructureReducer);
    // const { role_data } = useSelector(state => state?.RoleAndAccessReducer?.RoleReducer);
    const { job_options } = useSelector(
      (state) => state?.AncillaryReducer?.JobReducer
    );
    const { workLocation_options } = useSelector(
      (state) => state?.AncillaryReducer?.WorkLocationReducer
    );
  
    const { employees_options } = useSelector(
      (state) => state.EmployeeReducer.EmployeesReducer
    );
    const { candidateData } = useSelector((state) => state?.OnboardingReducer);
    const [inputData, setInputData] = useState(initialValues);
    const [errors, setErrors] = useState({});
  
    const [proofErrors, setProofErrors] = useState([]);
    const [files, setFiles] = useState([]);
    const [documentsData, setDocumentsData] = useState([]);
    const [documentsUrl, setDocumentsUrl] = useState("");
    const [show, setShow] = useState(false);
    const [designationData, setDesignationData] = useState({
      load: false,
      values: designation_options || [],
    });
    const [worklocationData, setWorklocationData] = useState({
      load: false,
      values: [],
    });
    const [employeesData, setEmployeesData] = useState({
      load: true,
      values: [],
    });
    const [jobData, setJobData] = useState({ load: false, values: [] });
    const [employmentTypeData, setEmploymentTypeData] = useState({
      load: true,
      values: [],
    });
    // const [costCenterData, setCostCentereData] = useState({ load: true, values: [] });
    // const [employeesData, setEmployeesData] = useState({ load: true, values: [] });
    // const [paygradeData, setPaygradeData] = useState({ load: true, values: [] });
    // const [structureData, setStructureData] = useState([]);
    // const [roleData, setRoleData] = useState({ load: true, values: [] });
    const [curCountryData, setCurCountryData] = useState({
      load: true,
      values: [],
    });
    const [curStateData, setCurStateData] = useState({ load: true, values: [] });
    const [curCityData, setCurCityData] = useState({ load: true, values: [] });
    const [perStateData, setPerStateData] = useState({ load: true, values: [] });
    const [perCityData, setPerCityData] = useState({ load: true, values: [] });
    const [proofData, setProofData] = useState({ load: true, values: [] });
    // const [openComponent, setOpenComponent] = useState(false);
    const [step, setStep] = useState("1");
  
    const [maritalStatus] = useState({
      load: false,
      values: [
        { id: 1, name: "Married" },
        { id: 2, name: "Unmarried" },
        { id: 3, name: "Separated" },
        { id: 4, name: "Divorced" },
        { id: 5, name: "Widowed" },
      ],
    });
  
    useEffect(() => {
      dispatch(GetDesignationAction());
  
      dispatch(GetEmployeesAction({ is_active: true }));
      dispatch(GetWorkLocationAction());
      dispatch(GetEmploymentTypeAction());
      dispatch(GetDocumentationsAction());
      // dispatch(GetJobAction());
      // dispatch(GetPaygradeAction())
      // dispatch(GetSalaryStructureAction())
      // dispatch(GetRoleAction());
      // dispatch(GetCostCenterAction())
      // dispatch(GetEmployeesAction())
      candidateData?.data &&
        setInputData({
          ...inputData,
          first_name: candidateData?.data?.first_name,
          last_name: candidateData?.data?.last_name,
          personal_email: candidateData?.data?.email_id,
          personal_mobile: candidateData?.data?.contact_no,
          _job_id: {
            job_id: candidateData?.data?.ats_jobpost_id,
            name: candidateData?.data?.title,
          },
          birthdate: candidateData?.data?.dob,
          gender: candidateData?.data?.gender,
          joining_date: candidateData?.data?.joined_date || default_date,
          accepted_ctc: candidateData?.data?.joined_ctc,
          candidate_id: candidateData?.data?.candidate_id,
          is_internal: candidateData?.data?.is_internal,
        });
      inputData?.personal_email && handleCurCountry();
      // eslint-disable-next-line
    }, [inputData?.personal_email]);
  
    const handleEmployees = () => {
      const filteredData = employees_options.filter((employee) => {
        return (
          (employee.employee_id !== userData?.employee_id &&
            employee.core_hr_user_type !== "Employee") ||
          employee.core_hr_user_type === "Owner"
        );
      });
      employees_options &&
        setEmployeesData({ load: false, values: filteredData });
    };
    const { getRootProps, getInputProps } = useDropzone({
      disabled: false,
      multiple: false,
      maxSize: 2000000,
      accept: {
        "image/*": [".png", ".jpg", ".jpeg"],
      },
      onDrop: (acceptedFiles) => {
        setFiles(acceptedFiles.map((file) => Object.assign(file)));
      },
      onDropRejected: () => {
        toast.error("You can only upload image with maximum size of 2 MB.", {
          duration: 2000,
        });
      },
    });
  
    const img = files.map((file) => (
      <img
        style={{ borderRadius: "50%", padding: "2px" }}
        width="100%"
        height="100%"
        key={file.name}
        alt={file.name}
        className="single-file-image"
        src={URL.createObjectURL(file)}
      />
    ));
  
    const validate = (fieldValues = inputData) => {
      let temp = { ...errors };
      // BASIC
      if ("first_name" in fieldValues) {
        temp.first_name =
          fieldValues.first_name === "" ? "First Name is required" : "";
      }
  
      if ("last_name" in fieldValues) {
        temp.last_name =
          fieldValues.last_name === "" ? "Last Name is required" : "";
      }
      if ("birthdate" in fieldValues) {
          temp.birthdate = fieldValues.birthdate === null ? "DOB is required" : "";
        }
      if ("personal_email" in fieldValues) {
        temp.personal_email =
          fieldValues.personal_email === ""
            ? ""
            : /$^|.+@.+..+/.test(fieldValues.personal_email)
            ? ""
            : "Invalid Email.";
      }
  
      if ("personal_mobile" in fieldValues) {
        temp.personal_mobile =
          fieldValues.personal_mobile === ""
            ? ""
            : fieldValues?.personal_mobile
                ?.toString()
                .match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
            ? ""
            : "Invalid Mobile.";
      }
  
      if ("employmenttype_id" in fieldValues) {
        temp.employmenttype_id =
          fieldValues.employmenttype_id === ""
            ? "Employment Type is required"
            : "";
      }
      if ("accepted_ctc" in fieldValues) {
        temp.accepted_ctc =
          fieldValues.accepted_ctc === "" ? "Accepted CTC is required" : "";
      }
      if (inputData?.employmenttype_id?.etype_name === "On Contract") {
        if ("contract_from" in fieldValues) {
          temp.contract_from =
            fieldValues.contract_from === "" || fieldValues.contract_from === null
              ? "Contract From is required"
              : "";
        }
        if ("contract_to" in fieldValues) {
          temp.contract_to =
            fieldValues.contract_to === "" || fieldValues.contract_to === null
              ? "Contract To is required"
              : "";
        }
        if ("contractor" in fieldValues) {
          temp.contractor =
            fieldValues.contractor === "" || fieldValues.contractor === null
              ? "Contractor is required"
              : "";
        }
      } else {
        temp.contractor = "";
        temp.contract_from = "";
        temp.contract_to = "";
      }
      if (fieldValues?.is_internal) {
        if ("_designation_id" in fieldValues) {
          temp._designation_id =
            fieldValues._designation_id === "" ? "Designation is required" : "";
        }
      }
      if (fieldValues?.is_internal && show) {
          if ("office_email" in fieldValues) {
              temp.office_email =
                fieldValues.office_email === "" || null
                  ? "Email is required"
                  : /$^|.+@.+..+/.test(fieldValues.office_email)
                  ? ""
                  : "Invalid Email.";
            }
        }
        if (fieldValues?.is_internal && show) {
          if ("ats_work_location_id" in fieldValues) {
              temp.ats_work_location_id =
                fieldValues.ats_work_location_id === "" || null
                  ? "Work Location is required"
                  : "";
            }
        }
        if (fieldValues?.is_internal && show) {
          if ("reportingmanager_id" in fieldValues) {
              temp.reportingmanager_id =
                fieldValues.reportingmanager_id === "" || null
                  ? "Reporting Manager is required"
                  : "";
            }
        }
  
      // WORK DETAILS
      if ("joining_date" in fieldValues) {
        temp.joining_date =
          fieldValues.joining_date === "" || fieldValues.joining_date === null
            ? "Date of Joining is required"
            : "";
      }
      if ("uan_no" in fieldValues) {
        temp.uan_no =
          fieldValues.uan_no === "" || fieldValues.uan_no === null
            ? ""
            : fieldValues?.uan_no.toString() !== "" &&
              !fieldValues?.uan_no?.toString()?.match(/^\(?[0-9]{12}$/)
            ? "Invalid UAN Number."
            : "";
      }
      // if ("role_id" in fieldValues) {
      //     temp.role_id = (fieldValues.role_id === "" || null) ? "User Role is required" : "";
      // }
  
      // PERSONAL
  
      if ("per_country" in fieldValues) {
        temp.per_country =
          fieldValues.per_country === "" ? "Country is required" : "";
      }
      if ("per_state" in fieldValues) {
        temp.per_state = fieldValues.per_state === "" ? "State is required" : "";
      }
      if ("per_city" in fieldValues) {
        temp.per_city = fieldValues.per_city === "" ? "City is required" : "";
      }
  
      setErrors({
        ...temp,
      });
      const temp1 = { ...temp };
      return Object.values(temp1).every((x) => x === "");
    };
  
    const proofvalidate = () => {
      let ret_value = [];
      documentsData?.forEach((store, index) => {
        const error = {};
        if (!store.proof_type) error.proof_type = "Proof Type is required";
        if (!store.number) error.number = "Proof Number is required";
        if (store?.file?.length === 0) error.file = "Proof Document is Required";
        const newErrors = [...proofErrors];
        while (newErrors.length <= index) {
          newErrors.push({});
        }
        newErrors[index] = error;
        setProofErrors(newErrors);
        ret_value.push(Object.values(error).every((e) => e === ""));
      });
      return ret_value;
    };
  
    const handleCurCountry = () => {
      GetCountryHelper().then((res) => {
        setCurCountryData({ load: false, values: res?.data?.data });
        let value = res?.data?.data?.filter(
          (item) => item?.country_name === "India"
        );
        setInputData({
          ...inputData,
          cur_country: value?.[0],
          per_country: value?.[0],
        });
      });
    };
  
    const loadingCurState = () => {
      GetStateHelper(inputData?.cur_country?.country_id).then((res) => {
        setCurStateData({ load: false, values: res?.data?.data });
      });
    };
    const loadingPerState = () => {
      GetStateHelper(inputData?.per_country?.country_id).then((res) => {
        setPerStateData({ load: false, values: res?.data?.data });
      });
    };
  
    const loadingCurCity = () => {
      GetCityHelper(inputData?.cur_state?.state_id).then((res) => {
        setCurCityData({ load: false, values: res?.data?.data });
      });
    };
    const loadingPerCity = () => {
      GetCityHelper(inputData?.per_state?.state_id).then((res) => {
        setPerCityData({ load: false, values: res?.data?.data });
      });
    };
  
    const handleInputChange = (event, value) => {
      // BASIC
      if (
        [
          "_job_id",
          "paygrade_id",
          "salary_structure_id",
          "costcenter_id",
          "reportingmanager_id",
          "hrmanager_id",
          "marital_status",
          "cur_city",
          "per_city",
          "role_id",
          "proof_type",
        ].includes(event)
      ) {
        if (value) {
          setInputData({ ...inputData, [event]: value });
        } else {
          setInputData({ ...inputData, [event]: "" });
        }
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
          validate({ ...inputData, [event]: value ? value : "" });
      } else if (
        ["_designation_id"].includes(
          event
        )
      ) {
          if (value?.id === null) {
            AddDesignationHelper({ name: value?.name }).then((res) => {
              setDesignationData({ load: false, values: res?.data?.data });
              setInputData({
                ...inputData,
                [event]: res?.data?.data?.filter(
                  (resp) => resp?.name === value?.name
                )?.[0],
              });
            });
          } else setInputData({ ...inputData, [event]: value });
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
          validate({ ...inputData, [event]: value ? value : "" });
      } else if (
        ["ats_work_location_id"].includes(
          event
        )
      ) {
          if (value?.id === null) {
            AddWorkLocationHelper({ name: value?.work_location_name }).then((res) => {
              setWorklocationData({ load: false, values: res?.data?.data });
              setInputData({
                ...inputData,
                [event]: res?.data?.data?.filter(
                  (resp) => resp?.work_location_name === value?.work_location_name
                )?.[0],
              });
            });
          } else setInputData({ ...inputData, [event]: value });
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
          validate({ ...inputData, [event]: value ? value : "" });
      } 
      else if ("employmenttype_id" === event) {
        if (value) {
          if (value?.id === null) {
            AddEmploymentTypeHelper({ etype_name: value?.etype_name }).then(
              (res) => {
                setDesignationData({ load: false, values: res?.data?.data });
                setInputData({
                  ...inputData,
                  [event]: res?.data?.data?.filter(
                    (resp) => resp?.etype_name === value?.etype_name
                  )?.[0],
                  contractor: "",
                  contract_from: "",
                  contract_to: "",
                });
              }
            );
          } else
            setInputData({
              ...inputData,
              [event]: value,
              contractor: "",
              contract_from: "",
              contract_to: "",
            });
        }
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
          validate({ ...inputData, [event]: value ? value : "" });
      }
  
      // WORK DETAILS
      else if (["international_worker"].includes(event?.target?.name)) {
        setInputData({ ...inputData, [event.target.name]: event.target.checked });
      } else if (event?.target?.name === "phy_disabled") {
        setInputData({
          ...inputData,
          [event.target.name]: event.target.checked,
          phy_disabilities: "",
        });
      }
      // PERSONAL DETAILS
      else if (["cur_country", "per_country"].includes(event)) {
        if (event === "cur_country") {
          setCurStateData({ load: true, values: [] });
          setCurCityData({ load: true, values: [] });
          setInputData({
            ...inputData,
            [event]: value ? value : "",
            cur_state: "",
            cur_city: "",
          });
        } else {
          setPerStateData({ load: true, values: [] });
          setPerCityData({ load: true, values: [] });
          setInputData({
            ...inputData,
            [event]: value ? value : "",
            per_state: "",
            per_city: "",
          });
        }
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
          validate({ ...inputData, [event]: value ? value : "" });
      } else if (["cur_state", "per_state"].includes(event)) {
        if (event === "cur_state") {
          setCurCityData({ load: true, values: [] });
          setInputData({
            ...inputData,
            [event]: value ? value : "",
            cur_city: "",
          });
        } else {
          setPerCityData({ load: true, values: [] });
          setInputData({
            ...inputData,
            [event]: value ? value : "",
            per_city: "",
          });
        }
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
          validate({ ...inputData, [event]: value ? value : "" });
      } else if (event?.target?.name === "is_permanent") {
        if (event.target.checked) {
          setInputData({
            ...inputData,
            [event.target.name]: event.target.checked,
            cur_street1: inputData?.per_street1,
            cur_street2: inputData?.per_street2,
            cur_city: inputData?.per_city,
            cur_state: inputData?.per_state,
            cur_country: inputData?.per_country,
            cur_zipcode: inputData?.per_zipcode,
          });
        } else {
          setInputData({
            ...inputData,
            [event.target.name]: event.target.checked,
            cur_street1: "",
            cur_street2: "",
            cur_city: "",
            cur_state: "",
            cur_country: "",
            cur_zipcode: "",
          });
        }
      } else if (event?.target?.name === "core_hr_user_type") {
          setInputData({
            ...inputData,
            [event?.target?.name]: event?.target?.value,
          });
        } else if (["enable_ats"].includes(event?.target?.name)) {
          setInputData({
            ...inputData,
            [event.target.name]: event.target.checked,
          });
        } else if (event?.target?.value === "Admin") {
          setInputData({
            ...inputData,
            [event?.target?.name]: event?.target?.value,
            enable_ats: true,
          });
        }else {
        setInputData({ ...inputData, [event?.target?.name]: event.target.value });
        Object.values?.(errors)?.find((res) => res !== "")?.length > 0 &&
          validate({ ...inputData, [event?.target?.name]: event.target.value });
      }
      if (
        ["cur_city", "cur_state", "cur_country"].includes(event) ||
        ["cur_street1", "cur_street2", "cur_zipcode"].includes(
          event?.target?.name
        )
      ) {
        setInputData((currentArray) => ({
          ...currentArray,
          is_permanent: false,
        }));
      }
    };
  
    const handleDocumentChange = (e, value, id) => {
      let newArr = [...documentsData];
      const newErrors = [...proofErrors];
      let item = newArr[id];
      item = { ...item, [e]: value };
      newArr[id] = item;
      newErrors[id] = { ...newErrors[id], [e]: "" };
      setDocumentsData(newArr);
      setProofErrors(newErrors);
    };
  
    const handleNext = async () => {
      if (proofvalidate() && validate()) {
          setStep("2");
          setShow(true)
      }
     
    };
  
    const handleSubmit = async () => {
      // let imgStr = files?.[0] ? await convertBase64(files?.[0]) : null
      if (proofvalidate() && validate())  {
        let b64Files = [];
        let fileNames = [];
        let documentationData = [];
        let contentTypes = [];
        let b64string = "";
        let filename = "";
        let content_type = "";
        for (let i = 0; i < documentsData?.length; i++) {
          b64string = await convertBase64(documentsData[i]?.file[0]);
          let ext = documentsData?.[i]?.file?.[0]?.name?.split(".").at(-1);
          content_type =
            ext === "pdf"
              ? "application/pdf"
              : ["image/png", "image/jpeg"]?.includes(ext)
              ? "image/jpeg"
              : "application/msword";
          filename =
            `${userData?.domain}/ATS/Documents/${documentsData[i]?.proof_type?.proof_folder_name}/` +
            uuidv4() +
            "." +
            ext;
          b64Files.push(b64string);
          fileNames.push(filename);
          contentTypes?.push(content_type);
          documentationData.push({
            proof_id: documentsData[i]?.proof_type?.proof_pk,
            proof_path: filename,
            proof_number: documentsData[i]?.number,
          });
        }
  
        const empDetails = [
          {
            first_name: inputData?.first_name,
            middle_name: inputData?.middle_name,
            last_name: inputData?.last_name,
            employee_no: "",
            birthdate: inputData?.birthdate ? inputData?.birthdate : null,
            gender: inputData?.gender,
            personal_email: inputData?.personal_email,
            personal_mobile: inputData?.personal_mobile
              ? +inputData?.personal_mobile
              : null,
            marital_status: inputData?.marital_status?.name
              ? inputData?.marital_status?.name
              : "",
            marriage_date: inputData?.marriage_date
              ? inputData?.marriage_date
              : null,
            // image: imgStr ? imgStr.split(",")[1] : null,
            image: null,
          },
        ];
        const employmentDetails = [
          {
            designation_fk: inputData?.is_internal
              ? inputData?._designation_id?.designation_id
                ? +inputData?._designation_id?.designation_id
                : null
              : inputData?._job_id?.job_id
              ? +inputData?._job_id?.job_id
              : null,
            job_fk: inputData?._job_id?.job_id
              ? +inputData?._job_id?.job_id
              : null,
            employmenttype_fk: +inputData?.employmenttype_id?.employmenttype_id,
            costcenter_fk: inputData?.costcenter_id?.costcenter_id
              ? +inputData?.costcenter_id?.costcenter_id
              : null,
            projectmanager_fk: inputData?.reportingmanager_id?.employee_id
              ? +inputData?.reportingmanager_id?.employee_id
              : null,
            hrmanager_fk: inputData?.hrmanager_id?.employee_id
              ? +inputData?.hrmanager_id?.employee_id
              : null,
            joining_date: inputData?.joining_date,
            paygrade_fk: inputData?.paygrade_id?.paygrade_id
              ? +inputData?.paygrade_id?.paygrade_id
              : null,
            uan_no: inputData?.uan_no ? +inputData?.uan_no : null,
            international_worker: inputData?.international_worker,
            emg_contact_no: inputData?.emg_contact_no
              ? +inputData?.emg_contact_no
              : null,
            emg_contact_person: inputData?.emg_contact_person,
            per_street1: inputData?.per_street1,
            per_street2: inputData?.per_street2,
            per_city_fk: inputData?.per_city?.city_id
              ? +inputData?.per_city?.city_id
              : null,
            per_state_fk: inputData?.per_state?.state_id
              ? +inputData?.per_state?.state_id
              : null,
            per_country_fk: inputData?.per_country?.country_id
              ? +inputData?.per_country?.country_id
              : null,
            per_zipcode: inputData?.per_zipcode,
            cur_street1: inputData?.cur_street1,
            cur_street2: inputData?.cur_street2,
            cur_city_fk: +inputData?.cur_city?.city_id,
            cur_state_fk: +inputData?.cur_state?.state_id,
            cur_country_fk: +inputData?.cur_country?.country_id,
            cur_zipcode: inputData?.cur_zipcode,
            role_fk: +inputData?.role_id?.role_id,
            phy_disabled: inputData?.phy_disabled,
            phy_disabilities: inputData?.phy_disabilities,
            contractor: inputData?.contractor,
            contract_from: inputData?.contract_from
              ? inputData?.contract_from
              : null,
            contract_to: inputData?.contract_to ? inputData?.contract_to : null,
            ats_work_location_id : inputData?.ats_work_location_id?.ats_work_location_id
            ? +inputData?.ats_work_location_id?.ats_work_location_id
            : null,
          },
        ];
        AddEmployeeOnboardingHelper({
          domain: userData?.domain,
          user_emp_id: userData?.employee_id,
          candidate_id: inputData?.candidate_id,
          empDetails: `${JSON.stringify(empDetails)}`,
          employmentDetails: `${JSON.stringify(employmentDetails)}`,
          ctc: inputData?.accepted_ctc,
          salary_structure_id: inputData?.salary_structure_id,
          b64Files: b64Files,
          fileNames: fileNames,
          contentTypes: contentTypes,
          proof_details: `${JSON.stringify(documentationData)}`,
          office_email: inputData?.office_email,
          enable_ats: inputData?.enable_ats,
          user_type: inputData?.core_hr_user_type,
          is_internal: inputData?.is_internal
        }).then((res) => {
          if(inputData?.is_internal){
            let token = uuidv4() + `=${userData?.domain}`;
            AddEmailTokenHelper({
              token: token,
              email_id: inputData?.office_email,
              json_data: JSON.stringify([{ ...inputData }]),
            });
            validateEmailHelper({ domain: userData?.domain, email: inputData?.office_email })
          }
          if (!res.hasError) {
            setCurrentMode("grid");
            if(inputData?.is_internal){
              setInternal(true)
            }
            else{
              setInternal(false)
            }
          }
        });
      }
    };
  
    const handleAddPoof = () => {
      setDocumentsData((state) => [...state, documentInitValue]);
      proofvalidate();
    };
  
    const handleProofDelete = (e, id) => {
      let proofData = [...documentsData];
      proofData.splice(id, 1);
      setDocumentsData(proofData);
    };
  
    const handleFileChange = (file, id) => {
      let newArr = [...documentsData];
      const newErrors = [...proofErrors];
      let item = newArr[id];
      item = { ...item, file: file };
      newArr[id] = item;
      newErrors[id] = { ...newErrors[id], file: "" };
      setDocumentsData(newArr);
      setProofErrors(newErrors);
    };
  
    return (
      <>
        {step === "1" ? (
          <>
            <Box sx={{ maxHeight: "85vh", overflowY: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  top: 0,
                  background: "#fff",
                  zIndex: 1000,
                  pl: 2,
                  pr: 2,
                }}
              >
                <ArrowBackButton onClick={() => setCurrentMode("grid")} />
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans",
                    fontWeight: 700,
                    color: "#000000",
                    fontSize: "large",
                  }}
                >
                  Create Employee profile
                </Typography>
                <Button
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="mpl-primary-btn"
                  onClick={inputData?.is_internal ? handleNext : handleSubmit}
                >
                  {" "}
                  {inputData?.is_internal ? "Next" : "Save"}
                </Button>
              </Box>
              <Grid container>
                {/* <Grid item xs={12} md={6} padding={1.5}>
                      <Paper className={classes.paper} sx={{
                          background: "#d3d3d3",
                          height: "100%",
                          width: "100%",
                          opacity: "0.7",
                      }}
                      >
                          <Typography variant="subtitle1" fontSize={15} gutterBottom>
                              Load from Offer Application
                          </Typography>
                          <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                          >
                              <Grid pt={2.5} container spacing={3}>
                                  <Grid item xs={12} sm={12} md={8}>
                                      <TextField
                                          size='small'
                                          disabled
                                          autoComplete='load_from_ref'
                                          name="load_from_ref"
                                          label="Load From Ref."
                                          fullWidth
                                      />
                                  </Grid>
                                  <Grid item xs={12} sm={12} md={4}>
                                      <Button disabled variant='contained'>Load</Button>
                                  </Grid>
                              </Grid>
                          </Grid>
                      </Paper>
                  </Grid>
                  <Grid item xs={12} md={6} padding={1.5}>
                      <Paper className={classes.paper} sx={{
                          background: "#d3d3d3",
                          height: "100%",
                          width: "100%",
                          opacity: "0.7",
                      }}>
                          <Typography variant="subtitle1" fontSize={15} gutterBottom>
                              Employee Bulk import
                          </Typography>
                          <Grid
                              container
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                          >
                              <div style={{ width: "150px" }}>
                                  <Box sx={{ textAlign: "center" }} className={classes.comingSoon}>
                                      COMING SOON...
                                  </Box>
                              </div>
                          </Grid>
                      </Paper>
                  </Grid> */}
                <Grid item xs={12} md={3} padding={1.5}>
                  <Box>
                    <Paper
                      className={classes.paper}
                      sx={{ padding: "80px 24px 40px", borderRadius: "16px" }}
                    >
                      <Box
                        sx={{
                          marginBottom: "20px",
                          border: "none !important",
                          cursor: "default",
                        }}
                        {...getRootProps({ className: "dropzone" })}
                      >
                        <input {...getInputProps()} />
                        <div className="presentation">
                          <div className="placeholder">
                            {files.length ? (
                              img
                            ) : (
                              <>
                                <AddPhotoAlternateIcon />
                                <Typography
                                  variant="caption"
                                  display="block"
                                  gutterBottom
                                >
                                  Upload Photo
                                </Typography>
                              </>
                            )}
                          </div>
                        </div>
                        <Typography
                          sx={{ margin: "25px auto 0px" }}
                          variant="caption"
                          className="content-sec"
                        >
                          Allowed *.jpeg, *.jpg, *.png
                          <br /> max size of 2 MB
                        </Typography>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
  
                <Grid item xs={12} md={9} padding={1.5}>
                  <Paper className={classes.paper}>
                    <Typography m={0} variant="h6" gutterBottom>
                      Basic Info
                    </Typography>
                    <Typography m={0} variant="body2">
                      <span style={{ fontWeight: 600 }}>Note:</span> Please Enter
                      your Name as per Aadhar
                    </Typography>
                    <Grid pt={2.5} container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          required
                          autoFocus={true}
                          size="small"
                          autoComplete="first_name"
                          onChange={handleInputChange}
                          name="first_name"
                          label="First Name"
                          fullWidth
                          value={inputData?.first_name || ""}
                          {...(errors.first_name && {
                            error: true,
                            helperText: errors.first_name,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          size="small"
                          onChange={handleInputChange}
                          name="middle_name"
                          label="Middle Name"
                          fullWidth
                          value={inputData?.middle_name || ""}
                          {...(errors.middle_name && {
                            error: true,
                            helperText: errors.middle_name,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          required
                          size="small"
                          onChange={handleInputChange}
                          name="last_name"
                          label="Last Name"
                          fullWidth
                          value={inputData?.last_name || ""}
                          {...(errors.last_name && {
                            error: true,
                            helperText: errors.last_name,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Email"
                          fullWidth
                          name="personal_email"
                          onChange={handleInputChange}
                          value={inputData?.personal_email || ""}
                          disabled
                          size="small"
                          {...(errors.personal_email && {
                            error: true,
                            helperText: errors.personal_email,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          label="Mobile"
                          fullWidth
                          name="personal_mobile"
                          onChange={handleInputChange}
                          value={inputData?.personal_mobile || ""}
                          size="small"
                          {...(errors.personal_mobile && {
                            error: true,
                            helperText: errors.personal_mobile,
                          })}
                        />
                      </Grid>
                      {inputData?.is_internal && (
                        <Grid item xs={12} sm={6} md={6}>
                          <CreatableSearch
                            label="Designation"
                            value={inputData?._designation_id}
                            loading={() =>
                              setDesignationData({
                                load: false,
                                values: designation_options,
                              })
                            }
                            fieldLabel="name"
                            required
                            variant={"contained"}
                            name="_designation_id"
                            size="small"
                            showAddOption={true}
                            OptionName="Type to add new Designation"
                            onChangeEvent={handleInputChange}
                            data={designationData}
                            {...(errors._designation_id && {
                              error: true,
                              helperText: errors._designation_id,
                            })}
                          />
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={6}>
                        <SelectableSearch
                          label="Job Title"
                          value={inputData?._job_id}
                          loading={() =>
                            setJobData({ load: false, values: job_options })
                          }
                          fieldLabel="name"
                          required={false}
                          variant={"contained"}
                          name="_job_id"
                          size="small"
                          disabled={true}
                          onChangeEvent={handleInputChange}
                          data={jobData}
                          {...(errors._job_id && {
                            error: true,
                            helperText: errors._job_id,
                          })}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
  
                <Grid item xs={12} md={12} padding={1.5}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                      Work Info
                    </Typography>
                    <Grid pt={2.5} container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <CreatableSearch
                            label="Type of Employment"
                            value={inputData?.employmenttype_id}
                            loading={() =>
                              setEmploymentTypeData({
                                load: false,
                                values: employment_type_options,
                              })
                            }
                            fieldLabel="etype_name"
                            required
                            variant={"outlined"}
                            name="employmenttype_id"
                            size="small"
                            onChangeEvent={handleInputChange}
                            data={employmentTypeData}
                            {...(errors.employmenttype_id && {
                              error: true,
                              helperText: errors.employmenttype_id,
                            })}
                          />
                        </FormControl>
                      </Grid>
                      {inputData?.employmenttype_id?.etype_name ===
                        "On Contract" && (
                        <>
                          <Grid item xs={12} sm={6} md={4}>
                            <TextField
                              fullWidth
                              label="Contractor"
                              name="contractor"
                              required
                              onChange={handleInputChange}
                              value={inputData?.contractor}
                              size="small"
                              {...(errors?.contractor && {
                                error: true,
                                helperText: errors.contractor,
                              })}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                              <CommonDatePicker
                                size="small"
                                name="contract_from"
                                label="Contract Date From"
                                required
                                pickerType="date"
                                onKeyPress={(e) => e.preventDefault()}
                                onKeyDown={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={inputData?.contract_from}
                                handleChange={(value) =>
                                  handleInputChange({
                                    target: {
                                      name: "contract_from",
                                      value: `${
                                        value?.getFullYear() +
                                        "-" +
                                        ("0" + (value?.getMonth() + 1)).slice(
                                          -2
                                        ) +
                                        "-" +
                                        ("0" + value?.getDate()).slice(-2)
                                      }`,
                                    },
                                  })
                                }
                                {...(errors.contract_from && {
                                  error: true,
                                  helperText: errors.contract_from,
                                })}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl fullWidth>
                              <CommonDatePicker
                                minDate={
                                  inputData?.contract_from
                                    ? new Date(inputData.contract_from)
                                    : ""
                                }
                                size="small"
                                name="contract_to"
                                label="Contract Date To"
                                required
                                pickerType="date"
                                onKeyPress={(e) => e.preventDefault()}
                                onKeyDown={(e) => e.preventDefault()}
                                pickerFormat="dd/MM/yyyy"
                                DefaultDTvalue={inputData?.contract_to}
                                handleChange={(value) =>
                                  handleInputChange({
                                    target: {
                                      name: "contract_to",
                                      value: `${
                                        value?.getFullYear() +
                                        "-" +
                                        ("0" + (value?.getMonth() + 1)).slice(
                                          -2
                                        ) +
                                        "-" +
                                        ("0" + value?.getDate()).slice(-2)
                                      }`,
                                    },
                                  })
                                }
                                {...(errors.contract_to && {
                                  error: true,
                                  helperText: errors.contract_to,
                                })}
                              />
                            </FormControl>
                          </Grid>
                        </>
                      )}
                      {/*<Grid item xs={12} sm={6} md={4}>
                                  <FormControl fullWidth>
                                      <SelectableSearch
                                          label="Cost Center"
                                          value={inputData?.costcenter_id}
                                          loading={() => setCostCentereData({ load: false, values: cost_center_options })}
                                          fieldLabel="name"
                                          required={false}
                                          variant={"outlined"}
                                          name="costcenter_id"
                                          size="small"
                                          onChangeEvent={handleInputChange}
                                          data={costCenterData}
                                          {...(errors.costcenter_id && {
                                              error: true,
                                              helperText: errors.costcenter_id,
                                          })}
                                      />
                                  </FormControl>
                              </Grid>
                             <Grid item xs={12} sm={6} md={4}>
                                  <FormControl fullWidth>
                                      <SelectableSearch
                                          label="Reporting Manager"
                                          value={inputData?.reportingmanager_id}
                                          loading={() => setEmployeesData({ load: false, values: employees_options })}
                                          fieldLabel="name"
                                          variant={"outlined"}
                                          name="reportingmanager_id"
                                          size="small"
                                          onChangeEvent={handleInputChange}
                                          data={employeesData}
                                          {...(errors.reportingmanager_id && {
                                              error: true,
                                              helperText: errors.reportingmanager_id,
                                          })}
                                      />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                  <FormControl fullWidth>
                                      <SelectableSearch
                                          label="HR Manager"
                                          value={inputData?.hrmanager_id}
                                          loading={() => setEmployeesData({ load: false, values: employees_options })}
                                          fieldLabel="name"
                                          variant={"outlined"}
                                          name="hrmanager_id"
                                          size="small"
                                          onChangeEvent={handleInputChange}
                                          data={employeesData}
                                          {...(errors.hrmanager_id && {
                                              error: true,
                                              helperText: errors.hrmanager_id,
                                          })}
                                      />
                                  </FormControl>
                              </Grid> */}
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <CommonDatePicker
                            required
                            size="small"
                            name="joining_date"
                            label="Date of Joining"
                            pickerType="date"
                            onKeyPress={(e) => e.preventDefault()}
                            onKeyDown={(e) => e.preventDefault()}
                            pickerFormat="dd/MM/yyyy"
                            DefaultDTvalue={inputData?.joining_date}
                            handleChange={(value) =>
                              handleInputChange({
                                target: {
                                  name: "joining_date",
                                  value: value
                                    ? `${
                                        value?.getFullYear() +
                                        "-" +
                                        ("0" + (value?.getMonth() + 1)).slice(
                                          -2
                                        ) +
                                        "-" +
                                        ("0" + value?.getDate()).slice(-2)
                                      }`
                                    : "",
                                },
                              })
                            }
                            {...(errors.joining_date && {
                              error: true,
                              helperText: errors.joining_date,
                            })}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          label="UAN Number"
                          name="uan_no"
                          onChange={handleInputChange}
                          value={inputData?.uan_no || ""}
                          size="small"
                          {...(errors?.uan_no && {
                            error: true,
                            helperText: errors.uan_no,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          fullWidth
                          label="Accepted CTC"
                          name="accepted_ctc"
                          onChange={handleInputChange}
                          type="number"
                          required
                          value={inputData?.accepted_ctc || ""}
                          size="small"
                          {...(errors?.accepted_ctc && {
                            error: true,
                            helperText: errors.accepted_ctc,
                          })}
                        />
                      </Grid>
                      {/* <Grid item xs={12} sm={6} md={4}>
                                  <SelectableSearch
                                      label="User Role"
                                      value={inputData?.role_id}
                                      loading={() => setRoleData({ load: false, values: role_data })}
                                      fieldLabel="name"
                                      required
                                      variant={"outlined"}
                                      name="role_id"
                                      size="small"
                                      onChangeEvent={handleInputChange}
                                      data={roleData}
                                      {...(errors.role_id && {
                                          error: true,
                                          helperText: errors.role_id,
                                      })}
                                  />
                              </Grid> */}
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="international_worker"
                              checked={inputData?.international_worker || false}
                              onChange={handleInputChange}
                            />
                          }
                          label="International Worker?"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="phy_disabled"
                              checked={inputData?.phy_disabled || false}
                              onChange={handleInputChange}
                            />
                          }
                          label="Physically Challenged/Disabled?"
                        />
                      </Grid>
                      {inputData?.phy_disabled && (
                        <Grid key="phy_disabilities" item xs={12} sm={6} md={4}>
                          <TextField
                            fullWidth
                            label="Disabilities"
                            name="phy_disabilities"
                            onChange={handleInputChange}
                            value={inputData?.phy_disabilities || ""}
                            size="small"
                            {...(errors?.phy_disabilities && {
                              error: true,
                              helperText: errors.phy_disabilities,
                            })}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                </Grid>
  
                <Grid item xs={12} md={12} padding={1.5}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                      Personal Info
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <CommonDatePicker
                            size="small"
                            required
                            name="birthdate"
                            label="Birth Date"
                            pickerType="date"
                            onKeyPress={(e) => e.preventDefault()}
                            onKeyDown={(e) => e.preventDefault()}
                            pickerFormat="dd/MM/yyyy"
                            maxDate={new Date().setFullYear(
                              new Date().getFullYear() - 18
                            )}
                            DefaultDTvalue={inputData?.birthdate}
                            handleChange={(value) =>
                              handleInputChange({
                                target: {
                                  name: "birthdate",
                                  value: value
                                    ? `${
                                        value?.getFullYear() +
                                        "-" +
                                        ("0" + (value.getMonth() + 1)).slice(-2) +
                                        "-" +
                                        ("0" + value.getDate()).slice(-2)
                                      }`
                                    : "",
                                },
                              })
                            }
                            {...(errors.birthdate && {
                              error: true,
                              helperText: errors.birthdate,
                            })}
                          />
                        </FormControl>
                      </Grid>
  
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <CommonDatePicker
                            size="small"
                            name="marriage_date"
                            label="Marriage Date"
                            pickerType="date"
                            onKeyPress={(e) => e.preventDefault()}
                            onKeyDown={(e) => e.preventDefault()}
                            pickerFormat="dd/MM/yyyy"
                            DefaultDTvalue={inputData?.marriage_date}
                            handleChange={(value) =>
                              handleInputChange({
                                target: {
                                  name: "marriage_date",
                                  value: value
                                    ? `${
                                        value?.getFullYear() +
                                        "-" +
                                        ("0" + (value.getMonth() + 1)).slice(-2) +
                                        "-" +
                                        ("0" + value.getDate()).slice(-2)
                                      }`
                                    : "",
                                },
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <SelectableSearch
                          label="Marital Status"
                          value={inputData?.marital_status}
                          fieldLabel="name"
                          required={false}
                          variant={"contained"}
                          name="marital_status"
                          size="small"
                          data={maritalStatus}
                          onChangeEvent={handleInputChange}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            value={inputData?.emg_contact_no || ""}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            id="name"
                            label="Emergency Contact Number"
                            name="emg_contact_no"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <TextField
                            size="small"
                            value={inputData?.emg_contact_person || ""}
                            onChange={handleInputChange}
                            variant="outlined"
                            fullWidth
                            id="name"
                            label="Emergency Contact Person"
                            name="emg_contact_person"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormLabel
                          sx={{ lineHeight: "2.3em" }}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Gender
                        </FormLabel>
                        <FormControl sx={{ ml: 2 }}>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="gender"
                            onChange={handleInputChange}
                            value={inputData?.gender}
                          >
                            <FormControlLabel
                              value="Male"
                              control={<Radio size="small" />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="Female"
                              control={<Radio size="small" />}
                              label="Female"
                            />
                            <FormControlLabel
                              value="Transgender"
                              control={<Radio size="small" />}
                              label="Transgender"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
  
                <Grid item xs={12} md={12} padding={1.5}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                      Address Info.
                    </Typography>
  
                    <Grid pt={2.5} container spacing={3}>
                      <Grid item xs={12} md={5.8}>
                        <Typography sx={{ fontWeight: 600 }}>
                          {" "}
                          Permanent Address
                        </Typography>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                value={inputData?.per_street1 || ""}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Street 1"
                                name="per_street1"
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                value={inputData?.per_street2 || ""}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Street 2"
                                name="per_street2"
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <SelectableSearch
                              label="Country"
                              value={inputData?.per_country}
                              loading={() => handleCurCountry()}
                              fieldLabel="country_name"
                              required
                              variant={"contained"}
                              name="per_country"
                              size="small"
                              data={curCountryData}
                              onChangeEvent={handleInputChange}
                              {...(errors.per_country && {
                                error: true,
                                helperText: errors.per_country,
                              })}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <SelectableSearch
                              label="State"
                              value={inputData?.per_state}
                              loading={loadingPerState}
                              fieldLabel="state_name"
                              required
                              variant={"contained"}
                              name="per_state"
                              size="small"
                              data={perStateData}
                              onChangeEvent={handleInputChange}
                              {...(errors.per_state && {
                                error: true,
                                helperText: errors.per_state,
                              })}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <SelectableSearch
                              label="City"
                              value={inputData?.per_city}
                              loading={loadingPerCity}
                              fieldLabel="city_name"
                              required
                              variant={"contained"}
                              name="per_city"
                              size="small"
                              data={perCityData}
                              onChangeEvent={handleInputChange}
                              {...(errors.per_city && {
                                error: true,
                                helperText: errors.per_city,
                              })}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                value={inputData?.per_zipcode || ""}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Zip Code"
                                name="per_zipcode"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Divider
                        orientation="vertical"
                        flexItem
                        variant="fullWidth"
                      />
                      <Grid item xs={12} md={5.8}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontWeight: 600 }}>
                            {" "}
                            Current Address
                          </Typography>
                          <FormControlLabel
                            sx={{ paddingBottom: "0px" }}
                            control={
                              <Checkbox
                                name="is_permanent"
                                sx={{ paddingBottom: "0px", paddingTop: "0px" }}
                                checked={inputData?.is_permanent}
                                onChange={handleInputChange}
                              />
                            }
                            label="Same as Permanent"
                          />
                        </Box>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                value={inputData?.cur_street1 || ""}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Street 1"
                                name="cur_street1"
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                value={inputData?.cur_street2 || ""}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="cur_street2"
                                label="Street 2"
                                name="cur_street2"
                              />
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <SelectableSearch
                              label="Country"
                              value={inputData?.cur_country}
                              loading={() => handleCurCountry()}
                              fieldLabel="country_name"
                              variant={"contained"}
                              name="cur_country"
                              size="small"
                              data={curCountryData}
                              onChangeEvent={handleInputChange}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <SelectableSearch
                              label="State"
                              value={inputData?.cur_state}
                              fieldLabel="state_name"
                              variant={"contained"}
                              name="cur_state"
                              size="small"
                              loading={loadingCurState}
                              data={curStateData}
                              onChangeEvent={handleInputChange}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <SelectableSearch
                              label="City"
                              value={inputData?.cur_city}
                              fieldLabel="city_name"
                              loading={loadingCurCity}
                              variant={"contained"}
                              name="cur_city"
                              size="small"
                              data={curCityData}
                              onChangeEvent={handleInputChange}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ paddingRight: "1.5rem", marginTop: "1.5rem" }}
                          >
                            <FormControl fullWidth>
                              <TextField
                                size="small"
                                value={inputData?.cur_zipcode || ""}
                                onChange={handleInputChange}
                                variant="outlined"
                                fullWidth
                                id="name"
                                label="Zip Code"
                                name="cur_zipcode"
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
  
                {/* <Grid item xs={12} md={12} padding={1.5}>
                      <Paper className={classes.paper} >
                          <Typography variant="h6" gutterBottom>
                              Payroll Info
                          </Typography>
                          <Grid pt={2.5} container spacing={3}>
                              <Grid item xs={12} sm={6} md={4}>
                                  <FormControl fullWidth>
                                      <SelectableSearch
                                          label="Paygrade"
                                          value={inputData?.paygrade_id}
                                          loading={() => setPaygradeData({ load: false, values: paygrade_options })}
                                          fieldLabel="name"
                                          required={false}
                                          variant={"outlined"}
                                          name="paygrade_id"
                                          size="small"
                                          onChangeEvent={handleInputChange}
                                          data={paygradeData}
                                          {...(errors.paygrade_id && {
                                              error: true,
                                              helperText: errors.paygrade_id,
                                          })}
                                      />
                                  </FormControl>
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                  <TextField
                                      fullWidth
                                      label="CTC"
                                      name="ctc"
                                      onChange={handleInputChange}
                                      value={inputData?.ctc || ""}
                                      size="small"
                                      {...(errors?.ctc && {
                                          error: true,
                                          helperText: errors.ctc,
                                      })}
                                  />
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                  <FormControl fullWidth>
                                      <SelectableSearch
                                          label="Salary Structure"
                                          value={inputData?.salary_structure_id}
                                          loading={() => setStructureData({ load: false, values: salaryStructure_data })}
                                          fieldLabel="name"
                                          required={false}
                                          variant={"outlined"}
                                          name="salary_structure_id"
                                          size="small"
                                          onChangeEvent={handleInputChange}
                                          data={structureData}
                                          {...(errors.salary_structure_id && {
                                              error: true,
                                              helperText: errors.salary_structure_id,
                                          })}
                                      />
                                  </FormControl>
                              </Grid>
                          </Grid>
                      </Paper>
                  </Grid> */}
                <Grid item xs={12} md={12} padding={1.5}>
                  <Paper className={classes.paper}>
                    <Typography
                      variant="h6"
                      gutterBottom
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Box sx={{ display: "flex" }}>
                        Capture Proof
                        <Box sx={{ pl: 1, pt: 0.3 }}>
                          <Tooltip title="The documentation configured by admin will be displayed here.">
                            <InfoOutlinedIcon fontSize="small" />
                          </Tooltip>
                        </Box>
                      </Box>
                      <Tooltip title="Add Proof">
                        <IconButton sx={{ pt: 0 }} onClick={handleAddPoof}>
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                    {documentsData?.map((res, id) => {
                      return (
                        <>
                          <Grid pt={2.5} container spacing={2}>
                            <Grid item xs={12} sm={6} md={3}>
                              <FormControl fullWidth>
                                <SelectableSearch
                                  isOptionEqualToValue={(option, value) =>
                                    +option?.proof_pk === +value?.proof_pk
                                  }
                                  label="Proof Type"
                                  fullWidth
                                  value={res?.proof_type}
                                  loading={() =>
                                    setProofData({
                                      load: false,
                                      values: documentations_options,
                                    })
                                  }
                                  fieldLabel="proof_name"
                                  required={true}
                                  variant={"outlined"}
                                  name="proof_type"
                                  size="small"
                                  onChangeEvent={(e, value) =>
                                    handleDocumentChange("proof_type", value, id)
                                  }
                                  data={proofData}
                                  {...(proofErrors[id] &&
                                    proofErrors[id].proof_type && {
                                      error: true,
                                      helperText: proofErrors[id].proof_type,
                                    })}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} md={3}>
                              <FormControl fullWidth>
                                <TextField
                                  size="small"
                                  value={res?.number || ""}
                                  onChange={(e) =>
                                    handleDocumentChange(
                                      e.target.name,
                                      e.target.value,
                                      id
                                    )
                                  }
                                  variant="outlined"
                                  fullWidth
                                  required
                                  id="number"
                                  label="Proof Number"
                                  name="number"
                                  {...(proofErrors[id] &&
                                    proofErrors[id].number && {
                                      error: true,
                                      helperText: proofErrors[id].number,
                                    })}
                                />
                              </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12} md={5} sx={{ mt: -1.5 }}>
                              <FileUploader
                                fileUrl={documentsUrl}
                                setFileUrl={setDocumentsUrl}
                                task={true}
                                files={res?.file}
                                setFiles={(file) => handleFileChange(file, id)}
                                filename={res?.proof_type?.proof_name}
                                filepath={"mytask?.proof"}
                              />
                              <Typography color={"error"} mt={-5}>
                                {proofErrors[id]?.file}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={1}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  p: 1,
                                  pt: 0,
                                }}
                              >
                                <Fab
                                  id={res.id}
                                  onClick={(e) => {
                                    handleProofDelete(e, id);
                                  }}
                                  size="small"
                                  color="error"
                                  aria-label="delete"
                                >
                                  <DeleteIcon fontSize="small" />
                                </Fab>
                              </Box>
                            </Grid>
                          </Grid>
                        </>
                      );
                    })}
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ maxHeight: "85vh", overflowY: "auto" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "sticky",
                  top: 0,
                  background: "#fff",
                  zIndex: 1000,
                  pl: 2,
                  pr: 2,
                }}
              >
                <ArrowBackButton onClick={() => { setStep("1"); setShow(false);}}/>
                <Typography
                  sx={{
                    fontFamily: "Nunito Sans",
                    fontWeight: 700,
                    color: "#000000",
                    fontSize: "large",
                  }}
                >
                  Create Employee profile
                </Typography>
                <Button
                  sx={{ marginLeft: "10px" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="mpl-primary-btn"
                  onClick={handleSubmit}
                >
                  {" "}
                  Save & Enable Access
                </Button>
              </Box>
              <Grid container>
                <Grid item xs={12} md={12} padding={1.5}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                      Basic Info
                    </Typography>
                    <Grid pt={2.5} container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          required
                          disabled
                          autoFocus={true}
                          size="small"
                          autoComplete="first_name"
                          onChange={handleInputChange}
                          name="first_name"
                          label="First Name"
                          fullWidth
                          value={inputData?.first_name || ""}
                          {...(errors.first_name && {
                            error: true,
                            helperText: errors.first_name,
                          })}
                        />
                      </Grid>
  
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          required
                          disabled
                          size="small"
                          onChange={handleInputChange}
                          name="last_name"
                          label="Last Name"
                          fullWidth
                          value={inputData?.last_name || ""}
                          {...(errors.last_name && {
                            error: true,
                            helperText: errors.last_name,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <TextField
                          label="Office Email"
                          required
                          fullWidth
                          name="office_email"
                          onChange={handleInputChange}
                          value={inputData?.office_email || ""}
                          size="small"
                          {...(errors.office_email && {
                            error: true,
                            helperText: errors.office_email,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <CommonDatePicker
                            disabled
                            size="small"
                            name="birthdate"
                            label="Birth Date"
                            pickerType="date"
                            onKeyPress={(e) => e.preventDefault()}
                            onKeyDown={(e) => e.preventDefault()}
                            pickerFormat="dd/MM/yyyy"
                            DefaultDTvalue={inputData?.birthdate}
                            handleChange={(value) =>
                              handleInputChange({
                                target: {
                                  name: "birthdate",
                                  value: value
                                    ? `${
                                        value?.getFullYear() +
                                        "-" +
                                        ("0" + (value.getMonth() + 1)).slice(-2) +
                                        "-" +
                                        ("0" + value.getDate()).slice(-2)
                                      }`
                                    : "",
                                },
                              })
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CreatableSearch
                          label="Designation"
                          disabled
                          value={inputData?._designation_id}
                          loading={() =>
                            setDesignationData({
                              load: false,
                              values: designation_options,
                            })
                          }
                          fieldLabel="name"
                          required
                          variant={"contained"}
                          name="_designation_id"
                          size="small"
                          onChangeEvent={handleInputChange}
                          data={designationData}
                          showAddOption={true}
                          OptionName="Type to add new Designation"
                          {...(errors._designation_id && {
                            error: true,
                            helperText: errors._designation_id,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <CreatableSearch
                          label="Work Location"
                          value={inputData?.ats_work_location_id}
                          loading={() =>
                            setWorklocationData({
                              load: false,
                              values: workLocation_options,
                            })
                          }
                          fieldLabel="work_location_name"
                          required={true}
                          variant={"contained"}
                          name="ats_work_location_id"
                          size="small"
                          onChangeEvent={handleInputChange}
                          data={worklocationData}
                          showAddOption={true}
                          OptionName="Type to add new Work Location"
                          {...(errors.ats_work_location_id && {
                            error: true,
                            helperText: errors.ats_work_location_id,
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl fullWidth>
                          <SelectableSearch
                            label="Reporting Manager"
                            value={inputData?.reportingmanager_id}
                            loading={() => handleEmployees()}
                            fieldLabel="name"
                            required={true}
                            variant={"outlined"}
                            name="reportingmanager_id"
                            size="small"
                            onChangeEvent={handleInputChange}
                            data={employeesData}
                            {...(errors.reportingmanager_id && {
                              error: true,
                              helperText: errors.reportingmanager_id,
                            })}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={12} padding={1.5}>
                  <Paper className={classes.paper}>
                    <Typography variant="h6" gutterBottom>
                      Role and Access
                    </Typography>
                    <Grid pt={2.5} container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl required fullWidth size="small">
                          <InputLabel id="recruiter-type-select-label">
                            User Type
                          </InputLabel>
                          <Select
                            name="core_hr_user_type"
                            labelId="recruiter-type-select-label"
                            id="recruiter-type-select"
                            value={inputData?.core_hr_user_type}
                            label="User Type"
                            onChange={handleInputChange}
                          >
                            <MenuItem value={"Admin"}>Admin</MenuItem>
                            <MenuItem value={"Employee"}>Employee</MenuItem>
                          </Select>
                          <FormHelperText>
                            {errors?.core_hr_user_type}
                          </FormHelperText>
                        </FormControl>
                      </Grid>
                      {["Employee"].includes(inputData?.core_hr_user_type) && (
                <>
                  <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          disabled
                          name="enable_corehr"
                          checked={inputData?.enable_corehr || false}
                          onChange={handleInputChange}
                        />
                      }
                      label="Enable Core HR & LMS"
                    />
                  </Grid>
                  <Grid item xs={12} md={4} sx={{ padding: "0.75rem" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="enable_ats"
                          checked={inputData?.enable_ats || false}
                          onChange={handleInputChange}
                        />
                      }
                      label="Enable ATS"
                    />
                  </Grid>
                </>
              )}
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </>
    );
  };
  
  export default EmployeeAddForm;
  
