// ** React Imports
import { Fragment, useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { makeStyles } from "@mui/styles";
import { useDropzone } from 'react-dropzone'
import { convertBase64, ConvertDateTime, getUserData, s3Client, } from '../../../Base/helper/baseFunctions';
import Loading from "../../images/recruitment.gif";
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import ErrorIcon from '@mui/icons-material/Error';
import { Divider, FormControl, FormLabel, IconButton } from "@mui/material"
import { useDispatch, useSelector } from "react-redux";
import { UploadResumeAction } from '../../../../../store/ATS/Resume/Action';
import Warning from "../../images/report.png";
import Popup from "../../../Base/views/Popup";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { GetObjectCommand } from "@aws-sdk/client-s3";
import { AtsArchiveResumeHelper, GetAtsFailedResumeHelper, AddAtsBulkResumeHelper } from '../../helper/AtsResumeParserHelper'
import MaterialTable from 'material-table'

const Alert = () => {
  const containerStyle = {
    borderRadius: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 15px",
    color: "#000",
    position: "relative",
    width:"30%"
  };

  const infoIconStyle = {
    color: "#007bff",
    display:"flex",
    alignItems:"center",
    fontSize: "18px",
  };

  const textStyle = {
    margin: 0,
    flex: 1,
    color:"#1b1c21"
  };

  return (
    <div style={{ width:"100%",textAlign:"center", display:"flex", justifyContent:"center"}}>
    <div style={containerStyle}>
      <span style={infoIconStyle}><ErrorIcon /></span>
      <p style={textStyle}>
      You can only upload up to 50 files.
      </p>
    </div>
    </div>
  );
};

const uploadButtonStyles = {
  display: "inline-block",
  padding: "10px 20px",
  backgroundColor: "#8b44ff",
  color: "#fff",
  borderRadius: "5px",
  cursor: "pointer",
  marginRight: "10px",
};

const Img = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginRight: theme.spacing(15.75)
  },
  [theme.breakpoints.down('md')]: {
    marginBottom: theme.spacing(4)
  },
  [theme.breakpoints.down('sm')]: {
    width: 160
  }
}))

const HeadingTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down('sm')]: {
    marginBottom: theme.spacing(4)
  }
}))

const ResumeUploader = ({ id }) => {
  const useStyles = makeStyles({
    files: {
      display: 'flex',
      justifyContent: 'space-between',
      borderRadius: '6px',
      padding: '0.625rem 0.6rem 0.625rem 1.5rem',
      border: '1px solid rgba(93, 89, 98, 0.14)',
      marginTop: '1.5625rem'
    },
    button: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1.5625rem',
      paddingBottom: '1.25rem'
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isParsing } = useSelector(state => state.AtsReducer?.AtsPythonApiReducer)
  const { isUploading } = useSelector(state => state.AtsReducer?.AtsUploadReducer)
  const [files, setFiles] = useState([]);
  const [load, setLoad] = useState(false);
  const [failed_files, setFailedFiles] = useState([]);
  const [show, setShow] = useState(false);
  const userData = getUserData();


  useEffect(() => {
    GetAtsFailedResumeHelper().then((res) => {
      setFailedFiles(res?.data?.data)
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleUpload = async () => {
    try {
      setFiles([]);
      await AtsArchiveResumeHelper();
      let b64Files = [];
      let fileNames = [];
      let contentTypes = [];
      let file_data = [];
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const b64string = await convertBase64(file);
        const ext = file?.name?.split(".").at(-1);
        const content_type = ext === "pdf" ? "application/pdf" : "application/msword";
        const filename = `${userData?.domain}/Staging/ATS/Resumes/` + uuidv4() + "." + ext;
        b64Files.push(b64string);
        fileNames.push(filename);
        contentTypes.push(content_type);
        const extracted_filename = filename.split('/').pop();
        file_data.push({
          original_filename: file?.name,
          encrypt_filename: extracted_filename,
          digitalocean_filename: filename
        });
      }
      const data = {
        files: fileNames,
        workspace_name: [userData?.domain],
        user_id: [userData?.employee_id],
        job_id: [id],
        consultancy_id: [userData?.user_type === "Consultancy" ? userData?.consultancy_id : "0"]
      };
      AddAtsBulkResumeHelper({
        file_data: file_data
      });

      dispatch(UploadResumeAction({ b64Files, fileNames, data, contentTypes }, dispatch));
      (!isUploading && !isParsing) && setFiles([])
    } catch (error) {
      console.error("Error during file upload: ", error);
    }
  };


  const handleFolderChange = async (event) => {
    setFiles([]);
    event.preventDefault();
    const selectedFiles = event.target.files;
    const filesArray = Array.from(selectedFiles);
    const validFiles = [];
    filesArray?.forEach((file) => {
      if (
        file.type === "application/pdf"
      ) {
        validFiles.push(file);
      } else {
        setShow(true);
      }
    });
    if (validFiles.length > 50) {
      toast.error('You can only upload up to 50 files.', {
        duration: 2000
      });
    } else {
      setFiles(validFiles);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    noClick: isParsing,
    maxFiles: 50,
    maxSize: 20000000,
    onDrop: acceptedFiles => {
      acceptedFiles.forEach(file => {
        if (!id) {
          if (file.type === 'application/pdf') {
            setFiles(files => [...files, Object.assign(file)])
          }
          else {
            setShow(true);
          }
        }
        else {
          if (file.type === 'application/pdf') {
            setFiles(acceptedFiles.map(file => Object.assign(file)));
          }
          else {
            setShow(true);
          }
        }
      });
    },
    onDropRejected: () => {
      toast.error('You can only upload up to 50 files.', {
        duration: 2000
      })
    }
  })
  const handleRemoveAllFiles = () => {
    setFiles([])
  }
  const HandleClose = () => {
    setShow(false);
    setFiles([]);
  };
  const handleView = async (event, rowData) => {
    event.stopPropagation();
    const bucketParams = {
      Bucket: process.env.REACT_APP_DO_BUCKET_NAME,
      Key: rowData?.error_file_path,
    };
    try {
      const url = await getSignedUrl(
        s3Client,
        new GetObjectCommand(bucketParams),
        { expiresIn: 3 * 60 }
      );
      if (url) {
        window.open(url, "_blank");
      }
    } catch (err) {
      toast.error(err.message || "An error occurred while opening the document.");
    }
  };
  return (
    <Fragment>
      <Popup
        maxWidth="xs"
        openPopup={show}
        setOpenPopup={setShow}
        close={false}
        hideDividers={true}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 1, mb: 1, textAlign: "center !important" }}>
            <img src={Warning} alt="UnSupport File" width={"25%"} />
          </Box>
          <FormControl>
            <FormLabel id="gender-label">
              <Typography variant="h5" className="blink">
                Invalid File Type, Accepted file type is pdf
              </Typography>
            </FormLabel>
          </FormControl>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px",
            }}
          >
            <Button
              onClick={HandleClose}
              variant="contained"
              type="submit"
              className="mpl-primary-btn profile-btn"
              size="small"
              sx={{
                margin: "10px",
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Popup>
      {
        (load || isParsing || isUploading) &&
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',  // Full height of the viewport
          backgroundColor: '#f0f4f8'  // Optional: Set a background color
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '10px'
          }}>
            <Typography sx={{ mb: 2 }} variant='h4'>Your files are Parsing, we'll notify you once it's completed.</Typography>
            <img
              src={Loading}
              alt="Loading..."
              width={"150px"}
              height={"150px"}
              style={{
                backgroundColor: "#fff",
                borderRadius: "50%",
                padding: "10px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            />
          </div>
        </div>
      }
      {!(load || isParsing || isUploading) && (
        <>
          <div {...getRootProps({ className: 'dropzone' })}>
            <input {...getInputProps()} />
            <Box sx={{ display: 'flex', flexDirection: ['column', 'column', 'row'], alignItems: 'center', justifyContent: 'center' }}>
              <Img alt='Upload img' src='https://demos.themeselection.com//marketplace/materio-mui-react-nextjs-admin-template/demo-2/images/misc/upload.png' />
              <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: ['center', 'center', 'inherit'] }}>
                <HeadingTypography variant='h5'>Drop files here or click to upload.</HeadingTypography>
                <Typography color='textSecondary' sx={{ '& a': { color: 'primary.main', textDecoration: 'none' } }}>
                  Drop files here or click{' '}
                  <a href='/' onClick={e => e.preventDefault()}>
                    browse
                  </a>{' '}
                  through your machine
                </Typography>
              </Box>
            </Box>
          </div>
          <Alert />
          <Box
            sx={{
              display: "flex",
              flexDirection: ["column", "column", "row"],
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            <input
              type="file"
              id="folderInput"
              webkitdirectory=""
              directory=""
              onChange={handleFolderChange}
              style={{ display: "none" }}
            />
            <label htmlFor="folderInput" style={uploadButtonStyles}>
              Upload Folder
            </label>
          </Box>
        </>
      )}
      {files.length ? (
        <Fragment>
          <Typography sx={{ display: 'flex', justifyContent: 'end', pr: '25px', pt: '26px', fontSize: '1.1rem', fontWeight: 700, color: 'black' }}>No of resumes : {files.length}</Typography>
          <div className={classes.button}>
            <Button style={{ marginRight: '0.85rem' }} color='error' variant='outlined' onClick={handleRemoveAllFiles}>
              Remove All
            </Button>
            <Button style={uploadButtonStyles} variant='contained'onClick={() => {
            setLoad(true);
            handleUpload();
          }}>Upload Files</Button>
          </div>
        </Fragment>
      ) : null}

      {(failed_files.length > 0 && !(load || isParsing || isUploading)) && (
        <>
          <Divider sx={{ marginBottom: "20px" }} />
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ width: "70%", maxWidth: "1000px" }}>
              <MaterialTable
                columns={[
                  { title: "File Name", cellStyle: { padding: "10px 10px 10px 13px" }, field: "original_file_name", grouping: false },
                  {
                    title: "Upload Date", cellStyle: { padding: "10px 10px 10px 13px" }, field: "upload_date", render: (rowData) => {
                      return ConvertDateTime(rowData.upload_date);
                    }, grouping: false
                  },
                  {
                    title: "View Resume",
                    cellStyle: { padding: "0px 0px 0px 30px", },
                    render: rowData => (
                      <div>
                        {rowData.error_file_path && (
                          <IconButton
                            onClick={(e) => handleView(e, rowData)}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        )}
                      </div>
                    ),
                  },
                ]}
                data={failed_files || []}
                options={{
                  maxBodyHeight: "59vh",
                  minBodyHeight: "59vh",
                  rowStyle: { fontSize: "13px" },
                  sorting: false,
                  search: true,
                  searchText: "",
                  searchFieldAlignment: "right",
                  searchAutoFocus: true,
                  searchFieldVariant: "standard",
                  filtering: false,
                  paging: true,
                  pageSizeOptions: [25, 50],
                  pageSize: 25,
                  paginationType: "stepped",
                  showFirstLastPageButtons: false,
                  paginationPosition: "bottom",
                  exportButton: false,
                  exportAllData: true,
                  exportFileName: "Client",
                  addRowPosition: "first",
                  selection: false,
                  actionsColumnIndex: -1,
                  showSelectAllCheckbox: true,
                  showTextRowsSelected: true,
                  grouping: false,
                  columnsButton: false,
                  draggable:false
                }}

                title="Failed Resumes"

              />
            </div>
          </div>
        </>
      )}
    </Fragment>
  )
}
export default ResumeUploader
