import * as ActionTypes from '../ActionTypes';

const employeeInitialState = {
    isLoading: false,
    success: false,
    error: false,
    successMessage: "",
    errorMessage: "",
    employees_options:[]
}

export const EmployeesReducer = function (state = employeeInitialState, action) {
    switch (action.type) {
        case ActionTypes.EMPLOYEES_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                success: false,
                successMessage: "",
                error: false,
                errorMessage: "",
                employees_options:[]
            };
        case ActionTypes.EMPLOYEES_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                success: true,
                successMessage: action.payload?.message,
                employees_options: action.payload?.data?.data
            };
        case ActionTypes.EMPLOYEES_GET_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
                success: false,
                errorMessage: action.payload?.data?.error?.[0]?.message
            }
            case ActionTypes.ATS_EMPLOYEES_GET_REQUEST:
                return {
                    ...state,
                    isLoading: true,
                    success: false,
                    successMessage: "",
                    error: false,
                    errorMessage: "",
                    employees_options:[]
                };
            case ActionTypes.ATS_EMPLOYEES_GET_SUCCESS:
                return {
                    ...state,
                    isLoading: false,
                    success: true,
                    successMessage: action.payload?.message,
                    employees_options: action.payload?.data?.data
                };
            case ActionTypes.ATS_EMPLOYEES_GET_ERROR:
                return {
                    ...state,
                    isLoading: false,
                    error: true,
                    success: false,
                    errorMessage: action.payload?.data?.error?.[0]?.message
                }
        default:
            return state;
    }
}