// Propose Candidate
export const PROPOSE_CANDIDATE_CONFIRMATION_REQUEST =
  "PROPOSE_CANDIDATE_CONFIRMATION_REQUEST";

/* ATS USER */
export const ATS_USER_GET_REQUEST = "GET_ATS_USER_REQUEST";
export const ATS_USER_GET_SUCCESS = "GET_ATS_USER_SUCCESS";
export const ATS_USER_GET_ERROR = "GET_ATS_USER_ERROR";

export const ATS_USER_ADD_REQUEST = "ADD_ATS_USER_REQUEST";
export const ATS_USER_ADD_SUCCESS = "ADD_ATS_USER_SUCCESS";
export const ATS_USER_ADD_ERROR = "ADD_ATS_USER_ERROR";

export const ATS_USER_UPDATE_REQUEST = "UPDATE_ATS_USER_REQUEST";
export const ATS_USER_UPDATE_SUCCESS = "UPDATE_ATS_USER_SUCCESS";
export const ATS_USER_UPDATE_ERROR = "UPDATE_ATS_USER_ERROR";

export const ATS_INTERNAL_USER_TOGGLE = "ATS_INTERNAL_USER_TOGGLE";
export const ATS_COMPANY_USER_TOGGLE = "ATS_COMPANY_USER_TOGGLE";
export const ATS_CONSULTANCY_USER_TOGGLE = "ATS_CONSULTANCY_USER_TOGGLE";
export const ATS_GROUP_USER_TOGGLE = "ATS_GROUP_USER_TOGGLE";

/* ATS COMPANY */
export const ATS_COMPANY_GET_REQUEST = "GET_ATS_COMPANY_REQUEST";
export const ATS_COMPANY_GET_SUCCESS = "GET_ATS_COMPANY_SUCCESS";
export const ATS_COMPANY_GET_ERROR = "GET_ATS_COMPANY_ERROR";

export const ATS_COMPANY_ADD_REQUEST = "ADD_ATS_COMPANY_REQUEST";
export const ATS_COMPANY_ADD_SUCCESS = "ADD_ATS_COMPANY_SUCCESS";
export const ATS_COMPANY_ADD_ERROR = "ADD_ATS_COMPANY_ERROR";

export const ATS_COMPANY_UPDATE_REQUEST = "UPDATE_ATS_COMPANY_REQUEST";
export const ATS_COMPANY_UPDATE_SUCCESS = "UPDATE_ATS_COMPANY_SUCCESS";
export const ATS_COMPANY_UPDATE_ERROR = "UPDATE_ATS_COMPANY_ERROR";

export const ATS_COMPANY_DELETE_REQUEST = "DELETE_ATS_COMPANY_REQUEST";
export const ATS_COMPANY_DELETE_SUCCESS = "DELETE_ATS_COMPANY_SUCCESS";
export const ATS_COMPANY_DELETE_ERROR = "DELETE_ATS_COMPANY_ERROR";

/* ATS GROUP COMPANY */
export const ATS_GROUP_COMPANY_GET_REQUEST = "ATS_GROUP_COMPANY_GET_REQUEST";
export const ATS_GROUP_COMPANY_GET_SUCCESS = "ATS_GROUP_COMPANY_GET_SUCCESS";
export const ATS_GROUP_COMPANY_GET_ERROR = "ATS_GROUP_COMPANY_GET_ERROR";

export const ATS_GROUP_COMPANY_ADD_REQUEST = "ATS_GROUP_COMPANY_ADD_REQUEST";
export const ATS_GROUP_COMPANY_ADD_SUCCESS = "ATS_GROUP_COMPANY_ADD_SUCCESS";
export const ATS_GROUP_COMPANY_ADD_ERROR = "ATS_GROUP_COMPANY_ADD_ERROR";

export const ATS_GROUP_COMPANY_UPDATE_REQUEST =
  "ATS_GROUP_COMPANY_UPDATE_REQUEST";
export const ATS_GROUP_COMPANY_UPDATE_SUCCESS =
  "ATS_GROUP_COMPANY_UPDATE_SUCCESS";
export const ATS_GROUP_COMPANY_UPDATE_ERROR = "ATS_GROUP_COMPANY_UPDATE_ERROR";

export const ATS_GROUP_COMPANY_DELETE_REQUEST =
  "ATS_GROUP_COMPANY_DELETE_REQUEST";
export const ATS_GROUP_COMPANY_DELETE_SUCCESS =
  "ATS_GROUP_COMPANY_DELETE_SUCCESS";
export const ATS_GROUP_COMPANY_DELETE_ERROR = "ATS_GROUP_COMPANY_DELETE_ERROR";
/* ATS CONSULTANCY */
export const ATS_CONSULTANCY_GET_REQUEST = "GET_ATS_CONSULTANCY_REQUEST";
export const ATS_CONSULTANCY_GET_SUCCESS = "GET_ATS_CONSULTANCY_SUCCESS";
export const ATS_CONSULTANCY_GET_ERROR = "GET_ATS_CONSULTANCY_ERROR";

export const ATS_CONSULTANCY_ADD_REQUEST = "ADD_ATS_CONSULTANCY_REQUEST";
export const ATS_CONSULTANCY_ADD_SUCCESS = "ADD_ATS_CONSULTANCY_SUCCESS";
export const ATS_CONSULTANCY_ADD_ERROR = "ADD_ATS_CONSULTANCY_ERROR";

export const ATS_CONSULTANCY_UPDATE_REQUEST = "UPDATE_ATS_CONSULTANCY_REQUEST";
export const ATS_CONSULTANCY_UPDATE_SUCCESS = "UPDATE_ATS_CONSULTANCY_SUCCESS";
export const ATS_CONSULTANCY_UPDATE_ERROR = "UPDATE_ATS_CONSULTANCY_ERROR";

export const ATS_CONSULTANCY_DELETE_REQUEST = "DELETE_ATS_CONSULTANCY_REQUEST";
export const ATS_CONSULTANCY_DELETE_SUCCESS = "DELETE_ATS_CONSULTANCY_SUCCESS";
export const ATS_CONSULTANCY_DELETE_ERROR = "DELETE_ATS_CONSULTANCY_ERROR";

// ATS Candidate
export const ATS_CANDIDATE_GET_REQUEST = "ATS_CANDIDATE_GET_REQUEST";
export const ATS_CANDIDATE_GET_SUCCESS = "ATS_CANDIDATE_GET_SUCCESS";
export const ATS_CANDIDATE_GET_ERROR = "ATS_CANDIDATE_GET_ERROR";

export const ATS_CANDIDATE_ADD_REQUEST = "ATS_CANDIDATE_ADD_REQUEST";
export const ATS_CANDIDATE_ADD_SUCCESS = "ATS_CANDIDATE_ADD_SUCCESS";
export const ATS_CANDIDATE_ADD_ERROR = "ATS_CANDIDATE_ADD_ERROR";

export const ATS_CANDIDATE_UPDATE_REQUEST = "ATS_CANDIDATE_UPDATE_REQUEST";
export const ATS_CANDIDATE_UPDATE_SUCCESS = "ATS_CANDIDATE_UPDATE_SUCCESS";
export const ATS_CANDIDATE_UPDATE_ERROR = "ATS_CANDIDATE_UPDATE_ERROR";

export const ATS_CANDIDATE_DELETE_REQUEST = "ATS_CANDIDATE_DELETE_REQUEST";
export const ATS_CANDIDATE_DELETE_SUCCESS = "ATS_CANDIDATE_DELETE_SUCCESS";
export const ATS_CANDIDATE_DELETE_ERROR = "ATS_CANDIDATE_DELETE_ERROR";

export const ATS_QUICK_SEARCH_GET_REQUSET = "ATS_QUICK_SEARCH_GET_REQUEST";
export const ATS_QUICK_SEARCH_GET_SUCCESS = "ATS_QUICK_SEARCH_GET_SUCCESS";
export const ATS_QUICK_SEARCH_GET_ERROR = "ATS_QUICK_SEARCH_GET_ERROR";

// ATS Job Post
export const ATS_JOB_POST_GET_REQUEST = "ATS_JOB_POST_GET_REQUEST";
export const ATS_JOB_POST_GET_SUCCESS = "ATS_JOB_POST_GET_SUCCESS";
export const ATS_JOB_POST_GET_ERROR = "ATS_JOB_POST_GET_ERROR";

export const ATS_JOB_POST_ADD_REQUEST = "ATS_JOB_POST_ADD_REQUEST";
export const ATS_JOB_POST_ADD_SUCCESS = "ATS_JOB_POST_ADD_SUCCESS";
export const ATS_JOB_POST_ADD_ERROR = "ATS_JOB_POST_ADD_ERROR";

export const ATS_JOB_POST_UPDATE_REQUEST = "ATS_JOB_POST_UPDATE_REQUEST";
export const ATS_JOB_POST_UPDATE_SUCCESS = "ATS_JOB_POST_UPDATE_SUCCESS";
export const ATS_JOB_POST_UPDATE_ERROR = "ATS_JOB_POST_UPDATE_ERROR";

export const ATS_JOB_FILTER_ADD_REQUEST = "ATS_JOB_FILTER_ADD_REQUEST";
export const ATS_JOB_FILTER_ADD_SUCCESS = "ATS_JOB_FILTER_ADD_SUCCESS";
export const ATS_JOB_FILTER_ADD_ERROR = "ATS_JOB_FILTER_ADD__ERROR";

export const ATS_JOB_POST_MODE = "ATS_JOB_POST_MODE";

export const ATS_JOB_OFFER_GET_REQUEST = "ATS_JOB_OFFER_GET_REQUEST";
export const ATS_JOB_OFFER_GET_SUCCESS = "ATS_JOB_OFFER_GET_SUCCESS";
export const ATS_JOB_OFFER_GET_ERROR = "ATS_JOB_OFFER_GET_ERROR";

// ATS Filter

export const ATS_JOB_FILTER_ADMIN_GET_REQUEST =
  "ATS_JOB_FILTER_ADMIN_GET_REQUEST";
export const ATS_JOB_FILTER_ADMIN_GET_SUCCESS =
  "ATS_JOB_FILTER_ADMIN_GET_SUCCESS";
export const ATS_JOB_FILTER_ADMIN_GET_ERROR = "ATS_JOB_FILTER_ADMIN_GET_ERROR";

export const ATS_JOB_FILTER_INTERNAL_GET_REQUEST =
  "ATS_JOB_FILTER_INTERNAL_GET_REQUEST";
export const ATS_JOB_FILTER_INTERNAL_GET_SUCCESS =
  "ATS_JOB_FILTER_INTERNAL_GET_SUCCESS";
export const ATS_JOB_FILTER_INTERNAL_GET_ERROR =
  "ATS_JOB_FILTER_INTERNAL_GET_ERROR";

export const ATS_JOB_FILTER_CONSULTANCY_GET_REQUEST =
  "ATS_JOB_FILTER_CONSULTANCY_GET_REQUEST";
export const ATS_JOB_FILTER_CONSULTANCY_GET_SUCCESS =
  "ATS_JOB_FILTER_CONSULTANCY_GET_SUCCESS";
export const ATS_JOB_FILTER_CONSULTANCY_GET_ERROR =
  "ATS_JOB_FILTER_CONSULTANCY_GET_ERROR";

export const ATS_JOB_FILTER_COMPANY_GET_REQUEST =
  "ATS_JOB_FILTER_COMPANY_GET_REQUEST";
export const ATS_JOB_FILTER_COMPANY_GET_SUCCESS =
  "ATS_JOB_FILTER_COMPANY_GET_SUCCESS";
export const ATS_JOB_FILTER_COMPANY_GET_ERROR =
  "ATS_JOB_FILTER_COMPANY_GET_ERROR";

export const ATS_JOB_FILTER_GROUP_ORG_GET_REQUEST =
  "ATS_JOB_FILTER_GROUP_ORG_GET_REQUEST";
export const ATS_JOB_FILTER_GROUP_ORG_GET_SUCCESS =
  "ATS_JOB_FILTER_GROUP_ORG_GET_SUCCESS";
export const ATS_JOB_FILTER_GROUP_ORG_GET_ERROR =
  "ATS_JOB_FILTER_GROUP_ORG_GET_ERROR";

// ATS Profile Score
export const ATS_PROFILE_SCORE_GET_REQUEST = "ATS_PROFILE_SCORE_GET_REQUEST";
export const ATS_PROFILE_SCORE_GET_SUCCESS = "ATS_PROFILE_SCORE_GET_SUCCESS";
export const ATS_PROFILE_SCORE_GET_ERROR = "ATS_PROFILE_SCORE_GET_ERROR";

// ATS Interview Details
export const ATS_INTERVIEW_DETAILS_GET_REQUEST =
  "ATS_INTERVIEW_DETAILS_GET_REQUEST";
export const ATS_INTERVIEW_DETAILS_GET_SUCCESS =
  "ATS_INTERVIEW_DETAILS_GET_SUCCESS";
export const ATS_INTERVIEW_DETAILS_GET_ERROR =
  "ATS_INTERVIEW_DETAILS_GET_ERROR";

export const ATS_INTERVIEW_DETAILS_ADD_REQUEST =
  "ATS_INTERVIEW_DETAILS_ADD_REQUEST";
export const ATS_INTERVIEW_DETAILS_ADD_SUCCESS =
  "ATS_INTERVIEW_DETAILS_ADD_SUCCESS";
export const ATS_INTERVIEW_DETAILS_ADD_ERROR =
  "ATS_INTERVIEW_DETAILS_ADD_ERROR";

export const ATS_INTERVIEW_DETAILS_UPDATE_REQUEST =
  "ATS_INTERVIEW_DETAILS_UPDATE_REQUEST";
export const ATS_INTERVIEW_DETAILS_UPDATE_SUCCESS =
  "ATS_INTERVIEW_DETAILS_UPDATE_SUCCESS";
export const ATS_INTERVIEW_DETAILS_UPDATE_ERROR =
  "ATS_INTERVIEW_DETAILS_UPDATE_ERROR";

export const ATS_INTERVIEW_DETAILS_DELETE_REQUEST =
  "ATS_INTERVIEW_DETAILS_DELETE_REQUEST";
export const ATS_INTERVIEW_DETAILS_DELETE_SUCCESS =
  "ATS_INTERVIEW_DETAILS_DELETE_SUCCESS";
export const ATS_INTERVIEW_DETAILS_DELETE_ERROR =
  "ATS_INTERVIEW_DETAILS_DELETE_ERROR";

// ATS User Info
export const ATS_USER_INFO_REQUEST = "ATS_USER_INFO_REQUEST";
export const ATS_USER_INFO_SUCCESS = "ATS_USER_INFO_SUCCESS";
export const ATS_USER_INFO_ERROR = "ATS_USER_INFO_ERROR";

// ATS Get Unique Candidate Details
export const ATS_CANDIDATE_BY_EMAIL_GET_REQUEST =
  "ATS_CANDIDATE_BY_EMAIL_GET_REQUEST";
export const ATS_CANDIDATE_BY_EMAIL_GET_SUCCESS =
  "ATS_CANDIDATE_BY_EMAIL_GET_SUCCESS";
export const ATS_CANDIDATE_BY_EMAIL_GET_ERROR =
  "ATS_CANDIDATE_BY_EMAIL_GET_ERROR";

// ATS Get Invoice Details
export const ATS_INVOICE_GET_REQUEST = "ATS_INVOICE_GET_REQUEST";
export const ATS_INVOICE_GET_SUCCESS = "ATS_INVOICE_GET_SUCCESS";
export const ATS_INVOICE_GET_ERROR = "ATS_INVOICE_GET_ERROR";

// ATS Add Invoice Details
export const ATS_INVOICE_ADD_REQUEST = "ATS_INVOICE_ADD_REQUEST";
export const ATS_INVOICE_ADD_SUCCESS = "ATS_INVOICE_ADD_SUCCESS";
export const ATS_INVOICE_ADD_ERROR = "ATS_INVOICE_ADD_ERROR";

export const ATS_INVOICE_UPDATE_REQUEST = "ATS_INVOICE_UPDATE_REQUEST";
export const ATS_INVOICE_UPDATE_SUCCESS = "ATS_INVOICE_UPDATE_SUCCESS";
export const ATS_INVOICE_UPDATE_ERROR = "ATS_INVOICE_UPDATE_ERROR";

// ATS Get Invoice Details
export const ATS_INVOICE_CANDIDATES_GET_REQUEST =
  "ATS_INVOICE_CANDIDATES_GET_REQUEST";
export const ATS_INVOICE_CANDIDATES_GET_SUCCESS =
  "ATS_INVOICE_CANDIDATES_GET_SUCCESS";
export const ATS_INVOICE_CANDIDATES_GET_ERROR =
  "ATS_INVOICE_CANDIDATES_GET_ERROR";

export const ATS_INVOICE_GENERATE_CANDIDATES_GET_REQUEST =
  "ATS_INVOICE_GENERATE_CANDIDATES_GET_REQUEST";
export const ATS_INVOICE_GENERATE_CANDIDATES_GET_SUCCESS =
  "ATS_INVOICE_GENERATE_CANDIDATES_GET_SUCCESS";
export const ATS_INVOICE_GENERATE_CANDIDATES_GET_ERROR =
  "ATS_INVOICE_GENERATE_CANDIDATES_GET_ERROR";

//ATS Switch Workspaces
export const ATS_WORKSPACE_ADD_REQUEST = "ATS_WORKSPACE_ADD_REQUEST";
export const ATS_WORKSPACE_ADD_SUCCESS = "ATS_WORKSPACE_ADD_SUCCESS";
export const ATS_WORKSPACE_ADD_ERROR = "ATS_WORKSPACE_ADD_ERROR";

export const ATS_RESUME_UPLOAD_REQUEST = "ATS_RESUME_UPLOAD_REQUEST";
export const ATS_RESUME_UPLOAD_SUCCESS = "ATS_RESUME_UPLOAD_SUCCESS";
export const ATS_RESUME_UPLOAD_ERROR = "ATS_RESUME_UPLOAD_ERROR";

export const ATS_RESUME_PYTHON_API_REQUEST = "ATS_RESUME_PYTHON_API_REQUEST";
export const ATS_RESUME_PYTHON_API_SUCCESS = "ATS_RESUME_PYTHON_API_SUCCESS";
export const ATS_RESUME_PYTHON_API_ERROR = "ATS_RESUME_PYTHON_API_ERROR";

//ATS Reports
export const ATS_TARGET_ACHIEVEMENT_REPORT_GET_REQUEST =
  "ATS_TARGET_ACHIEVEMENT_REPORT_GET_REQUEST";
export const ATS_TARGET_ACHIEVEMENT_REPORT_GET_SUCCESS =
  "ATS_TARGET_ACHIEVEMENT_REPORT_GET_SUCCESS";
export const ATS_TARGET_ACHIEVEMENT_REPORT_GET_ERROR =
  "ATS_TARGET_ACHIEVEMENT_REPORT_GET_ERROR";

export const ATS_QUALITY_OF_RESUME_REPORT_GET_REQUEST =
  "ATS_QUALITY_OF_RESUME_REPORT_GET_REQUEST";
export const ATS_QUALITY_OF_RESUME_REPORT_GET_SUCCESS =
  " ATS_QUALITY_OF_RESUME_REPORT_GET_SUCCESS";
export const ATS_QUALITY_OF_RESUME_REPORT_GET_ERROR =
  "ATS_QUALITY_OF_RESUME_REPORT_GET_ERROR";

export const ATS_REVENUE_REPORT_GET_REQUEST = "ATS_REVENUE_REPORT_GET_REQUEST";
export const ATS_REVENUE_REPORT_GET_SUCCESS = " ATS_REVENUE_REPORT_GET_SUCCESS";
export const ATS_REVENUE_REPORT_GET_ERROR = "ATS_REVENUE_REPORT_GET_ERROR";

export const ATS_DAILY_PRODUCTIVITY_REPORT_GET_REQUEST =
  "ATS_DAILY_PRODUCTIVITY_REPORT_GET_REQUEST";
export const ATS_DAILY_PRODUCTIVITY_REPORT_GET_SUCCESS =
  "ATS_DAILY_PRODUCTIVITY_REPORT_GET_SUCCESS";
export const ATS_DAILY_PRODUCTIVITY_REPORT_GET_ERROR =
  "ATS_DAILY_PRODUCTIVITY_REPORT_GET_ERROR";

export const ATS_MONTHLY_REPORT_GET_REQUEST = "ATS_MONTHLY_REPORT_GET_REQUEST";
export const ATS_MONTHLY_REPORT_GET_SUCCESS = "ATS_MONTHLY_REPORT_GET_SUCCESS";
export const ATS_MONTHLY_REPORT_GET_ERROR = "ATS_MONTHLY_REPORT_GET_ERROR";

export const ATS_REPORT_RECRUITER_GET_REQUEST =
  "ATS_REPORT_RECRUITER_GET_REQUEST";
export const ATS_REPORT_RECRUITER_GET_SUCCESS =
  "ATS_REPORT_RECRUITER_GET_SUCCESS";
export const ATS_REPORT_RECRUITER_GET_ERROR = "ATS_REPORT_RECRUITER_GET_ERROR";

export const ATS_WORK_LOAD_REPORT_GET_REQUEST =
  "ATS_WORK_LOAD_REPORT_GET_REQUEST";
export const ATS_WORK_LOAD_REPORT_GET_SUCCESS =
  "ATS_WORK_LOAD_REPORT_GET_SUCCESS";
export const ATS_WORK_LOAD_REPORT_GET_ERROR =
  "ATS_WORK_LOAD_REPORT_GET_ERROR";


//ATS CandidateExport
export const ATS_CANDIDATE_EXPORT_GET_REQUEST =
  "ATS_CANDIDATE_EXPORT_GET_REQUEST";
export const ATS_CANDIDATE_EXPORT_GET_SUCCESS =
  "ATS_CANDIDATE_EXPORT_GET_SUCCESS";
export const ATS_CANDIDATE_EXPORT_GET_ERROR = "ATS_CANDIDATE_EXPORT_GET_ERROR";

//Industry Type
export const MEEPL_ADMIN_INDUSTRY_TYPE_GET_REQUEST =
  "MEEPL_ADMIN_INDUSTRY_TYPE_GET_REQUEST";
export const INDUSTRY_TYPE_GET_REQUEST = "INDUSTRY_TYPE_GET_REQUEST";
export const INDUSTRY_TYPE_GET_SUCCESS = "INDUSTRY_TYPE_GET_SUCCESS";
export const INDUSTRY_TYPE_GET_ERROR = "INDUSTRY_TYPE_GET_ERROR";

export const INDUSTRY_TYPE_ADD_REQUEST = "INDUSTRY_TYPE_ADD_REQUEST";
export const INDUSTRY_TYPE_ADD_SUCCESS = "INDUSTRY_TYPE_ADD_SUCCESS";
export const INDUSTRY_TYPE_ADD_ERROR = "INDUSTRY_TYPE_ADD_ERROR";

export const INDUSTRY_TYPE_UPDATE_REQUEST = "INDUSTRY_TYPE_UPDATE_REQUEST";
export const INDUSTRY_TYPE_UPDATE_SUCCESS = "INDUSTRY_TYPE_UPDATE_SUCCESS";
export const INDUSTRY_TYPE_UPDATE_ERROR = "INDUSTRY_TYPE_UPDATE_ERROR";

export const INDUSTRY_TYPE_DELETE_REQUEST = "INDUSTRY_TYPE_DELETE_REQUEST";
export const INDUSTRY_TYPE_DELETE_SUCCESS = "INDUSTRY_TYPE_DELETE_SUCCESS";
export const INDUSTRY_TYPE_DELETE_ERROR = "INDUSTRY_TYPE_DELETE_ERROR";

//Skills
export const MEEPL_ADMIN_SKILLS_GET_REQUEST = "MEEPL_ADMIN_SKILLS_GET_REQUEST";
export const SKILLS_GET_REQUEST = "SKILLS_GET_REQUEST";
export const SKILLS_GET_SUCCESS = "SKILLS_GET_SUCCESS";
export const SKILLS_GET_ERROR = "SKILLS_GET_ERROR";

export const SKILLS_ADD_REQUEST = "SKILLS_ADD_REQUEST";
export const SKILLS_ADD_SUCCESS = "SKILLS_ADD_SUCCESS";
export const SKILLS_ADD_ERROR = "SKILLS_ADD_ERROR";

export const SKILLS_UPDATE_REQUEST = "SKILLS_UPDATE_REQUEST";
export const SKILLS_UPDATE_SUCCESS = "SKILLS_UPDATE_SUCCESS";
export const SKILLS_UPDATE_ERROR = "SKILLS_UPDATE_ERROR";

export const SKILLS_DELETE_REQUEST = "SKILLS_DELETE_REQUEST";
export const SKILLS_DELETE_SUCCESS = "SKILLS_DELETE_SUCCESS";
export const SKILLS_DELETE_ERROR = "SKILLS_DELETE_ERROR";

//Job Template
export const MEEPL_ADMIN_JOB_TEMPLATE_GET_REQUEST =
  "MEEPL_ADMIN_JOB_TEMPLATE_GET_REQUEST";
export const JOB_TEMPLATE_GET_REQUEST = "JOB_TEMPLATE_GET_REQUEST";
export const JOB_TEMPLATE_GET_SUCCESS = "JOB_TEMPLATE_GET_SUCCESS";
export const JOB_TEMPLATE_GET_ERROR = "JOB_TEMPLATE_GET_ERROR";

export const JOB_TEMPLATE_ADD_REQUEST = "JOB_TEMPLATE_ADD_REQUEST";
export const JOB_TEMPLATE_ADD_SUCCESS = "JOB_TEMPLATE_ADD_SUCCESS";
export const JOB_TEMPLATE_ADD_ERROR = "JOB_TEMPLATE_ADD_ERROR";

export const JOB_TEMPLATE_UPDATE_REQUEST = "JOB_TEMPLATE_UPDATE_REQUEST";
export const JOB_TEMPLATE_UPDATE_SUCCESS = "JOB_TEMPLATE_UPDATE_SUCCESS";
export const JOB_TEMPLATE_UPDATE_ERROR = "JOB_TEMPLATE_UPDATE_ERROR";

export const JOB_TEMPLATE_DELETE_REQUEST = "JOB_TEMPLATE_DELETE_REQUEST";
export const JOB_TEMPLATE_DELETE_SUCCESS = "JOB_TEMPLATE_DELETE_SUCCESS";
export const JOB_TEMPLATE_DELETE_ERROR = "JOB_TEMPLATE_DELETE_ERROR";

// My Tasks
export const MY_TASK_GET_REQUEST = "MY_TASK_GET_REQUEST";
export const MY_TASK_GET_SUCCESS = "MY_TASK_GET_SUCCESS";
export const MY_TASK_GET_ERROR = "MY_TASK_GET_ERROR";

export const MY_TASK_UPDATE_REQUEST = "MY_TASK_UPDATE_REQUEST";
export const MY_TASK_UPDATE_SUCCESS = "MY_TASK_UPDATE_SUCCESS";
export const MY_TASK_UPDATE_ERROR = "MY_TASK_UPDATE_ERROR";

export const ASSIGNED_TASK_GET_REQUEST = "ASSIGNED_TASK_GET_REQUEST";
export const ASSIGNED_TASK_GET_SUCCESS = "ASSIGNED_TASK_GET_SUCCESS";
export const ASSIGNED_TASK_GET_ERROR = "ASSIGNED_TASK_GET_ERROR";

export const ASSIGNED_TASK_ADD_REQUEST = "ASSIGNED_TASKE_ADD_REQUEST";
export const ASSIGNED_TASK_ADD_SUCCESS = "ASSIGNED_TASK_ADD_SUCCESS";
export const ASSIGNED_TASK_ADD_ERROR = "ASSIGNED_TASK_ADD_ERROR";

export const ASSIGNED_TASK_UPDATE_REQUEST = "ASSIGNED_TASK_UPDATE_REQUEST";
export const ASSIGNED_TASK_UPDATE_SUCCESS = "ASSIGNED_TASK_UPDATE_SUCCESS";
export const ASSIGNED_TASK_UPDATE_ERROR = "ASSIGNED_TASK_UPDATE_ERROR";

export const ASSIGNED_TASK_DELETE_REQUEST = "ASSIGNED_TASK_DELETE_REQUEST";
export const ASSIGNED_TASK_DELETE_SUCCESS = "ASSIGNED_TASK_DELETE_SUCCESS";
export const ASSIGNED_TASK_DELETE_ERROR = "ASSIGNED_TASK_DELETE_ERROR";

//Form Template

export const FORM_TEMPLATE_GET_REQUEST = "FORM_TEMPLATE_GET_REQUEST";
export const FORM_TEMPLATE_GET_SUCCESS = "FORM_TEMPLATE_GET_SUCCESS";
export const FORM_TEMPLATE_GET_ERROR = "FORM_TEMPLATE_GET_ERROR";

export const FORM_TEMPLATE_ADD_REQUEST = "FORM_TEMPLATE_ADD_REQUEST";
export const FORM_TEMPLATE_ADD_SUCCESS = "FORM_TEMPLATE_ADD_SUCCESS";
export const FORM_TEMPLATE_ADD_ERROR = "FORM_TEMPLATE_ADD_ERROR";

export const FORM_TEMPLATE_UPDATE_REQUEST = "FORM_TEMPLATE_UPDATE_REQUEST";
export const FORM_TEMPLATE_UPDATE_SUCCESS = "FORM_TEMPLATE_UPDATE_SUCCESS";
export const FORM_TEMPLATE_UPDATE_ERROR = "FORM_TEMPLATE_UPDATE_ERROR";

export const FORM_TEMPLATE_DELETE_REQUEST = "FORM_TEMPLATE_DELETE_REQUEST";
export const FORM_TEMPLATE_DELETE_SUCCESS = "FORM_TEMPLATE_DELETE_SUCCESS";
export const FORM_TEMPLATE_DELETE_ERROR = "FORM_TEMPLATE_DELETE_ERROR";

//Pre Screen Template

export const PRE_SCREEN_TEMPLATE_GET_REQUEST =
  "PRE_SCREEN_TEMPLATE_GET_REQUEST";
export const PRE_SCREEN_TEMPLATE_GET_SUCCESS =
  "PRE_SCREEN_TEMPLATE_GET_SUCCESS";
export const PRE_SCREEN_TEMPLATE_GET_ERROR = "PRE_SCREEN_TEMPLATE_GET_ERROR";

export const PRE_SCREEN_TEMPLATE_ADD_REQUEST =
  "PRE_SCREEN_TEMPLATE_ADD_REQUEST";
export const PRE_SCREEN_TEMPLATE_ADD_SUCCESS =
  "PRE_SCREEN_TEMPLATE_ADD_SUCCESS";
export const PRE_SCREEN_TEMPLATE_ADD_ERROR = "PRE_SCREEN_TEMPLATE_ADD_ERROR";

export const PRE_SCREEN_TEMPLATE_UPDATE_REQUEST =
  "PRE_SCREEN_TEMPLATE_UPDATE_REQUEST";
export const PRE_SCREEN_TEMPLATE_UPDATE_SUCCESS =
  "PRE_SCREEN_TEMPLATE_UPDATE_SUCCESS";
export const PRE_SCREEN_TEMPLATE_UPDATE_ERROR =
  "PRE_SCREEN_TEMPLATE_UPDATE_ERROR";

export const PRE_SCREEN_TEMPLATE_DELETE_REQUEST =
  "PRE_SCREEN_TEMPLATE_DELETE_REQUEST";
export const PRE_SCREEN_TEMPLATE_DELETE_SUCCESS =
  "PRE_SCREEN_TEMPLATE_DELETE_SUCCESS";
export const PRE_SCREEN_TEMPLATE_DELETE_ERROR =
  "PRE_SCREEN_TEMPLATE_DELETE_ERROR";

//Offer Letter Template

export const OFFER_LETTER_TEMPLATE_GET_REQUEST =
  "OFFER_LETTER_TEMPLATE_GET_REQUEST";
export const OFFER_LETTER_TEMPLATE_GET_SUCCESS =
  "OFFER_LETTER_TEMPLATE_GET_SUCCESS";
export const OFFER_LETTER_TEMPLATE_GET_ERROR =
  "OFFER_LETTER_TEMPLATE_GET_ERROR";

export const OFFER_LETTER_TEMPLATE_ADD_REQUEST =
  "OFFER_LETTER_TEMPLATE_ADD_REQUEST";
export const OFFER_LETTER_TEMPLATE_ADD_SUCCESS =
  "OFFER_LETTER_TEMPLATE_ADD_SUCCESS";
export const OFFER_LETTER_TEMPLATE_ADD_ERROR =
  "OFFER_LETTER_TEMPLATE_ADD_ERROR";

export const OFFER_LETTER_TEMPLATE_UPDATE_REQUEST =
  "OFFER_LETTER_TEMPLATE_UPDATE_REQUEST";
export const OFFER_LETTER_TEMPLATE_UPDATE_SUCCESS =
  "OFFER_LETTER_TEMPLATE_UPDATE_SUCCESS";
export const OFFER_LETTER_TEMPLATE_UPDATE_ERROR =
  "OFFER_LETTER_TEMPLATE_UPDATE_ERROR";

export const OFFER_LETTER_TEMPLATE_DELETE_REQUEST =
  "OFFER_LETTER_TEMPLATE_DELETE_REQUEST";
export const OFFER_LETTER_TEMPLATE_DELETE_SUCCESS =
  "OFFER_LETTER_TEMPLATE_DELETE_SUCCESS";
export const OFFER_LETTER_TEMPLATE_DELETE_ERROR =
  "OFFER_LETTER_TEMPLATE_DELETE_ERROR";

// Designation
export const ATS_DESIGNATION_GET_REQUEST = "ATS_DESIGNATION_GET_REQUEST";
export const ATS_DESIGNATION_GET_SUCCESS = "ATS_DESIGNATION_GET_SUCCESS";

export const ATS_DESIGNATION_ADD_REQUEST = "ATS_DESIGNATION_ADD_REQUEST";
export const ATS_DESIGNATION_ADD_SUCCESS = "ATS_DESIGNATION_ADD_SUCCESS";
export const ATS_DESIGNATION_ADD_ERROR = "ATS_DESIGNATION_ADD_ERROR";

export const ATS_DESIGNATION_UPDATE_REQUEST = "ATS_DESIGNATION_UPDATE_REQUEST";
export const ATS_DESIGNATION_UPDATE_SUCCESS = "ATS_DESIGNATION_UPDATE_SUCCESS";
export const ATS_DESIGNATION_UPDATE_ERROR = "ATS_DESIGNATION_UPDATE_ERROR";

export const ATS_DESIGNATION_DELETE_REQUEST = "ATS_DESIGNATION_DELETE_REQUEST";
export const ATS_DESIGNATION_DELETE_SUCCESS = "ATS_DESIGNATION_DELETE_SUCCESS";
export const ATS_DESIGNATION_DELETE_ERROR = "ATS_DESIGNATION_DELETE_ERROR";
